// import { PrinterList } from "../PrinterList";
import { Box, Avatar, Divider, TextField, InputLabel, Menu, MenuItem, IconButton, Tooltip, FormControl, FormControlLabel, Select, Switch, NativeSelect, Button, Stack, Paper, Card, CardHeader, CardContent, CardActions, Grid } from '@mui/material';
import { DataGrid, GridToolbar, GridColDef, GridHeader } from '@mui/x-data-grid';
import { experimentalStyled as styled } from '@mui/material/styles';
import { useAuth } from "../../auth/AuthWrapper";
import React, { useState, useEffect, useCallback } from 'react';
import FileSaver from "file-saver";
import { useParams, useNavigate, Link } from "react-router-dom";
import { LinearProgress, FormHelperText } from "@mui/material";
import { Page, Text, View, Image, Svg, Document, StyleSheet, PDFDownloadLink } from '@react-pdf/renderer';
import { Pie, PieChart, Cell, ResponsiveContainer } from 'recharts';
import { Line as SvgLine } from '@react-pdf/renderer';
import { useCurrentPng } from "recharts-to-png";
import { useForm, useFormState, Controller, useFieldArray } from "react-hook-form";
import dayjs from 'dayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

var inventory_cnt = 0
var data_bearing_cnt = 0
var whtCount = 0
var crt_wht = 0
var crt_32_cnt = 0
var crt_33_cnt = 0
var crt_broken_cnt = 0
var crt_broken_wht = 0
var crt_monitor_cnt = 0
var other_tv_wht = 0
var other_tv_cnt = 0
var lcd_tv_wht = 0
var lcd_tv_cnt = 0
var printer_wht = 0
var printer_cnt = 0
var consumer_wht = 0
var computing_wht = 0
var computing_cnt = 0
var lcd_monitor_wht = 0
var lcd_monitor_cnt = 0
var metal_wht = 0
var lab_wht = 0
var lab_cnt = 0
var trash_wht = 0
var battery_wht = 0
var battery_cnt = 0
var bulb_wht = 0
var bulb_cnt = 0
var detector_wht = 0
var detector_cnt = 0
var total_wht = 0

const BasicPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  ...theme.typography.body2,
  textAlign: 'center',
  }));

const LeftPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  ...theme.typography.body2,
  textAlign: 'center',
}));

const Job = ({ socket, isConnected }) => {
  const drawerWidth = 200;
  const auth = useAuth();
  const navigate = useNavigate();
  const [job, setJob] = useState({"job_id":"", "facility_id":"", "client_id":"", "service_level":"", "on_behalf_of":"", "date":"", "time":"", "type":"", "estimate":"", "invoice":"", "purchase_order":"", "reference_id":"", "public_notes":"", "private_notes":"", "status":{"weighed": false, "picked_up": false, "scheduled": false, "inventoried": false, "refurbished": false, "data_destroyed": false, "revenue_window": false, "quarantined_items": false}});
  const [facilityId, setFacilityId] = useState('')
  const [newForm, setNewForm] = useState(false)
  const [facility, setFacility] = useState({certifications:{}, operations_name: "", address:"7118 Geoffrey Way", address2:"Suite E", city:"Frederick", zip:"21704"});
  const [facilities, setFacilities] = useState([]);
  const [ locationId, setLocationId ] = useState('');
  const [location, setLocation] = useState({contract_details:{}, address:"7118 Geoffrey Way", address2:"Suite E", city:"Frederick", zip:"21704"});
  const [locations, setLocations] = useState([{contract_details:{}, address:"7118 Geoffrey Way", address2:"Suite E", city:"Frederick", zip:"21704"}]);
  const [partner, setPartner] = useState({contract_details:{}});
  const [partnerId, setPartnerId] = useState('')
  const [client, setClient] = useState({});
  const [clientId, setClientId] = useState('');
  const [clients, setClients] = useState([]);
  const [weights, setWeights] = useState([]);
  const [weightCount, setWeightCount] = useState(0);
  const [inventory, setInventory] = useState([]);
  const [inventoryCount, setInventoryCount] = useState(0);
  const [dataDestruction, setDataDestruction] = useState([]);
  const [dataDestructionCount, setDataDestructionCount] = useState(0);
  const [refurbishment, setRefurbishment] = useState([]);
  const [refurbishmentCount, setRefurbishmentCount] = useState(0);
  const [companyLogo, setCompanyLogo] = useState("company_logo")
  const [companyName, setCompanyName] = useState("IER")
  const [partnerLogo, setPartnerLogo] = useState("blank")
  const [statusHeader, SetStatusHeader] = useState("status_header")
  const [jobId2, setJobId] = useState('');
  let { jobId } = useParams();
  const[serviceLevel, setServiceLevel] = useState('');
  const [partners, setPartners] = useState([])
  const [isLoading1, setIsLoading1] = useState(true);
  const [isLoading2, setIsLoading2] = useState(true);
  const [isLoading3, setIsLoading3] = useState(true);
  const [isLoading4, setIsLoading4] = useState(true);
  const [isLoading5, setIsLoading5] = useState(true);
  const [readOnly, setReadOnly] = useState(true);
  const [fileName, setFileName] = useState('')

  const { register, setValue, getValues, handleSubmit, control, reset, watch, formState: { errors } } = useForm({ defaultValues: job });

  const { dirtyFields } = useFormState({ control });

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
      setAnchorEl(null);
  };

  const onSubmit = async (data, e) => {
    e.preventDefault();
    if(isConnected){
         socket.emit('jobs', {"function": "save", "data": {"hostname": auth.domain, "ip": auth.ip, "id": jobId, "payload": data, "user": auth.user}}, (response) => {
            if(response !== 'error'){
              setJob(response)
              setReadOnly(true);
              console.log(response)
              navigate("/job/" + response.job_id);
            }
         })
      }
  }

 const editClicked = () => {
      setReadOnly(false);
 };
 
  const cancelClicked = () => {
    // for (const [key, value] of Object.entries(job)) {
    //   setValue(key, value)
    // }
    setReadOnly(true);
    reset(job);
    if(jobId === 'add'){
         navigate('/jobs');
    }
  }
  const onError = (errors, e) => console.log(errors, e)

  const handleClientChange = (event) => {
    setClientId(event.target.value);
    setValue("client_id", event.target.value)
  };

  const handleFacilityChange = (event) => {
    setFacilityId(event.target.value);
    setValue("facility_id", event.target.value)
  };

  const handleLocationChange = (event) => {
    setLocationId(event.target.value);
    setValue("location_id", event.target.value)
  };

  const handlePartnerChange = (event) => {
    setPartnerId(event.target.value);
    setValue("on_behalf_of", event.target.value)
  };

  const handleServicelevelChange = (event) => {
    setServiceLevel(event.target.value)
    setValue("service_level", event.target.value)
  }

  var servLvl = [{"value" : "partner", "label" : "Partner"}, {"value" : "whitelabel", "label" : "White Label"}, {"value" : "Standard", "label" : "Standard"}];
  var jobbieType = [
    {"value" : "Pickup", "label" : "Pickup"}, {"value" : "Drop Off", "label" : "Drop Off"}, {"value" : "Equipment Relocation","label" : "Equipment Relocation"},
    {"value": "On-Site Event", "label": "On-Site Event"}, {"value": "In-House Event", "label": "In-House Event"}, {"value" : "On-Site Shred", "label" : "On-Site Shred"},
    {"value" : "In-House Shred", "label" : "In-House Shred"}
  ];

  useEffect(() => {
    if(isConnected){
      socket.emit("facilities", {"function":"get_all", "data": {"hostname": auth.domain, "ip": auth.ip, "user": auth.user}}, (response) => {
        if(response != 'error'){
          setFacilities(response);
        }
      })
      socket.emit("clients", {"function":"get_all", "data": {"hostname": auth.domain, "ip": auth.ip, "user": auth.user}}, (response) => {
        if(response != 'error'){
          setClients(response);
        }
      })
      if(jobId === 'add'){
        setIsLoading1(false);
        setIsLoading2(false);
        setIsLoading3(false);
        setIsLoading4(false);
        setIsLoading5(false);
        setReadOnly(false);
      }else{
        socket.emit("jobs", {"function":"get_one", "data": {"hostname": auth.domain, "ip": auth.ip, "id": jobId, "user": auth.user}}, (response) => {
          if(response != 'error'){
            setJob(response);
            setIsLoading1(false);
          }
        })
      }
    }
  }, [isConnected]);

  useEffect(() => {
    if(isConnected){
      if(job.location_id !== undefined){
        socket.emit("locations", {"function": "get_one", "data": {"hostname": auth.domain, "ip": auth.ip, "id": job.location_id, "user": auth.user}}, (response2) => {
          if(response2 != 'error'){
            setLocation(response2);
            setFileName(response2.address.split(' ').join('-').split('.').join('') + '-' + response2.city.split(' ').join('-').split('.').join('') + '-' + response2.state.split(' ').join('-') + '-' + response2.zip.split(' ').join('-') + '.' + job.date)
          }
        })
      }
      if(job.client_id !== undefined){
        socket.emit("clients", {"function": "get_one", "data": {"hostname": auth.domain, "ip": auth.ip, "id": job.client_id, "user": auth.user}}, (response3) => {
          if(response3 != 'error'){
            setClient(response3)
          }
        })
        socket.emit("locations", {"function": "get_all", "data": {"hostname": auth.domain, "ip": auth.ip, "id": job.client_id, "user": auth.user}}, (response) => {
          if(response != 'error'){
            setLocations(response)
            // console.log('job set')
            // console.log(response)
          }
        })
      }
      if(job.facility_id !== undefined){
        socket.emit("facilities", {"function":"get_one", "data": {"hostname": auth.domain, "ip": auth.ip, "id": job.facility_id, "user": auth.user}}, (response) => {
        setFacility(response)
        })
      }
      if(job.on_behalf_of !== undefined){
        socket.emit("clients", {"function": "get_one", "data": {"hostname": auth.domain, "ip": auth.ip, "id": job.on_behalf_of, "user": auth.user}}, (response) => {
        setPartner(response)
        })
      }
    }
    for (const [key, value] of Object.entries(job)) {
      setValue(key, value)
      if(key === 'client_id'){
        setClientId(value);
      }
    }
    setClientId(job.client_id)
    setServiceLevel(job.service_level)
  }, [job]);

  useEffect(() => {
    if(isConnected){
      if(job.on_behalf_of){
        if(job.service_level === 'whitelabel'){
          setCompanyName(partner.client_name)
          setCompanyLogo(partner.logo_id)
        }else if(job?.service_level === 'partner'){
          setPartnerLogo(partner.logo_id)
        }
      }
    }
    }, [partner]);

  useEffect(() => {
    if(isConnected){
      if( clientId != null ){
        socket.emit("locations", {"function": "get_all", "data": {"hostname": auth.domain, "ip": auth.ip, "id": clientId, "user": auth.user}}, (response4) => {
            if(response4 != 'error'){
              // console.log('clientId Change')
              // console.log(response4)
              setLocations(response4)
            }
        })
    }}
  }, [clientId]);

  useEffect(() => {
    if(isConnected){
      socket.emit("facilities", {"function":"get_one", "data": {"hostname": auth.domain, "ip": auth.ip, "id": facilityId, "user": auth.user}}, (response) => {
       setFacility(response)
      })
    }
  }, [facilityId]);

  useEffect(() => {
    if(isConnected){
      socket.emit("weights", {"function":"get_all", "data": {"hostname": auth.domain, "ip": auth.ip, "id": jobId, "user": auth.user}}, (response) => {
        // console.log(response)
        if(response != 'error'){
          inventory_cnt = 0
          data_bearing_cnt = 0
          whtCount = 0
          crt_wht = 0
          crt_32_cnt = 0
          crt_33_cnt = 0
          crt_broken_cnt = 0
          crt_broken_wht = 0
          crt_monitor_cnt = 0
          other_tv_wht = 0
          other_tv_cnt = 0
          lcd_tv_wht = 0
          lcd_tv_cnt = 0
          printer_wht = 0
          printer_cnt = 0
          consumer_wht = 0
          computing_wht = 0
          computing_cnt = 0
          lcd_monitor_wht = 0
          lcd_monitor_cnt = 0
          metal_wht = 0
          lab_wht = 0
          lab_cnt = 0
          trash_wht = 0
          battery_wht = 0
          battery_cnt = 0
          bulb_wht = 0
          bulb_cnt = 0
          detector_wht = 0
          detector_cnt = 0
          total_wht = 0
          response.forEach(element => {
            if(element.weight.crt_weight){
              crt_wht += parseInt(element.weight.crt_weight)
              crt_32_cnt += parseInt(element.weight.crt_32_count)
              crt_33_cnt += parseInt(element.weight.crt_33_count)
              crt_monitor_cnt += parseInt(element.weight.crt_monitor_count)
            }
            if(element.weight.other_tv_weight){
              if(parseInt(element.weight.othger_tv_weight) > 0){
                other_tv_wht += parseInt(element.weight.othger_tv_weight)
              }
              if(parseInt(element.weight.other_tv_count) > 0){
                other_tv_cnt += parseInt(element.weight.other_tv_count)
              }
            }
            if(element.weight.lcd_tv_weight){
              lcd_tv_wht += parseInt(element.weight.lcd_tv_weight)
              lcd_tv_cnt += parseInt(element.weight.lcd_tv_count)
            }
            if(element.weight.printer_weight){
              printer_wht += parseInt(element.weight.printer_weight)
              printer_cnt += parseInt(element.weight.printer_count)
            }
            if(element.weight.consumer_weight){
              consumer_wht += parseInt(element.weight.consumer_weight)
            }
            if(element.weight.computing_weight){
              computing_wht += parseInt(element.weight.computing_weight)
              computing_cnt += parseInt(element.weight.computing_count)
            }
            if(element.weight.lcd_monitor_weight){
              lcd_monitor_wht += parseInt(element.weight.lcd_monitor_weight)
              if(parseInt(element.weight.lcd_monitor_count) > 0){
                lcd_monitor_cnt += parseInt(element.weight.lcd_monitor_count)
              }
            }
            if(element.weight.trash_weight){
              trash_wht += parseInt(element.weight.trash_weight)
            }
            if(element.weight.battery_weight){
              battery_wht += parseInt(element.weight.battery_weight)
              if(parseInt(element.weight.battery_count) > 0){
                battery_cnt += parseInt(element.weight.battery_count)
              }
            }
            if(element.weight.bulb_weight){
              bulb_wht += parseInt(element.weight.bulb_weight)
              if(parseInt(element.weight.bulb_count) > 0){
                bulb_cnt += parseInt(element.weight.bulb_count)
              }
            }
            if(element.weight.detector_weight){
              detector_wht += parseInt(element.weight.detector_weight)
              if(parseInt(element.weight.detector_count) > 0){
                detector_cnt += parseInt(element.weight.detector_count)
              }
            }
            if(element.weight.metal_weight){
              metal_wht += parseInt(element.weight.metal_weight)
            }
            if(element.weight.lab_weight){
              lab_wht += parseInt(element.weight.lab_weight)
              if(parseInt(element.weight.lab_count) > 0){
                lab_cnt += parseInt(element.weight.lab_count)
              }
            }
            whtCount += 1
            setWeights(response);
            setWeightCount(whtCount)
          });
          setIsLoading2(false);
          total_wht = crt_wht + lcd_tv_wht + printer_wht + consumer_wht + computing_wht + lcd_monitor_wht + other_tv_wht + metal_wht + lab_wht + trash_wht + battery_wht + bulb_wht + detector_wht
        }
      });

      socket.emit("inventory", {"function":"get_all", "data": {"hostname": auth.domain, "ip": auth.ip, "id": jobId, "user": auth.user}}, (response) => {
        // console.log(response)
        if(response != 'error'){
          setInventory(response);
          var invCount = 0
          response.forEach(element => {
            invCount += 1
            setInventoryCount(invCount)
          });
          setIsLoading3(false);
        }
      });

      socket.emit("data_destruction", {"function":"get_all", "data": {"hostname": auth.domain, "ip": auth.ip, "id": jobId, "user": auth.user}}, (response) => {
        // console.log(response)
        if(response != 'error'){
          setDataDestruction(response);
          var desCount = 0
          response.forEach(element => {
            desCount += 1
            setDataDestructionCount(desCount)
          });
          setIsLoading4(false);
        }
      });

      socket.emit("refurbishment", {"function":"get_all", "data": {"hostname": auth.domain, "ip": auth.ip, "id": jobId, "user": auth.user}}, (response) => {
        // console.log(response)
        if(response != 'error'){
          setRefurbishment(response);
          var refCount = 0
          response.forEach(element => {
            refCount += 1
            setRefurbishmentCount(refCount)
          });
          setIsLoading5(false);
        }
      });
    }
  }, [clientId]);

  useEffect(() =>{
    if(isConnected){
      socket.emit("clients", {"function": "get_partners", "data": {"hostname": auth.domain, "ip": "0.0.0.0", "id": serviceLevel, "user": auth.user}}, (response) => {
        if(response != 'error'){
          setPartners(response)
        }
      })
    } 
    }, [serviceLevel]);

  // useEffect(() =>{
  //   if(isConnected){
  //     socket.emit("locations", {"function": "get_all", "data": {"hostname": auth.domain, "ip": "0.0.0.0", "id": job.client_id, "user": auth.user}}, (response) => {
  //       setLocations(response)
  //     })
  //     if(job.on_behalf_of){
  //       socket.emit("clients", {"function": "get_one", "data": {"hostname": auth.domain, "ip": "0.0.0.0", "id": job.on_behalf_of, "user": auth.user}}, (response) => {
  //       setPartner(response)
  //       })
  //     }
  //   }
  //   for (const [key, value] of Object.entries(job)) {
  //     setValue(key, value)
  //   }
  //   setClientId(job.client_id)
  //   setServiceLevel(job.service_level)
  // }, [job])

  useEffect(() =>{
    if(isConnected){
        socket.emit("clients", {"function": "get_one", "data": {"hostname": auth.domain, "ip": "0.0.0.0", "id": clientId, "user": auth.user}}, (response) => {
          setClient(response)
        })
        
    }
  }, [clientId])

  let weightMap = weights.map(weight => {
    return <p>{weight.name}</p>
  })

  let inventoryMap = inventory.map(item => {
    return <p>{item.id}</p>
  })

  let dataDestructionMap = dataDestruction.map(item => {
    return <p>{item.id}</p>
  })

  let refurbishmentMap = refurbishment.map(item => {
    return <p>{item.id}</p>
  })
  // Create styles
  const pdfStyles = StyleSheet.create({
    page: {
      backgroundColor: '#ffffff',
      margin: 0,
      padding: 0,
      position: 'relative'
    },
    header_qehsms: {
      position: 'absolute',
      textAlign: 'center',
      top: 13,
      left: 91,
      width: 135,
      fontSize: 13,
      fontFamily: 'Helvetica-Bold',
      lineHeight: 1.25
    },
    header_rerere: {
      position: 'absolute',
      textAlign: 'left',
      top: 14,
      left: 238,
      width: 38,
      fontSize: 8,
      fontFamily: 'Helvetica',
      lineHeight: 1.2,
      flex: 1
    },
    header_did: {
      position: 'absolute',
      textAlign: 'center',
      fontFamily: 'Helvetica-Bold',
      top: 17,
      left: 276,
      width: 272,
      fontSize: 20
    },
    header_epa_info: {
      position: 'absolute',
      textAlign: 'left',
      top: 48,
      left: 238,
      width: 191,
      fontSize: 8,
      fontFamily: 'Helvetica-Bold',
      lineHeight: 1.2,
      flex: 1,
      overflow: 'hidden'
    },
    header_doc_info: {
      position: 'absolute',
      textAlign: 'left',
      top: 48,
      left: 434,
      width: 166,
      fontSize: 8,
      fontFamily: 'Helvetica-Bold',
      lineHeight: 1.2,
      flex: 1
    },
    header_company_info: {
      position: 'absolute',
      textAlign: 'center',
      top: 83,
      left: 406,
      width: 194,
      fontSize: 8,
      fontFamily: 'Helvetica-Bold',
      lineHeight: 1.3,
      flex: 1
    },
    header_client_info: {
      position: 'absolute',
      textAlign: 'left',
      top: 83,
      left: 175,
      width: 226,
      fontSize: 8,
      fontFamily: 'Helvetica-Bold',
      lineHeight: 1.3,
      flex: 1
    },
    header_partner_logo: {
      position: 'absolute',
      top: 81,
      left: 12,
      width: 160,
      height: 44
    },
    headerImage: {
      height: 81,
      width: 81,
      position: 'absolute',
      top: 0,
      left: 0,
      margin: 0,
      paddingTop: 12,
      paddingLeft: 12
    },
    footer_sigs_title: {
      height: 30,
      width: 588,
      position: 'absolute',
      top: 680,
      left: 12,
      margin: 0,
      justifyContent: 'space-evenly',
      fontFamily: 'Helvetica',
      paddingLeft: 20,
      paddingRight: 20,
      flexDirection: 'row',
      display: 'flex',
      fontSize: 6
    },
    footer_sigs: {
      height: 25,
      width: 588,
      position: 'absolute',
      top: 700,
      left: 12,
      margin: 0,
      justifyContent: 'space-evenly',
      fontFamily: 'Helvetica',
      paddingLeft: 60,
      paddingRight: 60,
      flexDirection: 'row',
      display: 'flex',
      fontSize: 8
    },
    header_footer_images: {
      height: 50,
      width: 588,
      position: 'absolute',
      top: 730,
      left: 12,
      margin: 0,
      justifyContent: 'space-evenly',
      paddingLeft: 10,
      paddingRight: 10,
      flexDirection: 'row',
      display: 'flex'
    },
    pageNumber: {
      position: 'absolute',
      margin: 0,
      top: 29,
      left: 555
    },
    header_doc_title: {
      position: 'absolute',
      textAlign: 'center',
      fontFamily: 'Helvetica-Bold',
      top: 130,
      left: 12,
      width: 588,
      fontSize: 20
    },
    document_page_body: {
      position: 'absolute',
      textAlign: 'Left',
      fontFamily: 'Helvetica',
      top: 165,
      left: 12,
      width: 588,
      fontSize: 9,
      lineHeight: 1.5
    },
    document_body_head: {
      position: 'absolute',
      textAlign: 'center',
      fontFamily: 'Helvetica',
      top: 165,
      left: 12,
      width: 588,
      fontSize: 9,
      lineHeight: 1.5,
      paddingLeft: 20,
      paddingRight: 20
    },
    document_body_content: {
      position: 'absolute',
      textAlign: 'left',
      fontFamily: 'Helvetica',
      top: 310,
      left: 12,
      width: 588,
      height: 360,
      fontSize: 9,
      lineHeight: 1.5,
      paddingLeft: 15,
      paddingRight: 15
    },
    section: {
      margin: 10,
      padding: 10,
      position: 'absolute'
    },
    line1: {
      x1: 82, //starting coords are x1 and y1
      y1: 80,
      x2: 600, //ending coords:
      y2: 80,
      strokeWidth: 1.5,
      stroke: "#4cae4c", //stroke color
      position: 'absolute',
      top: 0,
      left: 0
    },
    line2: {
      x1: 235, //starting coords are x1 and y1
      y1: 12,
      x2: 235, //ending coords:
      y2: 80,
      strokeWidth: 1.5,
      stroke: "#4cae4c", //stroke color
      position: 'absolute',
      top: 0,
      left: 0
    },
    line3: {
      x1: 235, //starting coords are x1 and y1
      y1: 45,
      x2: 600, //ending coords:
      y2: 45,
      strokeWidth: 1.5,
      stroke: "#4cae4c", //stroke color
      position: 'absolute',
      top: 0,
      left: 0
    },
    line4: {
      x1: 431, //starting coords are x1 and y1
      y1: 45,
      x2: 431, //ending coords:
      y2: 80,
      strokeWidth: 1.5,
      stroke: "#4cae4c", //stroke color
      position: 'absolute',
      top: 0,
      left: 0
    },
    line5: {
      x1: 12, //starting coords are x1 and y1
      y1: 126,
      x2: 600, //ending coords:
      y2: 126,
      strokeWidth: 1.5,
      stroke: "#4cae4c", //stroke color
      position: 'absolute',
      top: 0,
      left: 0
    },
    line6: {
      x1: 12, //starting coords are x1 and y1
      y1: 156,
      x2: 600, //ending coords:
      y2: 156,
      strokeWidth: 1.5,
      stroke: "#4cae4c", //stroke color
      position: 'absolute',
      top: 0,
      left: 0
    },
    line7: {
      x1: 403, //starting coords are x1 and y1
      y1: 80,
      x2: 403, //ending coords:
      y2: 126,
      strokeWidth: 1.5,
      stroke: "#4cae4c", //stroke color
      position: 'absolute',
      top: 0,
      left: 0
    },
    line8: {
      x1: 172, //starting coords are x1 and y1
      y1: 80,
      x2: 172, //ending coords:
      y2: 126,
      strokeWidth: 1.5,
      stroke: "#4cae4c", //stroke color
      position: 'absolute',
      top: 0,
      left: 0
    },
    line9: {
      x1: 12, //starting coords are x1 and y1
      y1: 722,
      x2: 600, //ending coords:
      y2: 722,
      strokeWidth: 1.5,
      stroke: "#4cae4c", //stroke color
      position: 'absolute',
      top: 0,
      left: 0
    }
  });
  const [getPiePng, { ref: pieRef }] = useCurrentPng();
  const handlePieDownload = useCallback(async () => {
    const png = await getPiePng();
    if (png) {
      FileSaver.saveAs(png, "pie-chart.png");
    }
  }, [getPiePng]);
  
  const CorHeader = () => (
    <>
    <Image fixed style={pdfStyles.headerImage} src={"/assets/img/company_logos/" + companyLogo + ".png"} />
    <Image fixed style={pdfStyles.header_partner_logo} src={"/assets/img/partner_logos/" + partnerLogo + ".png"} />
    <View style={pdfStyles.pageNumber} fixed render={({ pageNumber, totalPages }) => (<Text style={{ color: 'grey', fontSize: 8 }}>Page: {pageNumber} / {totalPages}</Text>)} />
  <View style={pdfStyles.header_qehsms} fixed>
    <Text>Quality</Text>
    <Text>Environmental</Text>
    <Text>Health & Safety</Text>
    <Text>Management System</Text>
  </View>
  <View style={pdfStyles.header_rerere} fixed>
    <View style={{flexDirection: 'row'}}>
      <Text style={{fontFamily: 'Helvetica-Bold'}}>RE</Text><Text>use</Text>
    </View>
    <View style={{flexDirection: 'row'}}>
      <Text style={{fontFamily: 'Helvetica-Bold'}}>RE</Text><Text>cover</Text>
    </View>
    <View style={{flexDirection: 'row'}}>
      <Text style={{fontFamily: 'Helvetica-Bold'}}>RE</Text><Text>cycle</Text>
    </View>
  </View>
  <View style={pdfStyles.header_did} fixed>
    <Text>DID: FRM-002</Text>
  </View>
  <View style={pdfStyles.header_epa_info} fixed>
    <View style={{flexDirection: 'row'}} wrap={false}>
      <Text style={{fontFamily: 'Helvetica'}}>EPA ID: </Text><Text>{facility.certifications?.epa_id}</Text>
    </View>
    <View style={{flexDirection: 'row'}} wrap={false}>
      <Text style={{fontFamily: 'Helvetica'}}>SAMS: </Text><Text>{facility.certifications?.sams}</Text>
    </View>
    <View style={{flexDirection: 'row'}} wrap={false}>
      <Text style={{fontFamily: 'Helvetica'}}>DUNS: </Text><Text>{facility.certifications?.duns}                   </Text>
      <Text style={{fontFamily: 'Helvetica'}}>CAGE: </Text><Text>{facility.certifications?.cage}</Text>
    </View>
  </View>
  <View style={pdfStyles.header_doc_info} fixed>
    <View style={{flexDirection: 'row'}}>
      <Text style={{fontFamily: 'Helvetica'}}>Type: </Text><Text>Administration Form v1.0</Text>
    </View>
    <View style={{flexDirection: 'row'}}>
      <Text style={{fontFamily: 'Helvetica'}}>Implemented: </Text><Text>2024-05-10 </Text><Text style={{fontFamily: 'Helvetica'}}>by </Text><Text>eda</Text>
    </View>
    <View style={{flexDirection: 'row'}}>
      <Text style={{fontFamily: 'Helvetica'}}>Last Revision: </Text><Text>2024-05-10 </Text><Text style={{fontFamily: 'Helvetica'}}>by </Text><Text>eda</Text>
    </View>
  </View>
  <View style={pdfStyles.header_client_info} fixed>
    <View style={{flexDirection: 'row'}} wrap={false}>
      <Text style={{fontFamily: 'Helvetica'}}>GoM: </Text><Text>{client?.client_name}</Text>
    </View>
    <View style={{flexDirection: 'row'}} wrap={false}>
      <Text style={{fontFamily: 'Helvetica'}}>Location: </Text><Text>{location?.location_name}</Text>
    </View>
    <View style={{flexDirection: 'row'}} wrap={false}>
      <Text style={{fontFamily: 'Helvetica'}}>Issued On: </Text><Text>{job?.date}</Text>
    </View>
    <View style={{flexDirection: 'row'}} wrap={false}>
      <Text style={{fontFamily: 'Helvetica'}}>Job ID: </Text><Text>{job?.job_id} </Text><Text style={{fontFamily: 'Helvetica'}}>E#</Text><Text>{job.estimate} </Text><Text style={{fontFamily: 'Helvetica'}}>I#</Text><Text>{job.invoice} </Text><Text style={{fontFamily: 'Helvetica'}}>P#</Text><Text>{job.purchase_order} </Text><Text style={{fontFamily: 'Helvetica'}}>R#</Text><Text>{job.reference_id}</Text>
    </View>
  </View>
  <View style={pdfStyles.header_company_info} fixed>
    <Text>{facility.address} {facility.address2} {facility.city} {facility.state} {facility.zip}</Text>
    <Text>help@ierpro.com</Text>
    <Text>www.ierpro.com</Text>
    <Text>(720) 645-7497</Text>
  </View></>
  );

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', 'green', 'black', 'purple', 'red', 'blue', 'pink', 'orange', 'brown'];
  const data = [
    { name: 'Mixed Electronics', value: consumer_wht },
    { name: 'CRT', value: crt_wht },
    { name: 'Other TV', value: other_tv_wht },
    { name: 'LCD TV', value: lcd_tv_wht },
    { name: 'LCD Monitor', value: lcd_monitor_wht },
    { name: 'Loose HDD', value: printer_wht },
    { name: 'Computing', value: computing_wht },
    { name: 'Metal', value: metal_wht },
    { name: 'Lab Equipment', value: lab_wht },
    { name: 'Trash', value: trash_wht },
    { name: 'Battery', value: battery_wht },
    { name: 'Bulb', value: bulb_wht },
    { name: 'Detector', value: detector_wht },
  ];

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    var radius = 30 + outerRadius;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
  
    if(data[index].value > 0){
      return (
        <text x={x} y={y} fill={COLORS[index % COLORS.length]} textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="outside">
          {data[index].name} {`${(percent * 100).toFixed(0)}%`}
        </text>
      );
    };
  }
  
  const CorChart = () => (
      <PieChart width={600} height={600}  ref={pieRef}>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          labelLine={false}
          label={renderCustomizedLabel}
          outerRadius={100}
          fill="white"
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
  );

  var weights_for_cert = weights.map(function(w, i){
      return (
        <View key={i}>
          <Text>ID: {w.id}</Text>
          <Text>Metal: {w.weight.metal_weight} lbs</Text>
          <Text>Mixed Electronics: {w.weight.consumer_count} items @ {w.weight.consumer_weight} lbs</Text>
          <Text>Computing: {w.weight.computing_count} items @ {w.weight.computing_weight} lbs</Text>
          <Text>Loose HDD: {w.weight.printer_count} items @ {w.weight.printer_weight} lbs</Text>
          <Text>Batteries: {w.weight.battery_count} items @ {w.weight.battery_weight} lbs</Text>
          <Text>Bulbs: {w.weight.bulb_count} items @ {w.weight.bulb_weight} lbs</Text>
          <Text>CRT under 33": {w.weight.crt_32_cnt} items</Text>
          <Text>CRT over 3": {w.weight.crt_33_cnt} items</Text>
          <Text>CRT Monitor: {w.weight.crt_monitor_cnt} items</Text>
          <Text>CRT Weight: {w.weight.crt_weight} lbs</Text>
          <Text>LCD Monitor: {w.weight.lcd_monitor_count} items @ {w.weight.lcd_monitor_weight} lbs</Text>
          <Text>LCD TV: {w.weight.lcd_tv_count} items @ {w.weight.lcd_tv_weight} lbs</Text>
          <Text>Other TV: {w.weight.other_tv_count} items @ {w.weight.other_tv_weight} lbs</Text>
          <Text>Detectors: {w.weight.detector_count} items @ {w.weight.detector_weight} lbs</Text>
          <Text>Lab Equipment: {w.weight.lab_count} items @ {w.weight.lab_weight} lbs</Text>
          <Text>Trash: {w.weight.trash_weight} lbs</Text>
        </View>
      );
  });

  var inventory_for_cert = inventory.map(function(inv, i){
    var storage_for_cert_item = ''
    inv.device_storage.forEach(item => {
      storage_for_cert_item += item.disk.serial += ', '
    })
    return (
      <View key={i}>
          <Text>{inv.id}  -  Category: {inv.device_category}  -  Vendor: {inv.device_vendor}  -  Model: {inv.device_name}  -  S/N: {inv.device_serial}  -  Barcode: {inv.device_asset_tag}</Text>
          <Text>Storage: {storage_for_cert_item}</Text>
      </View>
    );
  });

  var data_destruction_for_cert = dataDestruction.map(function(dd, i){
    return (
      <View key={i}>
          <Text>{dd.id}</Text>
      </View>
    );
  });

  const MyDocument = () => (
    <Document>
      <Page size="LETTER" style={pdfStyles.page}>
      <Svg fixed width={"100%"} height={"100%"}>
        <SvgLine style={pdfStyles.line1} />
        <SvgLine style={pdfStyles.line2} />
        <SvgLine style={pdfStyles.line3} />
        <SvgLine style={pdfStyles.line4} />
        <SvgLine style={pdfStyles.line5} />
        <SvgLine style={pdfStyles.line6} />
        <SvgLine style={pdfStyles.line7} />
        <SvgLine style={pdfStyles.line8} />
        <SvgLine style={pdfStyles.line9} />
      </Svg>
        <CorHeader />
        <View style={pdfStyles.header_doc_title} fixed>
          <Text>Certificate of Recycle & Data Sanitization</Text>
        </View>
        <View style={pdfStyles.document_body_head}>
          <Text>This document hereby certifies that ownership of the equipment listed on the attached document(s) were transferred to and received by {companyName}.{'\n'}{'\n'}{companyName} has pledged to assume all responsibility for processing, recycling and/or destroying the undermentioned items in accordance with all applicable federal, state and municipal laws, guidelines, regulations and standards regarding the proper disposal of hazardous electronic waste and certifies that all data containing media received has been sanitized beyond forensic reconstruction in conformance with NIST 800-88 r1.{'\n'}{'\n'}Any data containing devices or media that were unable to be processed as requested will be degaussed and/or shredded.{'\n'}Any device that cannot be physically destroyed will be handled by a certified downstream vendor.</Text>
        </View>
        <View style={pdfStyles.document_body_content}>
          <Image fixed style={{ width: 558, height:50 }} src={"/assets/img/" + statusHeader + ".png"} />
          <View>
            <Text>This material is to be {location.contract_details?.ewaste_handling} and all data is to be {location.contract_details?.data_handling}.</Text>
          </View>
          <View>
            <Text>{'\n'}</Text>
            <Text>Metal: {metal_wht} lbs</Text>
            <Text>Mixed Electronics: {consumer_wht} lbs</Text>
            <Text>Inventory: {inventory_cnt} items</Text>
            <Text>Data Bearing: {computing_cnt} items @ {computing_wht} lbs</Text>
            <Text>Loose HDD: {printer_cnt} items @ {printer_wht} lbs</Text>
            <Text>Batteries: {battery_cnt} items @ {battery_wht} lbs</Text>
            <Text>Bulbs: {bulb_cnt} items @ {bulb_wht} lbs</Text>
            <Text>CRT under 32": {crt_32_cnt} items</Text>
            <Text>CRT over 33": {crt_33_cnt} items</Text>
            <Text>CRT Monitors: {crt_monitor_cnt} items</Text>
            <Text>CRT Weight: {crt_wht} lbs</Text>
            <Text>Broken CRT: {crt_broken_cnt} items @ {crt_broken_wht} lbs</Text>
            <Text>LCD Monitor: {lcd_monitor_cnt} items @ {lcd_monitor_wht} lbs</Text>
            <Text>LCD TV: {lcd_tv_cnt} items @ {lcd_tv_wht} lbs</Text>
            <Text>Other TV: {other_tv_cnt} items @ {other_tv_wht} lbs</Text>
            <Text>Detectors: {detector_cnt} items @ {detector_wht} lbs</Text>
            <Text>Lab Equipment: {lab_cnt} items @ {lab_wht} lbs</Text>
            <Text>Trash: {trash_wht} lbs</Text>
          </View>
          <View>
            <Text>{'\n'}{'\n'}</Text>
            <Text>Total: {whtCount} Weights @ {crt_wht + crt_broken_wht + lcd_tv_wht + printer_wht + consumer_wht + computing_wht + lcd_monitor_wht + other_tv_wht + metal_wht + lab_wht + trash_wht + battery_wht + bulb_wht + detector_wht} lbs</Text>
          </View>
        </View>
        <View style={pdfStyles.footer_sigs_title}>
          <Text>Material was processed under the surveillance of the folliwing individual(s):</Text>
        </View>
        <View style={pdfStyles.footer_sigs} >
          <View style={{flexDirection: 'row'}}>
            <Text style={{fontFamily: 'Helvetica'}}>Facility Manager - </Text><Text>{facility.operations_name}</Text>
          </View>
          <Text>|</Text>
          <View style={{flexDirection: 'row'}}>
            <Text style={{fontFamily: 'Helvetica'}}>Audit Technician - </Text><Text>{facility.inventory_name}</Text>
          </View>
          <Text>|</Text>
          <View style={{flexDirection: 'row'}}>
            <Text style={{fontFamily: 'Helvetica'}}>Data Technician - </Text><Text>{facility.data_destruction_name}</Text>
          </View>
        </View>
        <View style={pdfStyles.header_footer_images} >
          {/* <Image src="/assets/img/QEHS21.png" />
          <Image src="/assets/img/r2v3-logo.png" />
          <Image src="/assets/img/naid-aaa-logo.png" />
          <Image src="/assets/img/VOSB-logo.png" />
          <Image src="/assets/img/orion-logo.png" />
          <Image src="/assets/img/e-stewards-certified-logo.png" /> */}
        </View>
      </Page>
      {/* <Page size="LETTER" style={pdfStyles.page} id='weights'>
        <Svg fixed width={"100%"} height={"100%"}>
          <SvgLine style={pdfStyles.line1} />
          <SvgLine style={pdfStyles.line2} />
          <SvgLine style={pdfStyles.line3} />
          <SvgLine style={pdfStyles.line4} />
          <SvgLine style={pdfStyles.line5} />
          <SvgLine style={pdfStyles.line6} />
          <SvgLine style={pdfStyles.line7} />
          <SvgLine style={pdfStyles.line8} />
        </Svg>
        <CorHeader />
        <View style={pdfStyles.header_doc_title} fixed>
          <Text>Weights</Text>
        </View>
        <View style={pdfStyles.document_page_body}>
          {weights_for_cert}
        </View>
      </Page> */}
      <Page size="LETTER" style={pdfStyles.page} id='inventory'>
        <Svg fixed width={"100%"} height={"100%"}>
          <SvgLine style={pdfStyles.line1} />
          <SvgLine style={pdfStyles.line2} />
          <SvgLine style={pdfStyles.line3} />
          <SvgLine style={pdfStyles.line4} />
          <SvgLine style={pdfStyles.line5} />
          <SvgLine style={pdfStyles.line6} />
          <SvgLine style={pdfStyles.line7} />
          <SvgLine style={pdfStyles.line8} />
        </Svg>
        <CorHeader />
        <View style={pdfStyles.header_doc_title} fixed>
          <Text>Inventory</Text>
        </View>
        <View style={pdfStyles.document_page_body}>
          {inventory_for_cert}
        </View>
      </Page>
      {/* <Page size="LETTER" style={pdfStyles.page} id='data_destruction'>
        <Svg fixed width={"100%"} height={"100%"}>
          <SvgLine style={pdfStyles.line1} />
          <SvgLine style={pdfStyles.line2} />
          <SvgLine style={pdfStyles.line3} />
          <SvgLine style={pdfStyles.line4} />
          <SvgLine style={pdfStyles.line5} />
          <SvgLine style={pdfStyles.line6} />
          <SvgLine style={pdfStyles.line7} />
          <SvgLine style={pdfStyles.line8} />
        </Svg>
        <CorHeader />
        <View style={pdfStyles.header_doc_title} fixed>
          <Text>Data Destruction</Text>
        </View>
        <View style={pdfStyles.document_page_body}>
          {data_destruction_for_cert}
        </View>
      </Page> */}
    </Document>
  );

  const onWeightClick = (e, row) => {
    e.stopPropagation();
    console.log(row);
    navigate('/weight/' + job.job_id + '/' + row);
  };

  const onInventoryClick = (e, row) => {
    e.stopPropagation();
    console.log(row);
    navigate('/inventory/item/' + row);
  };

  const onRefurbishmentClick = (e, row) => {
    e.stopPropagation();
    console.log(row);
    navigate('/refurbishment/item/' + row);
  };

  const onDataDestructionClick = (e, row) => {
    e.stopPropagation();
    console.log(row);
    navigate('/data_destruction/item/' + row);
  };

    const weightColumns: GridColDef[] = [
      { field: 'id', headerName: 'ID', width: 150 },
      { field: 'viewButton', headerName: 'Actions', description: 'Actions column.', sortable: false, width: 160,
        renderCell: (params) => {
          return (
            <Button
              onClick={(e) => onWeightClick(e, params.row?.weight_id)}
              variant="contained"
            >
              View
            </Button>
          );
        },
      },
      { field: 'consumer_weight', headerName: 'Mixed Electronics', width: 150, valueGetter: (params) => params.row?.weight?.consumer_weight + 'lbs.' },
      { field: 'computing_weight', headerName: 'Computing', width: 150, valueGetter: (params) => params.row?.weight?.computing_weight + 'lbs.' },
      { field: 'computing_count', headerName: 'Computing Count', width: 150, valueGetter: (params) => params.row?.weight?.computing_count },
      { field: 'printer_weight', headerName: 'Loose HDD', width: 150, valueGetter: (params) => params.row?.weight?.printer_weight + 'lbs.' },
      { field: 'printer_count', headerName: 'Loose HDD Count', width: 150, valueGetter: (params) => params.row?.weight?.printer_count },
      { field: 'lcd_monitor_weight', headerName: 'LCD Monitor', width: 150, valueGetter: (params) => params.row?.weight?.lcd_monitor_weight + 'lbs.' },
      { field: 'lcd_monitor_count', headerName: 'LCD Monitor Count', width: 150, valueGetter: (params) => params.row?.weight?.lcd_monitor_count },
      { field: 'lcd_tv_weight', headerName: 'LCD TV', width: 150, valueGetter: (params) => params.row?.weight?.lcd_tv_weight + 'lbs.' },
      { field: 'lcd_tv_count', headerName: 'LCD TV Count', width: 150, valueGetter: (params) => params.row?.weight?.lcd_tv_count },
      { field: 'crt_weight', headerName: 'CRT', width: 150, valueGetter: (params) => params.row?.weight?.crt_weight + 'lbs.' },
      { field: 'crt_monitor_count', headerName: 'CRT Monitor Count', width: 150, valueGetter: (params) => params.row?.weight?.crt_monitor_count },
      { field: 'crt_32_count', headerName: 'CRT >32" Count', width: 150, valueGetter: (params) => params.row?.weight?.crt_monitor_count },
      { field: 'crt_33_count', headerName: 'CRT <33" Count', width: 150, valueGetter: (params) => params.row?.weight?.crt_monitor_count },
      { field: 'other_tv_weight', headerName: 'Other TV', width: 150, valueGetter: (params) => params.row?.weight?.other_tv_weight + 'lbs.' },
      { field: 'other_tv_count', headerName: 'Other TV Count', width: 150, valueGetter: (params) => params.row?.weight?.other_tv_count },
      { field: 'qtine_weight', headerName: 'Quarantine', width: 150, valueGetter: (params) => params.row?.weight?.qtine_weight + 'lbs.' },
      { field: 'qtine_count', headerName: 'Quarantine Count', width: 150, valueGetter: (params) => params.row?.weight?.qtine_count },
      { field: 'trash_weight', headerName: 'Trash', width: 150, valueGetter: (params) => params.row?.weight?.trash_weight + 'lbs.' },
      { field: 'metal_weight', headerName: 'Metal', width: 150, valueGetter: (params) => params.row?.weight?.metal_weight + 'lbs.' },
      { field: 'battery_weight', headerName: 'Battery', width: 150, valueGetter: (params) => params.row?.weight?.battery_weight + 'lbs.' },
      { field: 'battery_count', headerName: 'Battery Count', width: 150, valueGetter: (params) => params.row?.weight?.battery_count },
      { field: 'bulb_weight', headerName: 'Bulb', width: 150, valueGetter: (params) => params.row?.weight?.bulb_weight + 'lbs.' },
      { field: 'bulb_count', headerName: 'Bulb Count', width: 150, valueGetter: (params) => params.row?.weight?.bulb_count },
      { field: 'detector_weight', headerName: 'Detector', width: 150, valueGetter: (params) => params.row?.weight?.detector_weight + 'lbs.' },
      { field: 'detector_count', headerName: 'Detector Count', width: 150, valueGetter: (params) => params.row?.weight?.detector_count },
      { field: 'notes', headerName: 'Notes', width: 300 },
    ];

    const inventoryColumns: GridColDef[] = [
      { field: 'id', headerName: 'ID', width: 150 },
      { field: 'viewButton', headerName: 'Actions', description: 'Actions column.', sortable: false, width: 160,
        renderCell: (params) => {
          return (
            <Button
              onClick={(e) => onInventoryClick(e, params.row?.device_serial)}
              variant="contained"
            >
              View
            </Button>
          );
        },
      },
      { field: 'device_vendor', headerName: 'Vendor', width: 150 },
      { field: 'device_name', headerName: 'Name', width: 150 },
      { field: 'device_category', headerName: 'Category', width: 150 },
      { field: 'device_serial', headerName: 'Device Serial', width: 150 },
      { field: 'device_asset_tag', headerName: 'Asset Tag', width: 150 },
      { field: 'device_storage', headerName: 'Storage in GB', width: 150, valueGetter: (params) => params.row?.device_storage[0]?.size },
      { field: 'processor_amount', headerName: 'CPUs', width: 300, valueGetter: (params) => params.row?.processors?.length },
      { field: 'processor_vendor', headerName: 'CPU Model', width: 300, valueGetter: (params) => params.row?.processors[0]?.product },
      { field: 'memory', headerName: 'Ram in GB', width: 150, valueGetter: (params) => (((params.row?.memory[0]?.size / 1024) / 1024) / 1024) },
      { field: 'memory_type', headerName: 'Ram Type', width: 300, valueGetter: (params) => params.row?.memory[0]?.description },
      { field: 'batteries', headerName: 'Battery Health', width: 150, valueGetter: (params) => params.row?.batteries[0]?.detail?.capacity },
    ];
    
    const dataDestructionColumns: GridColDef[] = [
      { field: 'id', headerName: 'ID', width: 150 },
      { field: 'viewButton', headerName: 'Actions', description: 'Actions column.', sortable: false, width: 160,
        renderCell: (params) => {
          return (
            <Button
              onClick={(e) => onDataDestructionClick(e, params.row?.serial)}
              variant="contained"
            >
              View
            </Button>
          );
        },
      },
      { field: 'serial', headerName: 'Serial', width: 150 },
      { field: 'machine', headerName: 'Machine', width: 150 },
      { field: 'method', headerName: 'Method', width: 150 },
      { field: 'size', headerName: 'Size', width: 150 },
      { field: 'kind', headerName: 'Kind', width: 150 },
      { field: 'result', headerName: 'Result', width: 150 },
      { field: 'health', headerName: 'Health', width: 150 },
    ];

    const refurbishmentColumns: GridColDef[] = [
      { field: 'id', headerName: 'ID', width: 150 },
      { field: 'viewButton', headerName: 'Actions', description: 'Actions column.', sortable: false, width: 160,
        renderCell: (params) => {
          return (
            <Button
              onClick={(e) => onRefurbishmentClick(e, params.row?.device_serial)}
              variant="contained"
            >
              View
            </Button>
          );
        },
      },
      { field: 'device_vendor', headerName: 'Vendor', width: 150 },
      { field: 'device_name', headerName: 'Name', width: 150 },
      { field: 'device_category', headerName: 'Category', width: 150 },
      { field: 'device_serial', headerName: 'Device Serial', width: 150 },
      { field: 'device_storage', headerName: 'Storage in GB', width: 150, valueGetter: (params) => params.row?.device_storage[0]?.size },
      { field: 'processor_amount', headerName: 'CPUs', width: 300, valueGetter: (params) => params.row?.processors?.length },
      { field: 'processor_vendor', headerName: 'CPU Model', width: 300, valueGetter: (params) => params.row?.processors[0]?.product },
      { field: 'memory', headerName: 'Ram in GB', width: 150, valueGetter: (params) => (((params.row?.memory[0]?.size / 1024) / 1024) / 1024) },
      { field: 'memory_type', headerName: 'Ram Type', width: 300, valueGetter: (params) => params.row?.memory[0]?.description },
      { field: 'batteries', headerName: 'Battery Health', width: 150, valueGetter: (params) => params.row?.batteries[0]?.detail?.capacity },
    ];

    const refurbishmentManagerColumns: GridColDef[] = [
      { field: 'id', headerName: 'ID', width: 150 },
      { field: 'device_vendor', headerName: 'Vendor', width: 150 },
      { field: 'device_name', headerName: 'Name', width: 150 },
      { field: 'device_category', headerName: 'Category', width: 150 },
      { field: 'device_serial', headerName: 'Device Serial', width: 150 },
      { field: 'device_storage', headerName: 'Storage in GB', width: 150, valueGetter: (params) => params.row?.device_storage[0]?.size },
      { field: 'processor_vendor', headerName: 'CPU', width: 300, valueGetter: (params) => params.row?.processors[0]?.product },
      { field: 'memory', headerName: 'Ram in GB', width: 150, valueGetter: (params) => (((params.row?.memory[0]?.size / 1024) / 1024) / 1024) },
      { field: 'memory_type', headerName: 'Ram Type', width: 300, valueGetter: (params) => params.row?.memory[0]?.description },
      { field: 'batteries', headerName: 'Battery Health', width: 150, valueGetter: (params) => params.row?.batteries[0]?.detail?.capacity },
      { field: 'sold_price', headerName: 'Sold For', width: 150 },
      { field: 'sold_date', headerName: 'Sold On', width: 150 },
    ];

    watch(errors)

    return (
      <>
        <Box component="main" sx={{ flexGrow: 1, p:{xs: 1, md: 2}, ml: {sm: 25, xs: 0}, width: { md: `calc(100% - ${drawerWidth}px)`, sm: `calc(100% - ${drawerWidth}px)`, xs: '100%' }}}>
          <form onSubmit={handleSubmit(onSubmit)} id='jobForm'>
              {isLoading1 && (
                <Card sx={{my: 2}}>
                  <LinearProgress color="success" />
                </Card>)}
              {!isLoading1 && (
                  <Card sx={{my: 2}}>
                    <CardHeader sx={{textAlign: 'center'}} title="Job Info"/>
                    <CardContent>
                      <Controller name={`job_id`} control={control} rules={{ required: false }} render={({ field }) =>
                        <TextField {...field} defaultValue={job.job_id} InputProps={{ readOnly: true }} label='Job ID' variant="standard" />
                      }/>
                      <InputLabel id="facility_id">Facility</InputLabel>
                      <Controller name="facility_id" control={control} rules={{ required: true }} render={({ field }) =>
                        <NativeSelect {...field} error={errors?.facility_id} helperText="Please Select Facility" disabled={readOnly} onChange={handleFacilityChange} labelId="facility_id" label="Facility" defaultValue={field} variant="standard" >
                          <option value={null}>Select Facility</option>
                          {facilities.map((fac, i) => {
                            return(
                              <option value={fac.facility_id}>{fac.facility_name}</option>
                            )
                          })}
                        </NativeSelect>
                      }/>
                      {errors?.facility_id && (
                        <FormHelperText error>Required Field</FormHelperText>
                      )}
                      <InputLabel id="client_id">Client</InputLabel>
                      <Controller name="client_id" control={control} rules={{ required: true }} render={({ field }) =>
                        <NativeSelect {...field} error={errors?.client_id} disabled={readOnly} onChange={handleClientChange} labelId="client_id" label="Client" defaultValue={field} variant="standard" >
                          <option>Select Company</option>
                          {clients.map((clnt, i) => {
                            return(
                              <option value={clnt.internal_id}>{clnt.client_name}</option>
                            )
                          })}
                        </NativeSelect>
                      }/>
                      {errors?.client_id && (
                        <FormHelperText error>Required Field</FormHelperText>
                      )}
                      <InputLabel id="location_id">Job Location</InputLabel>
                      <Controller name="location_id" control={control} rules={{ required: true }} render={({ field }) =>
                        <NativeSelect {...field} error={errors?.location_id} labelId="location_id" onChange={handleLocationChange} label="Job Location" disabled={readOnly} defaultValue={field} variant="standard" >
                          <option>Select Company Location</option>
                          {locations.map((loc, i) => {
                            return(
                              <option value={loc.location_id}>{loc.location_name} - {loc.address} {loc.address2} {loc.city} {loc.state} {loc.zip}</option>
                            )
                          })}
                        </NativeSelect>
                      }/>
                      {errors?.location_id && (
                        <FormHelperText error>Required Field</FormHelperText>
                      )}
                      {location.public_notes && (
                        <>
                          <h3>Location Notes</h3>
                          <p><b>{location.public_notes}</b></p>
                        </>
                      )}
                      {auth.user?.type === 'employee' && (
                        location.private_notes && (
                          <>
                            <h3>Private Location Notes</h3>
                            <p><b>{location.public_notes}</b></p>
                          </>
                        )
                      )}
                      {location.primary_poc_name && (
                        <>
                          <h3>Primary POC</h3>
                          <p>Type: <b>{location.primary_poc_type}</b>  Name: <b>{location.primary_poc_name}</b> Number: <b>{location.primary_poc_number}</b> Email: <b>{location.primary_poc_email}</b></p>
                          <p>Notes: <b>{location.primary_poc_notes}</b></p>
                        </>
                      )}
                      {location.secondary_poc_name && (
                        <>
                          <h3>Secondary POC</h3>
                          <p>Type: <b>{location.secondary_poc_type}</b>  Name: <b>{location.secondary_poc_name}</b> Number: <b>{location.secondary_poc_number}</b> Email: <b>{location.secondary_poc_email}</b></p>
                          <p>Notes: <b>{location.secondary_poc_notes}</b></p>
                        </>
                      )}
                      {location.backup_poc_name && (
                        <>
                          <h3>Backup POC</h3>
                          <p>Type: <b>{location.backup_poc_type}</b>  Name: <b>{location.backup_poc_name}</b> Number: <b>{location.backup_poc_number}</b> Email: <b>{location.backup_poc_email}</b></p>\
                          <p>Notes: <b>{location.backup_poc_notes}</b></p>
                        </>
                      )}
                      <InputLabel id="service_level">Service Level</InputLabel>
                      <Controller name="service_level" control={control} rules={{ required: true }} render={({ field }) =>
                        <NativeSelect {...field} error={errors?.service_level} defaultValue={field} disabled={readOnly} onChange={handleServicelevelChange} label="Service Level" variant="standard" >
                          <option>Select Service Level</option>
                          {servLvl.map((serv, i) => {
                            return(
                              <option value={serv.value}>{serv.label}</option>
                            )
                          })}
                        </NativeSelect>
                      }/>
                      {errors?.service_level && (
                        <FormHelperText error>Required Field</FormHelperText>
                      )}
                      {(serviceLevel === 'partner' || serviceLevel === 'whitelabel') ?
                        <>
                          <InputLabel id="on_behalf_of">On Behalf of</InputLabel>
                          <Controller name="on_behalf_of" control={control} rules={{ required: true }} render={({ field }) =>
                            <NativeSelect {...field} error={errors?.on_behalf_of} defaultValue={field} disabled={readOnly} onChange={handlePartnerChange} label="On Behalf Of" variant="standard" >
                                <option>Select On Behalf Of</option>
                              {partners.map((partner, i) => {
                                return(
                                  <option value={partner.internal_id}>{partner.client_name}</option>
                                )
                              })}
                            </NativeSelect>
                          }/>
                          {errors?.on_behalf_of && (
                            <FormHelperText error>Required Field</FormHelperText>
                          )}
                        </>
                      :
                      null}
                      <Controller name="date" control={control} render={({ field }) =>
                        <DateTimePicker textReadOnly='true' onChange={(value) => { field.onChange(value); setValue("date", value);}} readOnly={readOnly} value={dayjs(field.value)} label="Job Start" views={['year', 'month', 'day', 'hours', 'minutes', 'seconds']} />
                        // <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Date of Job" variant="standard" />
                      }/>
                      <Controller name="end_date" control={control} render={({ field }) =>
                        <DateTimePicker textReadOnly='true' onChange={(value) => { field.onChange(value); setValue("end_date", value);}} readOnly={readOnly} value={dayjs(field.value)} label="Job End" views={['year', 'month', 'day', 'hours', 'minutes', 'seconds']} />
                        // <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Start Time of Job" variant="standard" />
                      }/>
                      <InputLabel id="type">Job Type</InputLabel>
                      <Controller name="type" control={control} rules={{ required: true }} render={({ field }) =>
                        <NativeSelect {...field} error={errors?.type} defaultValue={field} disabled={readOnly} label="Job Type" variant="standard" >
                            <option>Select Job Type</option>
                          {jobbieType.map((jobbie, i) => {
                            return(
                              <option value={jobbie.value}>{jobbie.label}</option>
                            )
                          })}
                        </NativeSelect>
                      }/>
                      {errors?.type && (
                        <FormHelperText error>Required Field</FormHelperText>
                      )}
                      <Controller name="estimate" control={control} render={({ field }) =>
                        <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Estimate Number" variant="standard" />
                      }/>
                      <Controller name="invoice" control={control} render={({ field }) =>
                        <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Invoice" variant="standard" />
                      }/>
                      <Controller name="purchase_order" control={control} render={({ field }) =>
                        <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Purchase Order" variant="standard" />
                      }/>
                      <Controller name="reference_id" control={control} render={({ field }) =>
                        <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Customer Reference ID" variant="standard" />
                      }/>
                      <Controller name="public_notes" control={control} render={({ field }) =>
                        <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Public Notes" variant="standard" fullWidth/>
                      }/>
                      {auth.user?.type === 'employee' && (
                        <Controller name="private_notes" control={control} render={({ field }) =>
                          <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Internal Notes" variant="standard" fullWidth/>
                        }/>
                      )}
                      </CardContent>
                      <CardContent>
                        <Controller name="status.scheduled" control={control} render={({ field: { onChange, value } }) => (
                          <FormControlLabel control={<Switch checked={value} disabled={readOnly} onChange={onChange} />} label="Scheduled" />
                        )}/>
                        <Controller name="status.picked_up" control={control} render={({ field: { onChange, value } }) => (
                          <FormControlLabel control={<Switch checked={value} disabled={readOnly} onChange={onChange} />} label="Picked Up" />
                        )}/>
                        <Controller name="status.weighed" control={control} render={({ field: { onChange, value } }) => (
                          <FormControlLabel control={<Switch checked={value} disabled={readOnly} onChange={onChange} />} label="Weighed" />
                        )}/>
                        <Controller name="status.inventoried" control={control} render={({ field: { onChange, value } }) => (
                          <FormControlLabel control={<Switch checked={value} disabled={readOnly} onChange={onChange} />} label="Inventoried" />
                        )}/>
                        <Controller name="status.data_destroyed" control={control} render={({ field: { onChange, value } }) => (
                          <FormControlLabel control={<Switch checked={value} disabled={readOnly} onChange={onChange} />} label="Data Destroyed" />
                        )}/>
                        <Controller name="status.refurbished" control={control} render={({ field: { onChange, value } }) => (
                          <FormControlLabel control={<Switch checked={value} disabled={readOnly} onChange={onChange} />} label="Refurbished" />
                        )}/>
                        <Controller name="status.revenue_window" control={control} render={({ field: { onChange, value } }) => (
                          <FormControlLabel control={<Switch checked={value} disabled={readOnly} onChange={onChange} />} label="In Revenue Window" />
                        )}/>
                        <Controller name="status.quarantined_items" control={control} render={({ field: { onChange, value } }) => (
                          <FormControlLabel control={<Switch checked={value} disabled={readOnly} onChange={onChange} />} label="Quarantined Items" />
                        )}/>
                    </CardContent>
                  </Card>)}
              {readOnly && (<>
                {isLoading2 && (
                  <Card sx={{my: 2}}>
                    <LinearProgress color="success" />
                  </Card>
                )}
                {!isLoading2 && (weights.length > 0 && (
                    <Card sx={{my: 2}}>
                      <CardHeader sx={{textAlign: 'center'}} title='Weights' />
                      <CardContent>
                            <p>Metal: {metal_wht} lbs</p>
                            <p>Mixed Electronics: {consumer_wht} lbs</p>
                            <p>Computing Devices: {computing_cnt} items @ {computing_wht} lbs</p>
                            <p>Loose HDD: {printer_cnt} items @ {printer_wht} lbs</p>
                            <p>Batteries: {battery_cnt} items @ {battery_wht} lbs</p>
                            <p>Bulbs: {bulb_cnt} items @ {bulb_wht} lbs</p>
                            <p>32" Under CRT: {crt_32_cnt} items</p>
                            <p>33" Over CRT: {crt_33_cnt} items</p>
                            <p>CRT Monitor: {crt_monitor_cnt} items</p>
                            <p>CRT Weight: {crt_wht} lbs</p>
                            <p>Broken CRT: {crt_broken_cnt} items @ {crt_broken_wht} lbs</p>
                            <p>LCD Monitor: {lcd_monitor_cnt} items @ {lcd_monitor_wht} lbs</p>
                            <p>LCD TV: {lcd_tv_cnt} items @ {lcd_tv_wht} lbs</p>
                            <p>Other TV: {other_tv_cnt} items @ {other_tv_wht}</p>
                            <p>Detectors: {detector_cnt} items @ {detector_wht} lbs</p>
                            <p>Lab Equipment: {lab_cnt} items @ {lab_wht} lbs</p>
                            <p>Trash: {trash_wht} lbs</p>
                            <p></p>
                            <p>Total: {weightCount} weights @ {total_wht} lbs ({total_wht/weightCount} lbs avg)</p>
                      </CardContent>
                      <CardContent>
                        <CorChart/>
                      </CardContent>
                      <CardContent>
                        <DataGrid rows={weights} columns={weightColumns} slots={{ toolbar: GridToolbar }} />
                      </CardContent>
                    </Card>
                  )
                )}
                {isLoading3 && (
                  <Card sx={{my: 2}}>
                    <LinearProgress color="success" />
                  </Card>
                )}
                {!isLoading3 && (inventory.length > 0 && (
                    <Card sx={{my: 2}}>
                      <CardHeader sx={{textAlign: 'center'}} title="Inventory" />
                      <CardContent>
                          <DataGrid rows={inventory} columns={inventoryColumns} slots={{ toolbar: GridToolbar }} />
                      </CardContent>
                    </Card>
                ))}
                {isLoading4 && (
                  <Card sx={{my: 2}}>
                    <LinearProgress color="success" />
                  </Card>
                )}
                {!isLoading4 && (dataDestruction.length > 0 && (
                    <Card sx={{my: 2}}>
                      <CardHeader sx={{textAlign: 'center'}} title="Data Destruction" />
                      <CardContent>
                          <DataGrid rows={dataDestruction} columns={dataDestructionColumns} slots={{ toolbar: GridToolbar,}} />
                      </CardContent>
                    </Card>
                ))}
                {isLoading5 && (
                  <Card sx={{my: 2}}>
                  <LinearProgress color="success" />
                  </Card>
                )}
                {!isLoading5 && (
                  refurbishment.length > 0 && (
                      <Card sx={{my: 2}}>
                        <CardHeader sx={{textAlign: 'center'}} title="Refurbishment" />
                        <CardContent>
                            <DataGrid rows={refurbishment} columns={refurbishmentColumns} slots={{ toolbar: GridToolbar,}} />
                        </CardContent>
                      </Card>
                ))}
              </>)}
          </form>
        </Box>
    {auth.user?.type === 'employee' &&(
      <>
        <Box sx={{ position: 'fixed', bottom: { xs: '3dvh', sm: '3dvh', md: '3dvh' }, right: { xs: '5dvw',sm: '3dvw', md: '3dvw' } }}>
              <Tooltip title="Actions">
                  <IconButton
                  onClick={handleClick}
                  size="small"
                  aria-controls={open ? 'account-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  >
                  <Avatar sx={{ width: 35, height: 35 }}>A</Avatar>
                  </IconButton>
              </Tooltip>
        </Box>
        <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              PaperProps={{
                  elevation: 0,
                  sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                  },
                  '&::before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  bottom: 0,
                  right: '50%',
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(50%) rotate(45deg)',
                  zIndex: 0,
                  },
              },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        >
              <MenuItem>
                  {readOnly && (<Button onClick={() => editClicked()}>Edit</Button>)}{!readOnly && (<><Button onClick={() => cancelClicked()}>Cancel</Button><Button type="submit" form='jobForm'>Save</Button></>)}
              </MenuItem>
              {readOnly && (
                <>
                  <MenuItem>
                    <Button component={Link} to={'/weight/' + jobId + '/add'}>Add Weight</Button>
                  </MenuItem>
                  <MenuItem>
                    <Button component={Link} to={'/inventory/item/add/' + jobId}>Add Inventory</Button>
                  </MenuItem>
                  <MenuItem>
                    <Button component={Link} to={'/refurbishment/item/add/' + jobId}>Add Refurbishment</Button>
                  </MenuItem>
                  <MenuItem>
                    <PDFDownloadLink document={<MyDocument />} fileName={`${fileName}.pdf`}>{({ blob, url, loading, error }) => loading ? 'Loading document...' : 'Download now!'}</PDFDownloadLink>
                  </MenuItem>
                </>
              )}
              {/* <MenuItem>
                  <PrinterList labelType='job' serial='' job={job.job_id} />
              </MenuItem> */}
        </Menu>
      </>
    )}
    </>
    )
}

export default Job;