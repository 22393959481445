import { useReducer, useState, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../../auth/AuthWrapper";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';

const Login = () => {
      const [input, setInput] = useState({
        username: "",
        password: "",
      });
     const navigate = useNavigate();
     const auth = useAuth();
     const [ formData, setFormData ] = useReducer((formData, newItem) => { return ( {...formData, ...newItem} )}, {username: "", password: ""})
     const [ errorMessage, setErrorMessage ] = useState(null)
     const location = useLocation().state;
     const handleSubmitEvent = (e) => {
       e.preventDefault();
       if (formData.username !== "" && formData.password !== "") {
         auth.loginAction(formData);
         return;
       }
       alert("pleae provide a valid input");
     };
     
     const drawerWidth = 0;

     return (
          <Box
          component="main"
          sx={{ flexGrow: 1, p: 3, width: { md: `calc(100% - ${drawerWidth}px)`, sm: `calc(100% - ${drawerWidth}px)`, xs: '100%' }}}
          ><br/><br/><br/><br/><br/>
          <center>
          <Card sx={{ maxWidth: 375 }}>
               <CardContent>
               <img src={`/${auth.subDomain}/img/bigLoginLogo.png`} style={{ maxWidth: "100%" }} />
               <h2>Login</h2>
                    <div className="inputs">
                         <div className="input">
                              <input value={formData.username} onChange={(e) => setFormData({username: e.target.value}) } type="text"/>
                         </div>
                         <div className="input">
                              <input value={formData.password} onChange={(e) => setFormData({password: e.target.value}) } type="password"/>
                         </div>
                         <div className="button">
                              <button onClick={handleSubmitEvent}>Log in</button>
                         </div>
                         {errorMessage ?
                         <div className="error">{errorMessage}</div>
                         : null }
                    </div>
               </CardContent>
          </Card>
          </center>
          </Box>
     )
}
   
   export default Login;