import { Grid, Box, Paper, Button, ButtonGroup, Accordion, AccordionDetails, AccordionSummary, Tooltip } from '@mui/material';
import Item from '@mui/material/Stack';
import { experimentalStyled as styled } from '@mui/material/styles';
import { useAuth } from "../../auth/AuthWrapper";
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import ScaleIcon from '@mui/icons-material/Scale';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import InventoryIcon from '@mui/icons-material/Inventory';
import BuildIcon from '@mui/icons-material/Build';
import GppMaybeIcon from '@mui/icons-material/GppMaybe';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import FolderDeleteIcon from '@mui/icons-material/FolderDelete';
import { LinearProgress } from "@mui/material";

const GridTitleItem = styled(Item)(({ theme }) => ({
     textAlign: 'center',
     color: 'green',
     fontWeight: 'bold',
     fontSize: '18px',
   }));

const GridItem = styled(Paper)(({ theme }) => ({
     backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
     ...theme.typography.body2,
     padding: theme.spacing(2),
     textAlign: 'left',
     color: theme.palette.text.secondary,
   }));

function openInNewTab(url) {
  window.open(url, "_blank", "noreferrer");
};   

const Jobs = ({ socket, isConnected}) => {
    const drawerWidth = 200;
    const auth = useAuth();
    const [isLoading, setIsLoading] = useState(true);
    const [jobs, setJobs] = useState([]);

    useEffect(() => {
      if(isConnected){
           socket.emit("jobs", {"function":"get_all", "data": {"hostname": auth.domain, "ip": auth.ip, "user": auth.user}}, (response) => {
            setJobs(response);
            setIsLoading(false);
           })
      }
    }, [isConnected]);

    const [expanded, setExpanded] = useState(false);
    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };
    
    return (
     <>
     <Box component="main" sx={{ 
          flexGrow: 1, mt: {xs: 1, sm: 2}, p:{xs: 1, md:2}, ml: {sm: 25, xs: 0}, width: { md: `calc(100% - ${drawerWidth}px)`, sm: `calc(100% - ${drawerWidth}px)`, xs: '100%' 
     }}}>
            {auth.user?.type === 'employee' && <center>
                 <ButtonGroup variant="contained" color="success" size="small" aria-label="outlined primary button group">
                    <Button component={Link} to={'/job/add'}>Add New Job</Button>
                 </ButtonGroup>
            </center>}
       </Box>
     <Box component="main" sx={{ 
          flexGrow: 1, p:{xs: 1, md: 2}, ml: {sm: 25, xs: 0}, width: { md: `calc(100% - ${drawerWidth}px)`, sm: `calc(100% - ${drawerWidth}px)`, xs: '100%' 
     }}}>
      {isLoading && (
        <LinearProgress color="success" />
      )}
      {!isLoading && (
     jobs.map((item, index) => (
       <Accordion expanded={expanded === 'panel-' + index} onChange={handleChange('panel-' + index)}>
       <AccordionSummary
         expandIcon={<ExpandMoreIcon />}
         aria-controls={"panel-" + index + "-content"}
         id={"panel-" + index + "-header"}
       >
        <Grid
       container
       spacing={{ sm: 1, md: 2 }}
       columns={{ xs: 1, sm: 1, md: 3, lg: 3, xl: 3 }}>
       <Grid item xs={1} sm={1} md={2} key={item.id + 1}>
         <Typography sx={{ flexShrink: 0 }}>
           <b>{item.job_id} | {item.name} | {item.date} @ {item.time}</b>
           <br />
           {item.type}
         </Typography>
        </Grid>
          <Grid item xs={1} sm={1} md={1} key={item.id + 2}>
         <Typography sx={{ color: 'text.secondary' }}>
          {item.status?.picked_up ?
          <Tooltip title="Picked Up">
          <LocalShippingIcon color='success' />
          </Tooltip>
          :
          <Tooltip title="Not Picked Up">
          <LocalShippingIcon color='disabled' />
          </Tooltip>
          }
          {item.status.weighed && item.status.picked_up ?
          <Tooltip title="Weighed">
          <ScaleIcon color='success' />
          </Tooltip>
          :
          <Tooltip title="Not Weighed">
          <ScaleIcon color='disabled' />
          </Tooltip>
          }
          {item.status.picked_up && item.status.inventoried && item.status.weighed ?
          <Tooltip title="Inventoried">
          <InventoryIcon color='success' />
          </Tooltip>
          :
          <Tooltip title="Not Inventoried">
          <InventoryIcon color='disabled' />
          </Tooltip>
          }
          {item.status.picked_up && item.status.data_destroyed && item.status.inventoried ?
          <Tooltip title="Data Destroyed">
          <FolderDeleteIcon color='success' />
          </Tooltip>
          :
          <Tooltip title="Data Not Destroyed">
          <FolderDeleteIcon color='disabled' />
          </Tooltip>
          }
          {item.status.picked_up && item.status.inventoried && item.status.refurbished && item.status.data_destroyed ?
          <Tooltip title="Refurbished">
          <BuildIcon color='success' />
          </Tooltip>
          :
          <Tooltip title="Not Refurbished">
          <BuildIcon color='disabled' />
          </Tooltip>
          }
          {item.status.revenue_window && item.status.picked_up ?
          <Tooltip title="In Revenue Share Window">
          <PointOfSaleIcon color='success' />
          </Tooltip>
          :
          <></>
          }
          {item.status.revenue_window && !item.status.picked_up ?
          <Tooltip title="Pending...">
          <PointOfSaleIcon color='disabled' />
          </Tooltip>
          :
          <></>
          }
          {!item.status.revenue_window && item.status.picked_up ?
          <Tooltip title="Not In Revenue Share Window">
          <PointOfSaleIcon color='error' />
          </Tooltip>
          :
          <></>
          }
          {!item.status.revenue_window && !item.status.picked_up ?
          <Tooltip title="Pending...">
          <PointOfSaleIcon color='disabled' />
          </Tooltip>
          :
          <></>
          }
          {item.status.picked_up && item.status.quarantined_items ?
          <Tooltip title="Quarantined Items">
          <GppMaybeIcon color='warning' />
          </Tooltip>
          :
          <Tooltip title="No Quarantined Items">
          <GppMaybeIcon color='disabled' />
          </Tooltip>
          }
        </Typography>
        </Grid>
        </Grid>
       </AccordionSummary>
       <AccordionDetails>
        <ButtonGroup variant="contained" color="success" size="small" aria-label="outlined primary button group">
          <Button component={Link} to={'/job/' + item.job_id}>View</Button>
          {/* { item.status.picked_up && item.status.weighed ?
          <Button>Download COR</Button>
          :
          <></>
          }
          { item.status.inventoried && item.status.data_destroyed ?
          <Button>Download COD</Button>
          :
          <></>
          }
          { item.status.inventoried && item.status.data_destroyed && item.status.refurbished ?
          <Button>Download Excel</Button>
          :
          <></>
          } */}
        </ButtonGroup>
          <br/><br/>
         <Typography>
           More Job Info To Go Here...
         </Typography>
       </AccordionDetails>
     </Accordion>
     ))
     )}
     </Box>
  </>
    )
}

export default Jobs