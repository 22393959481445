import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import AuthProvider from './auth/AuthWrapper';
import { socket } from './components/socket';
import { ConnectionState } from './components/ConnectionState';
import Login from './components/pages/Login';
import Account from './components/pages/Account';
import Dashboard from './components/pages/Dashboard';
import Facilities from './components/pages/Facilities';
import Facility from './components/pages/Facility';
import Clients from './components/pages/Clients';
import Client from './components/pages/Client';
import Location from './components/pages/Location';
import Jobs from './components/pages/Jobs';
import Job from './components/pages/Job';
import Weight from './components/pages/Weight';
import Inventory from './components/pages/Inventory';
import InvRefForm from './components/InvRefForm';
// import { Home2 } from './components/pages/Home2';
import { PrivateRoute, EmployeeRoute, ClientRoute } from "./router/routes";
import { useAuth } from "./auth/AuthWrapper";
import { MainLayout } from "./components/theme/Layout";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import './App.css';

function App() {
  const [isConnected, setIsConnected] = useState(false);
  const [fooEvents, setFooEvents] = useState([]);
  const [systemEvents, setSystemEvents] = useState([]);
  const [portalErrors, setPortalErrors] = useState([]);
  const auth = useAuth();

  useEffect(() => {
    if(localStorage.getItem("SAMS")){
      socket.connect();
      setIsConnected(true)
    }
  }, [])

  useEffect(() => {
    function onConnect() {
      setIsConnected(true);
      console.log('connected')
      socket.emit("system", {"status": "connected"})
    }

    function onDisconnect() {
      console.log('disconnected')
      setIsConnected(false);
    }

    function onFooEvent(value) {
      setFooEvents(previous => [...previous, value]);
      socket.emit("message", "response from portal")
    }

    function onSystem(value) {
      setSystemEvents(previous => [...previous, value]);
      socket.emit("system", {"status":"response from portal system"})
    }

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    socket.on('message', onFooEvent);
    socket.on('system', onSystem);

    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
      socket.off('message', onFooEvent);
      socket.off('system', onSystem);
    };
  }, []);

  return (
    <div className="App">
      <Router>
        <AuthProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <ConnectionState isConnected={ isConnected } />
            <MainLayout />
            <Routes>
              <Route element={<PrivateRoute />}>
                <Route path="/account" element={<Account socket={socket} isConnected={ isConnected } />} />
                <Route path="/dashboard" element={<Dashboard socket={socket} isConnected={ isConnected } />} />
                <Route path="/facilities" element={<Facilities socket={socket} isConnected={ isConnected } />} />
                <Route path="/facility/:facility_id" element={<Facility socket={socket} isConnected={ isConnected } />} />
                <Route path="/clients" element={<Clients socket={socket} isConnected={ isConnected } />} />
                <Route path="/client/:clientId" element={<Client socket={socket} isConnected={ isConnected } />} />
                {/* <Route path="/locations" element={<Locations socket={socket} isConnected={ isConnected } />} /> */}
                <Route path="/location/:client_id/:location_id" element={<Location socket={socket} isConnected={ isConnected } />} />
                <Route path="/jobs" element={<Jobs socket={socket} isConnected={ isConnected } />} />
                <Route path="/job/:jobId" element={<Job socket={socket} isConnected={ isConnected } />} />
                {/* <Route path="/weights" element={<Weights socket={socket} isConnected={ isConnected } />} /> */}
                <Route path="/weight/:job_id/:weight_id" element={<Weight socket={socket} isConnected={ isConnected } />} />
                <Route path="/inventory" element={<Inventory socket={socket} isConnected={ isConnected } />} />
                <Route path="/inventory/item/:serial" element={<InvRefForm type="inventory" socket={socket} isConnected={ isConnected } />} />
                <Route path="/inventory/item/:serial/:job_id" element={<InvRefForm type="inventory" socket={socket} isConnected={ isConnected } />} />
                <Route path="/refurbishment/item/:serial" element={<InvRefForm type="refurbishment" socket={socket} isConnected={ isConnected } />} />
                <Route path="/refurbishment/item/:serial/:job_id" element={<InvRefForm type="refurbishment" socket={socket} isConnected={ isConnected } />} />
              </Route>
              {/* <Route element={<ClientRoute />}>
                <Route path="/account" element={<Account />} />
                <Route path="/test" element={<Dashboard />} />
                <Route path="/test2" element={<Dashboard />} />
              </Route> */}
              <Route path="/" element={<Login />} />
              <Route path="/login" element={<Login />} />
              {/* Other routes */}
            </Routes>
          </LocalizationProvider>
        </AuthProvider>
      </Router>
    </div>
  );
}

export default App;
