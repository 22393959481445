import React, { useState, useEffect } from 'react';
import { useAuth } from "../../auth/AuthWrapper";
import { Box, Button, ButtonGroup, TextField} from '@mui/material';
import { useForm, useFormState, Controller, useFieldArray } from "react-hook-form";
import { useParams, useNavigate } from "react-router-dom";

const Account = ({socket, isConnected}) => {
     const drawerWidth = 200;
     const auth = useAuth();
     const navigate = useNavigate();
     const [ resetResponse, setResetResponse ] = useState('')
     const { register, setValue, getValues, handleSubmit, control, reset, resetField, watch, formState: { errors } } = useForm({});
     const [calendarUrl, setCalendarUrl] = useState(null)

     // useEffect(() => {
     //      if(isConnected){
     //        socket.emit("calendars", {"function":"get_token", "data": {"hostname": auth.domain, "ip": auth.ip, "user": auth.user}}, (response) => {
     //          if(response != 'error'){
     //            setCalendarUrl(response);
     //          }else{
     //           setCalendarUrl('error');
     //          }
     //        })
     //      }
     //    }, [isConnected]);

     const  handleCalendarUrlCopyClick = (e) => {
          e.preventDefault();
          if(isConnected){
               socket.emit("calendars", {"function":"get_token", "data": {"hostname": auth.domain, "ip": auth.ip, "user": auth.user}}, (response) => {
                    if(response != 'error'){
                         setCalendarUrl(response);
                    }else{
                         setCalendarUrl('error');
                    }
               })
          }else{
               navigator.clipboard.writeText('error')
          }
     }

     const  handleCalendarUrlGenerateClick = (e) => {
          e.preventDefault();
          navigator.clipboard.writeText(calendarUrl)
     }

     useEffect(() => {
     }, [calendarUrl])

     let pwd = watch("newpass");
     const onSubmit = async (data, e) => {
          e.preventDefault();
          if(isConnected){
              socket.emit('account', {"function": "change_password", "data": {"hostname": auth.domain, "ip": auth.ip, "id": '', "job": '', "payload": data, "user": auth.user}}, (response) => {
                  if(response != 'error'){
                      navigate("/dashboard");
                  }
              })
          }
          // const requestOptions = {
          //      method: 'POST',
          //      headers: { 'Content-Type': 'application/json', 'authorization': 'bearer ' + token },
          //      body: JSON.stringify(data)
          //  };
          // await fetch('/api/user/resetpass', requestOptions).then(async response => {
          //      console.log('sending...')
          //      if(response.ok === false){
          //           const error = 'Error!';
          //           console.log(error);
          //           throw new Error(error);
          //      }
          //      const resp = await response.json();
          //      console.log(resp)
          //      setResetResponse(resp)
          // }).catch(error => {
          //      console.log(error)
          // });
     }

     return (
          <Box
               component="main"
               sx={{ flexGrow: 1, p: 3, ml: {xl: 25, lg: 25, md: 25, sm: 25, xs: 0}, width: { md: `calc(100% - ${drawerWidth}px)`, sm: `calc(100% - ${drawerWidth}px)`, xs: '100%' }}}
               >
               <div>
               <h2>Your Account</h2>
               <p>Username: {auth.user?.name}</p>
               <p>Email: {auth.user?.email}</p>
               {auth.user?.type === 'employee' && 
                    (!calendarUrl ? 
                         <Button onClick={handleCalendarUrlCopyClick}>Get Calendar URL</Button>
                    :
                         <Button onClick={handleCalendarUrlGenerateClick}>Copy Calendar Url To Clipboard</Button>
                    
                    )
               }
               </div>
               <div>
                    <form onSubmit={handleSubmit(onSubmit)} id='resetPasswordForm'>
                         <h2>Set New Password</h2>
                         <Controller name="prevpass" control={control} rules={{ required: "You must specify a password" }} render={({ field }) => <TextField {...field} type="password" label="Current Password" variant="standard" />} />
                         {errors.prevpass && <p> {errors.prevpass.message}</p>}
                         <Controller name="newpass" control={control} rules={{ required: "You must specify a password" }} render={({ field }) => <TextField {...field} type="password" label="New Password" variant="standard" />} />
                         {errors.newpass && <p> {errors.newpass.message}</p>}
                     <Controller name="newpass2" control={control} rules={{ required: "You must specify a password", validate: value => value === pwd || "The passwords do not match" }} render={({ field }) => <TextField {...field} type="password" label="New Password" variant="standard" />} />
                         {errors.newpass2 && <p> {errors.newpass2.message}</p>}
                         <Button type="submit">Submit</Button>
                    </form>
               </div>
          </Box>
     )
}

export default Account;