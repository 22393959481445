import React from 'react';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';


export function ConnectionState({ isConnected }) {
  const [open, setOpen] = React.useState(true);
  const location = window.location.pathname;
  if(isConnected === true){
    return null
  }else if(isConnected === false){
    if(location != '/login'){
      return <Box style={{position:'absolute', width: '100dvw', zIndex: '9999999', marginTop: '75px'}}>
            <Collapse in={open} style={{padding: '10px'}}>
              <Alert action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                  setOpen(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              } severity="error">Not Connected!</Alert>
            </Collapse>
          </Box>
    }else{
      return null
    }
  }
}