// import { PrinterList } from "../PrinterList";
import { Avatar, Box, TextField, InputLabel,Menu, MenuItem, IconButton, Tooltip, FormControl, FormControlLabel, Select, Switch, NativeSelect, Button, ButtonGroup, Stack, Paper, Card, CardContent, CardHeader, CardActions, Grid } from '@mui/material';
import { useAuth } from "../../auth/AuthWrapper";
import Item from '@mui/material/Stack';
import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate, Link } from "react-router-dom";
import { useForm, useFormState, Controller, useFieldArray } from "react-hook-form";

// I believe I have all the const's needed, but I could be missing something else to create a functioning page.
const Client = ({ socket, isConnected }) => {
    const drawerWidth = 200;
    const auth = useAuth();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [newForm, setNewForm] = useState(false);
    const [client, setClient] = useState({"client_name":"", "client_type":"", "address":"", "address2":"", "city":"", "state":"", "zip":"", "primary_poc_name":"", "primary_poc_number":"", "primary_poc_email":"", "primary_poc_notes":"", "primary_poc_type":"",
                                        "secondary_poc_name":"", "secondary_poc_number":"", "secondary_poc_email":"", "secondary_poc_notes":"", "secondary_poc_type":"", "backup_poc_name":"", "backup_poc_number":"", "backup_poc_email":"", "backup_poc_notes":"",
                                        "backup_poc_type":"", "public_notes":"", "private_notes":""});
    const [readOnly, setReadOnly] = useState(true);
    const [locations, setLocations] = useState([]);
    const { register, setValue, getValues, handleSubmit, control, reset, resetField, watch, formState: { errors } } = useForm({ defaultValues: client });
    const { dirtyFields } = useFormState({ control });
    const onError = (errors, e) => console.log(errors, e);
    let { clientId } = useParams();

    const [ anchorEl, setAnchorEl ] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
         setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
         setAnchorEl(null);
    };

    useEffect(() => {
        if(isConnected){
            if(clientId === 'add'){
                // console.log('Add!')
                setReadOnly(false);
                setNewForm(false);
            }else{
                socket.emit("clients", {"function": "get_one", "data": {"hostname": auth.domain, "ip": auth.ip, "id": clientId}}, (response) => {
                    //  console.log(response)
                     setClient(response)
                })
                socket.emit("locations", {"function": "get_all", "data": {"hostname": auth.domain, "ip": auth.ip, "id": clientId}}, (response) => {
                    //  console.log(response)
                     setLocations(response)
                })
                setNewForm(true);
            }
        }
   }, [isConnected]);

    useEffect(() => {
        for (const [key, value] of Object.entries(client)) {
            setValue(key, value)
        }
        // setValue("client_id", clientId)
    }, [client])

    // For the button actions down below.
    const editClicked = () => {
      setReadOnly(false);
    };
  
    // const saveClicked = () => {
    //   setReadOnly(true);
    // };
  
    const cancelClicked = () => {
      setReadOnly(true);
      reset(client);
      if(clientId === 'add'){
        navigate("/clients");
      }
    };

    // This is for submitting the form after its been filled out.
    const onSubmit = async (data, e) => {
        e.preventDefault();
        if(isConnected){
            socket.emit('clients', {"function": "save", "data": {"hostname": auth.domain, "ip": auth.ip, "id": clientId, "payload": data, "user": auth.user}}, (response) => {
                if(response != 'error'){
                    setClient(response)
                    setReadOnly(true);
                    // console.log(response)
                    navigate("/client/" + response.internal_id);
                }
            })
        }
        // const requestOptions = {
        //      method: 'POST',
        //      headers: { 'Content-Type': 'application/json', 'authorization': 'bearer ' + token },
        //      body: JSON.stringify(data)
        //  };
        // await fetch('/api/sams/add_client', requestOptions).then(async response => {
        //      console.log('sending...')
        //      console.log(response)
        //      if(response.ok === false){
        //           const error = response.statusText;
        //           console.log('error')
        //           console.log(error);
        //           throw Error(error);
        //      }
        //      const resp = await response.json();
        //      console.log('test')
        //      console.log(resp)
        //      setClient(resp)
        // }).catch(error => {
        //      console.log(error)
        // });
   }
   watch(errors)
    return (
     <>
     <Box component="main" sx={{ 
          flexGrow: 1, mt: {xs: 1, sm: 2}, p:{xs: 1, md:2}, ml: {sm: 25, xs: 0}, width: { md: `calc(100% - ${drawerWidth}px)`, sm: `calc(100% - ${drawerWidth}px)`, xs: '100%' 
     }}}>
       </Box>
       <Box component="main" sx={{ 
            flexGrow: 1, p:{xs: 1, md: 2}, ml: {sm: 25, xs: 0}, width: { md: `calc(100% - ${drawerWidth}px)`, sm: `calc(100% - ${drawerWidth}px)`, xs: '100%' 
       }}}>

        {/* The form to be filled out. */}
        <form onSubmit={handleSubmit(onSubmit)} id='clientForm'>
            <Card sx={{textAlign: 'center'}}>
            <CardHeader title="Client"/>
                <Card>
                    <CardHeader title="Client Name & Address"/>
                    <CardContent>
                        <Controller name="internal_id" control={control} rules={{ required: true }} render={({ field }) => <TextField {...field} error={errors?.internal_id} defaultValue={field} InputProps={{ readOnly: newForm }} label="Internal ID" variant="standard" />} />
                        <Controller name="client_name" control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Name of Client" variant="standard" />} />
                        <Controller name="client_type" control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Type of Client (Partner, Govt, etc.)" variant="standard" />} />
                        <Controller name="address" control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Client Main Street Address" variant="standard" />} />
                        <Controller name="address2" control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Client Secondary Address (Unit, Suite, Bldg, etc.)" variant="standard" />} />
                        <Controller name="city" control={control} rules={{ required: true }} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="City" variant="standard" />} />
                        <Controller name="state" control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="State" variant="standard" />} />
                        <Controller name="zip" control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Zip Code" variant="standard" />} />
                    </CardContent>
                </Card>
                <Card>
                    <CardHeader title="Primary Client POC Information"/>
                    <CardContent>
                        <Controller name="primary_poc_name" control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Primary POC Name" variant="standard" />} />
                        <Controller name="primary_poc_number" control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Primary POC Phone/Cell Number" variant="standard" />} />
                        <Controller name="primary_poc_email" control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Primary POC E-Mail" variant="standard" />} />
                        <Controller name="primary_poc_notes" control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Primary POC Notes" variant="standard" />} />
                        <Controller name="primary_poc_type" control={control} rules={{ required: false }} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Primary POC Type" variant="standard" />} />
                    </CardContent>
                </Card>
                <Card>
                    <CardHeader title="Secondary Client POC Information"/>
                    <CardContent>
                        <Controller name="secondary_poc_name" control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Secondary POC Name" variant="standard" />} />
                        <Controller name="secondary_poc_number" control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Secondary POC Phone/Cell Number" variant="standard" />} />
                        <Controller name="secondary_poc_email" control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Secondary POC E-Mail" variant="standard" />} />
                        <Controller name="secondary_poc_notes" control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Secondary POC Notes" variant="standard" />} />
                        <Controller name="secondary_poc_type" control={control} rules={{ required: false }} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Secondary POC Type" variant="standard" />} />
                    </CardContent>
                </Card>
                <Card>
                    <CardHeader title="Backup Client POC Information"/>
                    <CardContent>
                        <Controller name="backup_poc_name" control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Backup POC Name" variant="standard" />} />
                        <Controller name="backup_poc_number" control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Backup POC Phone/Cell Number" variant="standard" />} />
                        <Controller name="backup_poc_email" control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Backup POC E-Mail" variant="standard" />} />
                        <Controller name="backup_poc_notes" control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Backup POC Notes" variant="standard" />} />
                        <Controller name="backup_poc_type" control={control} rules={{ required: false }} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Backup POC Type" variant="standard" />} />
                    </CardContent>
                </Card>
                <Card>
                    <CardHeader title="Client Notes" />
                    <CardContent>
                        <Controller name="public_notes" control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Public Notes for Client" variant="standard" />} />
                        <Controller name="private_notes" control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Private Notes for Client" variant="standard" />} />
                    </CardContent>
                </Card>
                <Card>
                    <CardHeader title="Locations Quick View" />
                    <CardContent>
                    {locations.map((loc, i) => {
                        return(
                            <p>{loc.location_name} - {loc.address} {loc.address2} {loc.city} {loc.state} {loc.zip} <Button component={Link} to={'/location/' + loc.client_id + '/' + loc.location_id}> View Location </Button> </p>
                        )
                    })}
                    </CardContent>
                </Card>
            </Card>
        </form> 
    </Box>
    {auth.user?.type === 'employee' &&(<>
        <Box sx={{ position: 'fixed', bottom: { xs: '3dvh', sm: '3dvh', md: '3dvh' }, right: { xs: '5dvw',sm: '3dvw', md: '3dvw' } }}>
            <Tooltip title="Actions">
                <IconButton
                onClick={handleClick}
                size="small"
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                >
                <Avatar sx={{ width: 35, height: 35 }}>A</Avatar>
                </IconButton>
            </Tooltip>
        </Box>
        <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            PaperProps={{
                elevation: 0,
                sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
                },
                '&::before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                bottom: 0,
                right: '50%',
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(50%) rotate(45deg)',
                zIndex: 0,
                },
            },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        >
            <MenuItem>
                {readOnly && (<Button onClick={() => editClicked()}>Edit</Button>)}{!readOnly && (<><Button onClick={() => cancelClicked()}>Cancel</Button><Button type="submit" form='clientForm'>Save</Button></>)}
            </MenuItem>
            {readOnly && clientId != 'add' && (
                <MenuItem>
                    <Button component={Link} to={'/location/' + clientId + '/add'}>Add New Location</Button>
                </MenuItem>
            )}
            {/* <MenuItem>
                <PrinterList labelType={props.type} job={client.job_id} />
            </MenuItem> */}
        </Menu>
        </>)}
    </>
    )
}

export default Client;