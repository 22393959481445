import React from "react";
import { createContext, useContext, useState, useEffect } from "react"
const portalIO = require('socket.io-client');

const SocketContext = createContext();

export default SocketContext;

const socketio = portalIO("https://porsock.staticip.it/", {
     autoConnect: true,
     transports: ['websocket'],
     auth: (cb) => {
       cb({ token: localStorage.getItem("SAMS") || "" })
     },
     maxHttpBufferSize: 7e6,
     connectionStateRecovery: {
          maxDisconnectionDuration: 2 * 60 * 1000,
          skipMiddlewares: false
     }
});

if(localStorage.getItem("SAMS")){
     socketio.connect();
}

export const socket = socketio;