import { Box, Button, ButtonGroup, Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { useAuth } from "../../auth/AuthWrapper";
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from "react-router-dom";
import React, { useState, useEffect } from 'react';
   
const Clients = ({ socket, isConnected }) => {
    const drawerWidth = 200;
    const auth = useAuth();
    const [isLoading, setIsLoading] = useState(true);
    const [clients, setClients] = useState([]);

    // useEffect(() => {
    //   const headers = { 'authorization': 'bearer ' + token };
    //   fetch('/api/sams/get_clients', { headers: headers })
    //     .then((response) => response.json())
    //     .then((data) => {setClients(data);setIsLoading(false)});
    // }, []);

    useEffect(() => {
      if(isConnected){
           socket.emit("clients", {"function":"get_all", "data": {"hostname": auth.domain, "ip": "0.0.0.0"}}, (response) => {
            setClients(response);
            setIsLoading(false);
           })
      }
    }, [isConnected]);

    const [expanded, setExpanded] = React.useState(false);
    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };

    return (
     <>
     <Box component="main" sx={{ 
          flexGrow: 1, mt: {xs: 1, sm: 2}, p:{xs: 1, md:2}, ml: {sm: 25, xs: 0}, width: { md: `calc(100% - ${drawerWidth}px)`, sm: `calc(100% - ${drawerWidth}px)`, xs: '100%' 
     }}}>
            <center>
                 <ButtonGroup variant="contained" color="success" size="small" aria-label="outlined primary button group">
                      <Button component={Link} to={'/client/add'}>Add New Client</Button>
                 </ButtonGroup>
            </center>
       </Box>
       <Box component="main" sx={{ 
            flexGrow: 1, p:{xs: 1, md: 2}, ml: {sm: 25, xs: 0}, width: { md: `calc(100% - ${drawerWidth}px)`, sm: `calc(100% - ${drawerWidth}px)`, xs: '100%' 
       }}}>
      {clients.map((client, index) => (
       <Accordion expanded={expanded === 'panel-' + index} onChange={handleChange('panel-' + index)}>
       <AccordionSummary
         expandIcon={<ExpandMoreIcon />}
         aria-controls={'panel-' + index + '-content'}
         id={"panel-" + index + "-header"}
       >
         <Typography sx={{ width: '33%', flexShrink: 0 }}>
           {client.client_name}
         </Typography>
         <Typography sx={{ color: 'text.secondary' }}> {client.internal_id} </Typography>
       </AccordionSummary>
       <AccordionDetails>
         <Typography>
           View Client Information
         </Typography>
            <center>
                 <ButtonGroup variant="contained" color="success" size="small" aria-label="outlined primary button group">
                      <Button component={Link} to={'/client/' + client.internal_id}>View Client Details</Button>
                 </ButtonGroup>
            </center>
       </AccordionDetails>
     </Accordion>
     ))}
      </Box>
  </>
    )
}

export default Clients;