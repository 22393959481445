import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../auth/AuthWrapper";

export const PrivateRoute = () => {
  const auth = useAuth();
  if (!localStorage.getItem("SAMS")) return <Navigate to="/login" />;
  if (!auth.token) return <Navigate to="/login" />;
  if (!auth.user?.isAuthenticated) return <Navigate to="/login" />;
  if (auth.user?.type != 'employee') return <Navigate to="/error" />;
  return <Outlet />;
};

export const ClientRoute = () => {
  const auth = useAuth();
  if (!localStorage.getItem("SAMS")) return <Navigate to="/login" />;
  if (!auth.user?.token) return <Navigate to="/login" />;
  if (auth.user?.type != 'client') return <Navigate to="/error" />;
  return <Outlet />;
};