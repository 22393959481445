// import { PrinterList } from "./PrinterList";
import { useAuth } from "../auth/AuthWrapper";
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { Box, Avatar, Divider, TextField, InputLabel, Menu, MenuItem, IconButton, Tooltip, FormControl, FormControlLabel, Select, Switch, NativeSelect, Button, Stack, Paper, Card, CardHeader, CardContent, CardActions, Grid } from '@mui/material';
import { useForm, useFormState, Controller, useFieldArray } from "react-hook-form";
import FileSaver from "file-saver";
import { Page, Text, View, Image, Svg, Document, StyleSheet, PDFDownloadLink } from '@react-pdf/renderer';
import { Line as SvgLine } from '@react-pdf/renderer';
import QRCode from 'qrcode'
import { emulateTab } from 'emulate-tab';

const InvRefForm = ({ socket, isConnected, type}) => {
     const drawerWidth = 200;
     const [ job, setJob ] = useState({"job_id":"", "facility_id":""});
     const [ invRefForm, setInvRefForm ] = useState({"job_id":"", "facility_id":"", "device_serial":"", "device_vendor":"", "device_name":"", "device_asset_tag":"", "device_weight":"", "feature_notes":"", "cosmetic_notes":"", "feature_notes":"", "display_size":"", "device_storage":[], "batteries":[], "processors":[], "memory":[], "network_interfaces":[], "motherboards":[], "webcam":[], "diagnostic_results":[]});
     const auth = useAuth();
     let { serial, job_id } = useParams();
     const [readOnly, setReadOnly] = useState(true);
     const navigate = useNavigate();
     const { register, setValue, getValues, setFocus, handleSubmit, control, reset, resetField, watch, formState: { errors } } = useForm({ defaultValues: invRefForm });
     const { fields: cpuFields, remove: removeCpuFields, append: appendCpuFields } = useFieldArray({ control, name: `processors`,});
     const { fields: motherboardFields, remove: removeMotherboardFields, append: appendMotherboardFields } = useFieldArray({ control, name: `motherboards`,});
     const { fields: ramFields, remove: removeRamFields, append: appendRamFields } = useFieldArray({ control, name: `memory`,});
     const { fields: storageFields, remove: removeStorageFields, append: appendStorageFields } = useFieldArray({ control, name: `device_storage`,});
     const { fields: gpuFields, remove: removeGpuFields, append: appendGpuFields } = useFieldArray({ control, name: `gpu`,});
     const { fields: batteryFields, remove: removeBatteryFields, append: appendBatteryFields } = useFieldArray({ control, name: `batteries`,});
     const [ cats, setCats ] = useState('');
     const [ istats, setIstats ] = useState('');
     const [ disp, setDisp] = useState('');
     const { dirtyFields } = useFormState({ control });

     const [ anchorEl, setAnchorEl ] = React.useState(null);
     const open = Boolean(anchorEl);
     const handleClick = (event) => {
          setAnchorEl(event.currentTarget);
     };
     const handleClose = () => {
          setAnchorEl(null);
     };

     const [key, setKey] = useState("");

     const handleSerialTab = (event) => {
          setKey(event.key);
          if(event.key === 'Tab'){
               console.log(event.key)
               appendStorageFields({
                    disk:{
                         vendor: "",
                         model: "",
                         type: "",
                         size: "",
                         serial: ""
                    }
               })
          }
     };

     const onSubmit = async (data, e) => {
          e.preventDefault();
          if(isConnected){
               socket.emit(type, {"function": "save", "data": {"hostname": auth.domain, "ip": "0.0.0.0", "payload": data, "user": auth.user}}, (response) => {
                    console.log(response)
                    setInvRefForm(response)
                    setReadOnly(true);
                    navigate("/" + type + "/item/" + response.device_serial);
               })
            }
     }

     const editClicked = () => {
          setReadOnly(false);
     };
     
     const cancelClicked = () => {
          setReadOnly(true);
          for (const [key, value] of Object.entries(invRefForm)) {
               setValue(key, value)
          }
          for (const [key, value] of Object.entries(job)) {
               if(key === 'facility_id'){
                    setValue(key, value)
               }
          }
          if(serial === 'add'){
              navigate('/job/' + job.job_id)
          }
     }

     const handleCatsChange = (event) => {
          setCats(event.target.value)
          setValue("device_category", event.target.value)
     }

     const handleStatsChange = (event) => {
          setIstats(event.target.value)
          setValue("status", event.target.value)
     }

     const handleDispChange = (event) => {
          setDisp(event.target.value)
          setValue("device_disposition", event.target.value)
     }
     
     useEffect(() => {
        if(isConnected){
               socket.emit(type, {"function": "get_one", "data": {"hostname": auth.domain, "ip": "0.0.0.0", "id": serial}}, (response) => {
                    console.log(response)
                    if(serial != 'add'){
                         setInvRefForm(response)
                         socket.emit("jobs", {"function": "get_one", "data": {"hostname": auth.domain, "ip": "0.0.0.0", "id": response.job_id}}, (response2) => {
                              console.log(response2)
                              setJob(response2)
                         })
                    }
                    if(serial === 'add'){
                         console.log('add')
                         setValue('job_id', job_id)
                    }
               })
           }
     }, [isConnected]);

     useEffect(() => {
          for (const [key, value] of Object.entries(invRefForm)) {
               setValue(key, value)
             }
     }, [invRefForm])

     useEffect(() => {
          if(serial === 'add'){
               console.log('add')
          }else if(job){
               for (const [key, value] of Object.entries(job)) {
                    if(key === 'facility_id'){
                         setValue('facility_id', value)
                    }
               }
          }
     }, [job]);

     var categories = [{"value" : "Select Category", "label" : "Select Category"}, {"value" : "2-in-1", "label" : "2-in-1"}, {"value" : "All-in-One", "label" : "All-in-One"}, {"value" : "ChromeBook", "label" : "ChromeBook"}, {"value" : "ChromeBox", "label" : "ChromeBox"}, {"value" : "Desktop - MicroFF", "label" : "Desktop - MicroFF"}, {"value" : "Desktop - USFF", "label" : "Desktop - USFF"}, {"value" : "Desktop - SFF", "label" : "Desktop - SFF"},
                       {"value" : "Desktop - MT", "label" : "Desktop - MT"}, {"value" : "Desktop - Other", "label" : "Desktop - Other"}, {"value" : "Workstation", "label" : "Workstation"}, {"value" : "Laptop", "label" : "Laptop"}, {"value" : "Notebook", "label" : "Notebook"}, {"value" : "NetBook", "label" : "NetBook"}, {"value" : "Server", "label" : "Server"}, {"value" : "Rack Mount Chassis", "label" : "Rack Mount Chassis"},
                       {"value" : "Networking", "label" : "Networking"}, {"value" : "Business Phone", "label" : "Business Phone"}, {"value" : "Cell Phone", "label" : "Cell Phone"}, {"value" : "SmartPhone", "label" : "SmartPhone"}, {"value" : "Tablet", "label" : "Tablet"}, {"value" : "Keyboard / Mouse", "label" : "Keyboard / Mouse"}, {"value" : "Game Console", "label" : "Game Console"}, {"value" : "Audio / Video", "label" : "Audio / Video"},
                       {"value" : "Medical", "label" : "Medical"}, {"value" : "Personal Assistant", "label" : "Personal Assistant"}, {"value" : "Smart Keyboard", "label" : "Smart Keyboard"}, {"value" : "Power Adapter", "label" : "Power Adapter"}, {"value" : "Power Supply", "label" : "Power Supply"}, {"value" : "Floppy Disk", "label" : "Floppy Disk"}, {"value" : "Flash Drive", "label" : "Flash Drive"}, {"value" : "Docking Station", "label" : "Docking Station"},
                       {"value" : "Data Tape", "label" : "Data Tape"}, {"value" : "Cassette Tape", "label" : "Cassette Tape"}, {"value" : "CD / DVD", "label" : "CD / DVD"}, {"value" : "Camera", "label" : "Camera"}, {"value" : "Detector - Smoke", "label" : "Detector - Smoke"}, {"value" : "TV - CRT", "label" : "TV - CRT"}, {"value" : "TV - LCD", "label" : "TV - LCD"}, {"value" : "VHS Tape", "label" : "VHS Tape"}, {"value" : "Vintage", "label" : "Vintage"},
                       {"value" : "Trash", "label" : "Trash"}, {"value" : "Tool", "label" : "Tool"}, {"value" : "Ink", "label" : "Ink"}, {"value" : "Toner", "label" : "Toner"}, {"value" : "Thin Client", "label" : "Thin Client"}, {"value" : "Specialty", "label" : "Specialty"}, {"value" : "Detector - Carbon Monoxide", "label" : "Detector - Carbon Monoxide"}, {"value" : "Testing", "label" : "Testing"}, {"value" : "Hand Held Electronics", "label" : "Hand Held Electronics"},
                       {"value" : "Lab Equipment", "label" : "Lab Equipment"}, {"value" : "SIM Card", "label" : "SIM Card"}, {"value" : "PDU", "label" : "PDU"}, {"value" : "Shredder", "label" : "Shredder"}, {"value" : "UPS", "label" : "UPS"}, {"value" : "Storage Shelf", "label" : "Storage Shelf"}, {"value" : "SD Card", "label" : "SD Card"}, {"value" : "Server Rack", "label" : "Server Rack"}, {"value" : "Robotics", "label" : "Robotics"}, {"value" : "Projector", "label" : "Projector"},
                       {"value" : "RAM", "label" : "RAM"}, {"value" : "Processor", "label" : "Processor"}, {"value" : "Printer", "label" : "Printer"}, {"value" : "Power Pack", "label" : "Power Pack"}, {"value" : "TV - Console", "label" : "TV - Console"}, {"value" : "3D Printer", "label" : "3D Printer"}, {"value" : "Loose Drive", "label" : "Loose Drive"}, {"value" : "Plotter", "label" : "Plotter"}, {"value" : "Misc / Other", "label" : "Misc / Other"}, {"value" : "Monitor - CRT", "label" : "Monitor - CRT"}, {"value" : "Monitor - LCD", "label" : "Monitor - LCD"}];
     var featureCat = [{"value" : "Select Feature Grade", "label" : "Select Feature Grade"}, {"value" : "0", "label" : "0"},  {"value" : "1", "label" : "1"},  {"value" : "2", "label" : "2"},  {"value" : "3", "label" : "3"},  {"value" : "4", "label" : "4"},  {"value" : "5", "label" : "5"},  {"value" : "6", "label" : "6"},  {"value" : "7", "label" : "7"}];
     var cosCat = [{"value" : "Select Cosmetic Grade", "label" : "Select Cosmetic Grade"}, {"value" : "0", "label" : "0"},  {"value" : "1", "label" : "1"},  {"value" : "2", "label" : "2"},  {"value" : "3", "label" : "3"},  {"value" : "4", "label" : "4"},  {"value" : "5", "label" : "5"},  {"value" : "6", "label" : "6"}, {"value" : "7", "label" : "7"},
                   {"value" : "8", "label" : "8"},  {"value" : "9", "label" : "9"},  {"value" : "10", "label" : "10"}];
     var operatingSysCat = [{"value" : "Select Installed OS", "label" : "Select Installed OS"}, {"value" : "Windows 10 Home", "label" : "Windows 10 Home"},  {"value" : "Windows 10 Pro", "label" : "Windows 10 Pro"},  {"value" : "Windows 11 Home", "label" : "Windows 11 Home"},  {"value" : "Windows 11 Pro", "label" : "Windows 11 Pro"},  {"value" : "macOS Monterey", "label" : "macOS Monterey"},
                            {"value" : "macOS Ventura", "label" : "macOS Ventura"},  {"value" : "macOS Sonoma", "label" : "macOS Sonoma"}, {"value" : "iOS 16.X.X", "label" : "iOS 16.X.X"}, {"value" : "iOS 17.X.X", "label" : "iOS 17.X.X"}, {"value" : "Android 13", "label" : "Android 13"}, {"value" : "Android 14", "label" : "Android 13"},
                            {"value" : "Other, List in Tech Notes", "label" : "Other, List in Tech Notes"}];
     var invRefComplete = [{"value" : "Select Device Completeness", "label" : "Select Device Completeness"}, {"value" : "Complete", "label" : "Complete"}, {"value" : "Incomplete", "label" : "Incomplete"}, {"value" : "Partial", "label" : "Partial"}];
     var invRefStatus = [{"value" : "Select Device Status", "label" : "Select Device Status"}, {"value" : "Inventoried", "label" : "Inventoried"}, {"value" : "Refurbished", "label" : "Refurbished"}, {"value" : "Rejected", "label" : "Rejected"}, {"value" : "Destoryed", "label" : "Destoryed"}, {"value" : "Listed", "label" : "Listed"}, {"value" : "Sold", "label" : "Sold"}, {"value" : "Quarantine", "label" : "Quarantine"}]
     var invRefDisposition = [{"value" : "Select Device Disposition", "label" : "Select Device Disposition"}, {"value" : "Resale", "label" : "Resale"}, {"value" : "Recycle", "label" : "Recycle"}, {"value" : "Recalled Product", "label" : "Recalled Product"}, {"value" : "Internal Use", "label" : "Internal Use"}, {"value" : "Destoryed", "label" : "Destoryed"}, {"value" : "Unusable Resale", "label" : "Unusable Resale"}, {"value" : "Quarantine", "label" : "Quarantine"}];
     var invRefMem = [{"value" : "Select Ram Type", "label" : "Select Ram Type"}, {"value" : "DDR3", "label" : "DDR3"}, {"value" : "LPDDR3", "label" : "LPDDR3"}, {"value" : "DDR4", "label" : "DDR4"}, {"value" : "LPDDR4", "label" : "LPDDR4"}, {"value" : "LPDDR4x", "label" : "LPDDR4x"}, {"value" : "DDR5", "label" : "DDR5"}, {"value" : "LPDDR5", "label" : "LPDDR5"},{"value" : "LPDDR5x", "label" : "LPDDR5x"}];
     var invRefStore = [{"value" : "Select Storage Type", "label" : "Select Storage Type"}, {"value" : "SATA HDD", "label" : "SATA HDD"}, {"value" : "SATA ODD", "label" : "SATA ODD"}, {"value" : "SATA SSD", "label" : "SATA SSD"}, {"value" : "SAS HDD", "label" : "SAS HDD"}, {"value" : "SAS SSD", "label" : "SAS SSD"}, {"value" : "M.2 SATA", "label" : "M.2 NVMe"}, {"value" : "SATA HDD", "label" : "M.2 NVMe"}, {"value" : "Mini SATA", "label" : "Mini SATA"}, {"value" : "U.2 SAS NVMe", "label" : "U.2 SAS NVMe"}, {"value" : "Soldered / OnBoard", "label" : "Solder / OnBoard"}];
     var invSalesOut = [{"value" : "Select Store Front", "label" : "Select Store Front"}, {"value" : "eBay", "label" : "eBay"}, {"value" : "Showroom", "label" : "Showroom"}, {"value" : "Facebook", "label" : "Facebook"}, {"value" : "Craigslist", "label" : "Craigslist"}, {"value" : "Wholesale Lot", "label" : "Wholesale Lot"}]

     console.log(errors)

     // Create styles
  const pdfStyles = StyleSheet.create({
     page: {
       backgroundColor: '#ffffff',
       margin: 0,
       padding: 0,
       position: 'relative'
     },
     header_qehsms: {
       position: 'absolute',
       textAlign: 'center',
       top: 13,
       left: 91,
       width: 135,
       fontSize: 13,
       fontFamily: 'Helvetica-Bold',
       lineHeight: 1.25
     },
     header_rerere: {
       position: 'absolute',
       textAlign: 'left',
       top: 14,
       left: 238,
       width: 38,
       fontSize: 8,
       fontFamily: 'Helvetica',
       lineHeight: 1.2,
       flex: 1
     },
     header_did: {
       position: 'absolute',
       textAlign: 'center',
       fontFamily: 'Helvetica-Bold',
       top: 17,
       left: 276,
       width: 272,
       fontSize: 20
     },
     header_epa_info: {
       position: 'absolute',
       textAlign: 'left',
       top: 48,
       left: 238,
       width: 191,
       fontSize: 8,
       fontFamily: 'Helvetica-Bold',
       lineHeight: 1.2,
       flex: 1,
       overflow: 'hidden'
     },
     header_doc_info: {
       position: 'absolute',
       textAlign: 'left',
       top: 48,
       left: 434,
       width: 166,
       fontSize: 8,
       fontFamily: 'Helvetica-Bold',
       lineHeight: 1.2,
       flex: 1
     },
     header_company_info: {
       position: 'absolute',
       textAlign: 'center',
       top: 83,
       left: 406,
       width: 194,
       fontSize: 8,
       fontFamily: 'Helvetica-Bold',
       lineHeight: 1.3,
       flex: 1
     },
     header_client_info: {
       position: 'absolute',
       textAlign: 'left',
       top: 83,
       left: 175,
       width: 226,
       fontSize: 8,
       fontFamily: 'Helvetica-Bold',
       lineHeight: 1.3,
       flex: 1
     },
     header_partner_logo: {
       position: 'absolute',
       top: 81,
       left: 12,
       width: 160,
       height: 44
     },
     headerImage: {
       height: 81,
       width: 81,
       position: 'absolute',
       top: 0,
       left: 0,
       margin: 0,
       paddingTop: 12,
       paddingLeft: 12
     },
     footer_sigs_title: {
       height: 30,
       width: 588,
       position: 'absolute',
       top: 680,
       left: 12,
       margin: 0,
       justifyContent: 'space-evenly',
       fontFamily: 'Helvetica',
       paddingLeft: 20,
       paddingRight: 20,
       flexDirection: 'row',
       display: 'flex',
       fontSize: 6
     },
     footer_sigs: {
       height: 25,
       width: 588,
       position: 'absolute',
       top: 700,
       left: 12,
       margin: 0,
       justifyContent: 'space-evenly',
       fontFamily: 'Helvetica',
       paddingLeft: 60,
       paddingRight: 60,
       flexDirection: 'row',
       display: 'flex',
       fontSize: 8
     },
     header_footer_images: {
       height: 50,
       width: 588,
       position: 'absolute',
       top: 730,
       left: 12,
       margin: 0,
       justifyContent: 'space-evenly',
       paddingLeft: 10,
       paddingRight: 10,
       flexDirection: 'row',
       display: 'flex'
     },
     pageNumber: {
       position: 'absolute',
       margin: 0,
       top: 29,
       left: 555
     },
     header_doc_title: {
       position: 'absolute',
       textAlign: 'center',
       fontFamily: 'Helvetica-Bold',
       top: 130,
       left: 12,
       width: 588,
       fontSize: 20
     },
     document_page_body: {
       position: 'absolute',
       textAlign: 'Left',
       fontFamily: 'Helvetica',
       top: 165,
       left: 12,
       width: 588,
       fontSize: 9,
       lineHeight: 1.5
     },
     document_body_head: {
       position: 'absolute',
       textAlign: 'center',
       fontFamily: 'Helvetica',
       top: 165,
       left: 12,
       width: 588,
       fontSize: 9,
       lineHeight: 1.5,
       paddingLeft: 20,
       paddingRight: 20
     },
     document_body_content: {
       position: 'absolute',
       textAlign: 'left',
       fontFamily: 'Helvetica',
       top: 165,
       left: 12,
       width: 588,
       height: 360,
       fontSize: 9,
       lineHeight: 1.5,
       paddingLeft: 15,
       paddingRight: 15
     },
     section: {
       margin: 10,
       padding: 10,
       position: 'absolute'
     },
     line1: {
       x1: 82, //starting coords are x1 and y1
       y1: 80,
       x2: 600, //ending coords:
       y2: 80,
       strokeWidth: 1.5,
       stroke: "#4cae4c", //stroke color
       position: 'absolute',
       top: 0,
       left: 0
     },
     line2: {
       x1: 235, //starting coords are x1 and y1
       y1: 12,
       x2: 235, //ending coords:
       y2: 80,
       strokeWidth: 1.5,
       stroke: "#4cae4c", //stroke color
       position: 'absolute',
       top: 0,
       left: 0
     },
     line3: {
       x1: 235, //starting coords are x1 and y1
       y1: 45,
       x2: 600, //ending coords:
       y2: 45,
       strokeWidth: 1.5,
       stroke: "#4cae4c", //stroke color
       position: 'absolute',
       top: 0,
       left: 0
     },
     line4: {
       x1: 431, //starting coords are x1 and y1
       y1: 45,
       x2: 431, //ending coords:
       y2: 80,
       strokeWidth: 1.5,
       stroke: "#4cae4c", //stroke color
       position: 'absolute',
       top: 0,
       left: 0
     },
     line5: {
       x1: 12, //starting coords are x1 and y1
       y1: 126,
       x2: 600, //ending coords:
       y2: 126,
       strokeWidth: 1.5,
       stroke: "#4cae4c", //stroke color
       position: 'absolute',
       top: 0,
       left: 0
     },
     line6: {
       x1: 12, //starting coords are x1 and y1
       y1: 156,
       x2: 600, //ending coords:
       y2: 156,
       strokeWidth: 1.5,
       stroke: "#4cae4c", //stroke color
       position: 'absolute',
       top: 0,
       left: 0
     },
     line7: {
       x1: 403, //starting coords are x1 and y1
       y1: 80,
       x2: 403, //ending coords:
       y2: 126,
       strokeWidth: 1.5,
       stroke: "#4cae4c", //stroke color
       position: 'absolute',
       top: 0,
       left: 0
     },
     line8: {
       x1: 172, //starting coords are x1 and y1
       y1: 80,
       x2: 172, //ending coords:
       y2: 126,
       strokeWidth: 1.5,
       stroke: "#4cae4c", //stroke color
       position: 'absolute',
       top: 0,
       left: 0
     },
     line9: {
       x1: 12, //starting coords are x1 and y1
       y1: 722,
       x2: 600, //ending coords:
       y2: 722,
       strokeWidth: 1.5,
       stroke: "#4cae4c", //stroke color
       position: 'absolute',
       top: 0,
       left: 0
     }
   });

   var company_logo = "company_logo"
   var company_name = "Techno Rescue"
   var partner_logo = "blank"
   var status_header = "status_header"
   
   var qrOpts = {
     errorCorrectionLevel: 'M',
     type: 'image/png',
     quality: 0.3,
     margin: 1,
     color: {
       dark:"#000000",
       light:"#FFFFFF"
     }
   }
   
   var qr_for_form = QRCode.toDataURL('text', qrOpts, async function (err, url) {
     if (err) throw err
     var new_url = url.toString() + '='
     return new_url
   })

   var storage_for_form = invRefForm.device_storage.map(function(stor, i){
     var diagnostic_result = ''
     var diagnostic_color = ''
     if(invRefForm.diagnostic_results){
          invRefForm.diagnostic_results.forEach(result => {
               if(result.name === 'Disk'){
                    if(result.result === 'PASS'){
                         diagnostic_color = 'green'
                         diagnostic_result = result.result
                    }else if(result.result === 'FAIL'){
                         diagnostic_color = 'red'
                         diagnostic_result = result.result
                    }else{
                         diagnostic_color = 'orange'
                         diagnostic_result = 'N/A'
                    }
               }
          })
     }else{
          diagnostic_color = 'orange'
          diagnostic_result = 'N/A'
     }

     return (
       <View key={'stor-' + i} style={{flexDirection: 'row'}} wrap={false}>
          <Text>Storage: {stor.disk?.vendor} {stor.disk?.size} {stor.disk?.type} - {stor.disk?.model} (S/N: {stor.disk?.serial}) | </Text><Text style={{ color: diagnostic_color}}>{diagnostic_result}</Text>
       </View>
     );
   });

   var memory_for_form = invRefForm.memory.map(function(mem, i){
     var diagnostic_result = ''
     var diagnostic_color = ''
     if(invRefForm.diagnostic_results){
          invRefForm.diagnostic_results.forEach(result => {
               if(result.name === 'Memory'){
                    if(result.result === 'PASS'){
                         diagnostic_color = 'green'
                         diagnostic_result = result.result
                    }else if(result.result === 'FAIL'){
                         diagnostic_color = 'red'
                         diagnostic_result = result.result
                    }else{
                         diagnostic_color = 'orange'
                         diagnostic_result = 'N/A'
                    }
               }
          })
     }else{
          diagnostic_color = 'orange'
          diagnostic_result = 'N/A'
     }

     return (
       <View key={'mem-' + i} style={{flexDirection: 'row'}} wrap={false}>
          <Text>Memory: {mem.size} {mem.type} {mem.speed} | </Text><Text style={{ color: diagnostic_color}}>{diagnostic_result}</Text>
       </View>
     );
   });

   var processor_for_form = invRefForm.processors.map(function(proc, i){
     var diagnostic_result = ''
     var diagnostic_color = ''
     if(invRefForm.diagnostic_results){
          invRefForm.diagnostic_results.forEach(result => {
               if(result.name === 'CPU'){
                    if(result.result === 'PASS'){
                         diagnostic_color = 'green'
                         diagnostic_result = result.result
                    }else if(result.result === 'FAIL'){
                         diagnostic_color = 'red'
                         diagnostic_result = result.result
                    }else{
                         diagnostic_color = 'orange'
                         diagnostic_result = 'N/A'
                    }
               }
          })
     }else{
          diagnostic_color = 'orange'
          diagnostic_result = 'N/A'
     }

     return (
       <View key={'proc-' + i} style={{flexDirection: 'row'}} wrap={false}>
          <Text>Processor: {proc.product} | </Text><Text style={{ color: diagnostic_color}}>{diagnostic_result}</Text>
       </View>
     );
   });

   var network_for_form = invRefForm.network_interfaces.map(function(net, i){
     var diagnostic_result = ''
     var diagnostic_color = ''
     if(invRefForm.diagnostic_results){
          invRefForm.diagnostic_results.forEach(result => {
               if(result.name.includes('Ethernet')){
                    if(result.result === 'PASS'){
                         diagnostic_color = 'green'
                         diagnostic_result = result.result
                    }else if(result.result === 'FAIL'){
                         diagnostic_color = 'red'
                         diagnostic_result = result.result
                    }else{
                         diagnostic_color = 'orange'
                         diagnostic_result = 'N/A'
                    }
               }else if(result.name.includes('WiFi')){
                    if(result.result === 'PASS'){
                         diagnostic_color = 'green'
                         diagnostic_result = result.result
                    }else if(result.result === 'FAIL'){
                         diagnostic_color = 'red'
                         diagnostic_result = result.result
                    }else{
                         diagnostic_color = 'orange'
                         diagnostic_result = 'N/A'
                    }
               }
          })
     }else{
          diagnostic_color = 'orange'
          diagnostic_result = 'N/A'
     }
     if(net.product?.includes('Ethernet')){
          return (
            <View key={'net-' + i} style={{flexDirection: 'row'}} wrap={false}>
               <Text>Ethernet: {net.vendor} {net.model} (S/N: {net.serial}) | </Text><Text style={{ color: diagnostic_color}}>{diagnostic_result}</Text>
            </View>
          );
     }else if(net.product?.includes('Wireless')){
          return (
            <View key={'net-' + i} style={{flexDirection: 'row'}} wrap={false}>
               <Text>Wireless: {net.vendor} {net.model} (S/N: {net.serial}) | </Text><Text style={{ color: diagnostic_color}}>{diagnostic_result}</Text>
            </View>
          );
     }
   });

   var batteries_for_form = invRefForm.batteries.map(function(batt, i){
     var diagnostic_result = ''
     var diagnostic_color = ''
     if(invRefForm.diagnostic_results){
          invRefForm.diagnostic_results.forEach(result => {
               if(result.name === 'Battery'){
                    if(result.result === 'PASS'){
                         diagnostic_color = 'green'
                         diagnostic_result = result.result
                    }else if(result.result === 'FAIL'){
                         diagnostic_color = 'red'
                         diagnostic_result = result.result
                    }else{
                         diagnostic_color = 'orange'
                         diagnostic_result = 'N/A'
                    }
               }
          })
     }else{
          diagnostic_color = 'orange'
          diagnostic_result = 'N/A'
     }

     return (
       <View key={'batt-' + i} style={{flexDirection: 'row'}} wrap={false}>
           <Text>Battery: {batt.model} {batt.capacity} ({batt.cycles} Cycles) | </Text><Text style={{ color: diagnostic_color}}>{diagnostic_result}</Text>
       </View>
     );
   });

   var motherboard_for_form = invRefForm.motherboards.map(function(board, i){
     var diagnostic_result = ''
     var diagnostic_color = ''
     if(invRefForm.diagnostic_results){
          invRefForm.diagnostic_results.forEach(result => {
               if(result.name === 'Motherboard'){
                    if(result.result === 'PASS'){
                         diagnostic_color = 'green'
                         diagnostic_result = result.result
                    }else if(result.result === 'FAIL'){
                         diagnostic_color = 'red'
                         diagnostic_result = result.result
                    }else{
                         diagnostic_color = 'orange'
                         diagnostic_result = 'N/A'
                    }
               }
          })
     }else{
          diagnostic_color = 'orange'
          diagnostic_result = 'N/A'
     }

     return (
       <View key={'board-' + i} style={{flexDirection: 'row'}} wrap={false}>
           <Text>Motherboard: {board.vendor} {board.product} | </Text><Text style={{ color: diagnostic_color}}>{diagnostic_result}</Text>
       </View>
     );
   });

   var display_for_form = invRefForm.diagnostic_results.map(function(result, i){
     var diagnostic_result = ''
     var diagnostic_color = ''
     if(result.name === 'Display'){
          if(result.result === 'PASS'){
               diagnostic_color = 'green'
               diagnostic_result = result.result
          }else if(result.result === 'FAIL'){
               diagnostic_color = 'red'
               diagnostic_result = result.result
          }else{
               diagnostic_color = 'orange'
               diagnostic_result = 'N/A'
          }
          if(invRefForm.display_size === null || invRefForm.display_size === '' || invRefForm.display_size === '0'){
               return null
          }
          return (
               <View key={'display-' + i} style={{flexDirection: 'row'}} wrap={false}>
                    <Text>Display: {invRefForm.display_size} | </Text><Text style={{ color: diagnostic_color}}>{diagnostic_result}</Text>
               </View>
          );
     }
   });

   var keyboard_for_form = invRefForm.diagnostic_results.map(function(result, i){
     var diagnostic_result = ''
     var diagnostic_color = ''
     if(result.name === 'Keyboard'){
          if(result.result === 'PASS'){
               diagnostic_color = 'green'
               diagnostic_result = result.result
          }else if(result.result === 'FAIL'){
               diagnostic_color = 'red'
               diagnostic_result = result.result
          }else{
               diagnostic_color = 'orange'
               diagnostic_result = 'N/A'
          }
          return (
               <View key={'keyboard-' + i} style={{flexDirection: 'row'}} wrap={false}>
                    <Text>Keyboard: </Text><Text style={{ color: diagnostic_color}}>{diagnostic_result}</Text>
               </View>
          );
     }
   });

   var speakers_for_form = invRefForm.diagnostic_results.map(function(result, i){
     var diagnostic_result = ''
     var diagnostic_color = ''
     if(result.name === 'Speakers'){
          if(result.result === 'PASS'){
               diagnostic_color = 'green'
               diagnostic_result = result.result
          }else if(result.result === 'FAIL'){
               diagnostic_color = 'red'
               diagnostic_result = result.result
          }else{
               diagnostic_color = 'orange'
               diagnostic_result = 'N/A'
          }
          return (
               <View key={'speaker-' + i} style={{flexDirection: 'row'}} wrap={false}>
                    <Text>Speakers: </Text><Text style={{ color: diagnostic_color}}>{diagnostic_result}</Text>
               </View>
          );
     }
   });

   var microphone_for_form = invRefForm.diagnostic_results.map(function(result, i){
     var diagnostic_result = ''
     var diagnostic_color = ''
          if(result.name === 'Microphone'){
               if(result.result === 'PASS'){
                    diagnostic_color = 'green'
                    diagnostic_result = result.result
               }else if(result.result === 'FAIL'){
                    diagnostic_color = 'red'
                    diagnostic_result = result.result
               }else{
                    diagnostic_color = 'orange'
                    diagnostic_result = 'N/A'
               }
               return (
                    <View key={'mic-' + i} style={{flexDirection: 'row'}} wrap={false}>
                         <Text>Microphone: </Text><Text style={{ color: diagnostic_color}}>{diagnostic_result}</Text>
                    </View>
               );
          }
   });

   var usb_for_form = invRefForm.diagnostic_results.map(function(result, i){
     var diagnostic_result = ''
     var diagnostic_color = ''
     if(result.name === 'USB'){
          if(result.result === 'PASS'){
               diagnostic_color = 'green'
               diagnostic_result = result.result
          }else if(result.result === 'FAIL'){
               diagnostic_color = 'red'
               diagnostic_result = result.result
          }else{
               diagnostic_color = 'orange'
               diagnostic_result = 'N/A'
          }
          return (
               <View key={'usb-' + i} style={{flexDirection: 'row'}} wrap={false}>
                    <Text>USB: </Text><Text style={{ color: diagnostic_color}}>{diagnostic_result}</Text>
               </View>
          );
     }
   });

   var webcam_for_form = invRefForm.webcam.map(function(cam, i){
     var diagnostic_result = ''
     var diagnostic_color = ''
     if(invRefForm.diagnostic_results){
          invRefForm.diagnostic_results.forEach(result => {
               if(result.name === 'Webcam'){
                    if(result.result === 'PASS'){
                         diagnostic_color = 'green'
                         diagnostic_result = result.result
                    }else if(result.result === 'FAIL'){
                         diagnostic_color = 'red'
                         diagnostic_result = result.result
                    }else{
                         diagnostic_color = 'orange'
                         diagnostic_result = 'N/A'
                    }
               }
          })
     }else{
          diagnostic_color = 'orange'
          diagnostic_result = 'N/A'
     }

     return (
       <View key={'cam-' + i} style={{flexDirection: 'row'}} wrap={false}>
           <Text>Webcam: {cam.vendor} {cam.product} | </Text><Text style={{ color: diagnostic_color}}>{diagnostic_result}</Text>
       </View>
     );
   });

   const CorHeader = () => (
     <>
     <Image fixed style={pdfStyles.headerImage} src={"/assets/img/company_logos/" + company_logo + ".png"} />
     <Image fixed style={pdfStyles.header_partner_logo} src={"/assets/img/partner_logos/" + partner_logo + ".png"} />
     <View style={pdfStyles.pageNumber} fixed render={({ pageNumber, totalPages }) => (<Text style={{ color: 'grey', fontSize: 8 }}>Page: {pageNumber} / {totalPages}</Text>)} />
   <View style={pdfStyles.header_qehsms} fixed>
     <Text>Quality</Text>
     <Text>Environmental</Text>
     <Text>Health & Safety</Text>
     <Text>Management System</Text>
   </View>
   <View style={pdfStyles.header_rerere} fixed>
     <View style={{flexDirection: 'row'}}>
       <Text style={{fontFamily: 'Helvetica-Bold'}}>RE</Text><Text>use</Text>
     </View>
     <View style={{flexDirection: 'row'}}>
       <Text style={{fontFamily: 'Helvetica-Bold'}}>RE</Text><Text>cover</Text>
     </View>
     <View style={{flexDirection: 'row'}}>
       <Text style={{fontFamily: 'Helvetica-Bold'}}>RE</Text><Text>cycle</Text>
     </View>
   </View>
   <View style={pdfStyles.header_did} fixed>
     <Text>DID: FRM-003</Text>
   </View>
   <View style={pdfStyles.header_epa_info} fixed>
     <View style={{flexDirection: 'row'}} wrap={false}>
       <Text style={{fontFamily: 'Helvetica'}}>C: </Text><Text>{invRefForm.cosmetic_grade} </Text><Text style={{fontFamily: 'Helvetica'}}>| F: </Text><Text>{invRefForm.feature_grade} </Text><Text style={{fontFamily: 'Helvetica'}}>| W: </Text><Text>{invRefForm.device_weight} </Text><Text style={{fontFamily: 'Helvetica'}}>| T: </Text><Text>{invRefForm.entered_by}</Text>
     </View>
     <View style={{flexDirection: 'row'}} wrap={false}>
       <Text style={{fontFamily: 'Helvetica'}}>Disposition: </Text><Text>{invRefForm.device_disposition}</Text>
     </View>
     <View style={{flexDirection: 'row'}} wrap={false}>
       <Text style={{fontFamily: 'Helvetica'}}>Lot: </Text><Text>{invRefForm.lot_id}</Text>
     </View>
   </View>
   <View style={pdfStyles.header_doc_info} fixed>
     <View style={{flexDirection: 'row'}}>
       <Text style={{fontFamily: 'Helvetica'}}>Type: </Text><Text>Refurbishment Form v2.0</Text>
     </View>
     <View style={{flexDirection: 'row'}}>
       <Text style={{fontFamily: 'Helvetica'}}>Implemented: </Text><Text>2024-05-16 </Text><Text style={{fontFamily: 'Helvetica'}}>by </Text><Text>eda</Text>
     </View>
     <View style={{flexDirection: 'row'}}>
       <Text style={{fontFamily: 'Helvetica'}}>Last Revision: </Text><Text>2024-05-16 </Text><Text style={{fontFamily: 'Helvetica'}}>by </Text><Text>eda</Text>
     </View>
   </View>
   <View style={pdfStyles.header_client_info} fixed>
     <View style={{flexDirection: 'row'}} wrap={false}>
       <Text style={{fontFamily: 'Helvetica'}}>Device: </Text><Text>{invRefForm.device_vendor} {invRefForm.device_name} {invRefForm.device_category}</Text>
     </View>
     <View style={{flexDirection: 'row'}} wrap={false}>
       <Text style={{fontFamily: 'Helvetica'}}>S/N: </Text><Text>{invRefForm.job_id}-{invRefForm.device_serial}</Text>
     </View>
     <View style={{flexDirection: 'row'}} wrap={false}>
       <Text style={{fontFamily: 'Helvetica'}}>Outlet: </Text><Text>{invRefForm.outlet_id} </Text><Text style={{fontFamily: 'Helvetica'}}>| Listed On: </Text><Text>{invRefForm.listing_date}</Text>
     </View>
     <View style={{flexDirection: 'row'}} wrap={false}>
       <Text style={{fontFamily: 'Helvetica'}}>Price: </Text><Text>{invRefForm.listing_price} </Text><Text style={{fontFamily: 'Helvetica'}}>| </Text><Text>{invRefForm.listing_id}</Text>
     </View>
   </View>
   <View style={pdfStyles.header_company_info} fixed>
     <Text>Techno Rescue</Text>
     <Text>help@technorescue.com</Text>
     <Text>www.technorescue.com</Text>
     <Text>1(800)230-5280</Text>
   </View></>
   );

   const MyDocument = () => (
     <Document>
       <Page size="LETTER" style={pdfStyles.page}>
       <Svg fixed width={"100%"} height={"100%"}>
         <SvgLine style={pdfStyles.line1} />
         <SvgLine style={pdfStyles.line2} />
         <SvgLine style={pdfStyles.line3} />
         <SvgLine style={pdfStyles.line4} />
         <SvgLine style={pdfStyles.line5} />
         <SvgLine style={pdfStyles.line6} />
         <SvgLine style={pdfStyles.line7} />
         <SvgLine style={pdfStyles.line8} />
         <SvgLine style={pdfStyles.line9} />
       </Svg>
         <CorHeader />
         <View style={pdfStyles.header_doc_title} fixed>
           <Text>Refurbishment Form</Text>
         </View>
         <View style={pdfStyles.document_body_head}>
         </View>
         <View style={pdfStyles.document_body_content}>
          <Text>Device: {invRefForm.device_vendor} {invRefForm.device_name} {invRefForm.device_category}</Text>
          <Text> </Text>
          {processor_for_form}
          <Text> </Text>
          {motherboard_for_form}
          <Text> </Text>
          {memory_for_form}
          <Text> </Text>
          {storage_for_form}
          <Text> </Text>
          {network_for_form}
          <Text> </Text>
          {display_for_form}
          <Text> </Text>
          {usb_for_form}
          <Text> </Text>
          {keyboard_for_form}
          <Text> </Text>
          {speakers_for_form}
          <Text> </Text>
          {microphone_for_form}
          <Text> </Text>
          {webcam_for_form}
          <Text> </Text>
          {batteries_for_form}
          <Text> </Text>
          <Text>Cosmetic Notes:</Text>
          <Text>{invRefForm.cosmetic_notes}</Text>
          <Text> </Text>
          <Text>Feature Notes:</Text>
          <Text>{invRefForm.feature_notes}</Text>
         </View>
        <View style={pdfStyles.footer_sigs} >
          <View style={{flexDirection: 'row'}}>
            <Text style={{fontFamily: 'Helvetica'}}> </Text><Text> </Text>
          </View>
        </View>
         <View style={pdfStyles.header_footer_images} >
           <Image src="/assets/img/QEHS21.png" />
           <Image src="/assets/img/r2v3-logo.png" />
           <Image src="/assets/img/naid-aaa-logo.png" />
           <Image src="/assets/img/VOSB-logo.png" />
           <Image src="/assets/img/orion-logo.png" />
           <Image src="/assets/img/e-stewards-certified-logo.png" />
         </View>
       </Page>
     </Document>
   );
     watch(errors)
return (
     <Box component="main" sx={{
          flexGrow: 1, p: 3, ml: {xl: 25, lg: 25, md: 25, sm: 25, xs: 0}, width: { md: `calc(100% - ${drawerWidth}px)`, sm: `calc(100% - ${drawerWidth}px)`, xs: '100%' 
     }}}>
          <form onSubmit={handleSubmit(onSubmit)} id='invRefForm'>
               <Card sx={{my: 2}}>
                    <CardHeader title="Job Information" />
                    <CardContent>
                         <Controller name="job_id" control={control} rules={{ required: true }} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Job ID" variant="standard" />} />
                         <Controller name="facility_id" control={control} rules={{ required: false }} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: true }} label="Facility ID" variant="standard" />} />
                    </CardContent>
               </Card>
               {(type === 'refurbishment') ?
               <>
               <Card sx={{my: 2}}>
                    <CardHeader title="Device Information" />
                    <CardContent>
                         <InputLabel id="device_category">Category</InputLabel>
                         <Controller name="device_category" control={control} rules={{ required: true }} render={({ field }) => 
                              <NativeSelect {...field} labelId="device_category" label="Device Category" disabled={readOnly} onChange={handleCatsChange} defaultValue={field} variant="standard" >
                              <option value="Not Selected">Select Category Type</option>
                              {categories.map((category, i) => {
                                   return(
                                        <option value={category.value}>{category.label}</option>
                                   )
                              })}
                         </NativeSelect>} />
                         <Controller name="device_vendor" control={control} rules={{ required: true }} render={({ field }) => <TextField {...field} error={errors?.device_vendor} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Vendor" variant="standard" />} />
                         <Controller name="device_name" control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Model" variant="standard" />} />
                         <Controller name="device_serial" control={control} rules={{ required: true }} render={({ field }) => <TextField {...field} error={errors?.device_serial} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Device Serial" variant="standard" />} />
                         <Controller name="device_asset_tag" control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Asset Tag" variant="standard" />} />
                         <Controller name="device_weight" control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Weight" variant="standard" />} />
                         <InputLabel id="status" variant="standard">Device Status</InputLabel>
                         <Controller name="status" control={control} rules={{ required: true }} render={({ field }) => <NativeSelect {...field} error={errors?.stats} labelId="status" label="Device Status" disabled={readOnly} onChange={handleStatsChange} defaultValue={field} variant="standard" >
                              {invRefStatus.map((stats, i) => {
                                   return(
                                        <option value={stats.value}>{stats.label}</option>
                                   )
                              })}
                         </NativeSelect>} />
                         <InputLabel id="device_disposition" variant="standard">Device Disposition</InputLabel>
                         <Controller name="device_disposition" control={control} rules={{ required: true }} render={({ field }) => <NativeSelect {...field} error={errors?.invRefDisposition} labelId="device_disposition" label="Device Disposition" disabled={readOnly} onChange={handleDispChange} defaultValue={field} variant="standard" >
                              {invRefDisposition.map((dispo, i) => {
                                   return(
                                        <option value={dispo.value}>{dispo.label}</option>
                                   )
                              })}
                         </NativeSelect>} />
                    </CardContent>
               </Card>
               <Card sx={{my: 2}}>
                    <CardHeader title="Grading Information" sx={{fontSize: 10}}/>
                    <CardContent>
                         <InputLabel id="device_completeness" variant="standard">Device Completeness</InputLabel>
                         <Controller name="device_completeness" control={control} rules={{ required: true }} render={({ field }) => <NativeSelect {...field} error={errors?.device_completeness} labelId="device_completeness" label="Device Completeness" disabled={readOnly} defaultValue={field} variant="standard" >
                              {invRefComplete.map((invcomp, i) => {
                                   return(
                                        <option value={invcomp.value}>{invcomp.label}</option>
                                   )
                              })}
                         </NativeSelect>} />
                         <InputLabel id="feature_grade" variant="standard">Feature Grade</InputLabel>
                         <Controller name="feature_grade" control={control} rules={{ required: true }} render={({ field }) => <NativeSelect {...field} error={errors?.feature_grade} labelId="feature_grade" label="Feature Grade" disabled={readOnly} defaultValue={field} variant="standard"  >
                              {featureCat.map((feat, i) => {
                                   return(
                                        <option value={feat.value}>{feat.label}</option>
                                   )
                              })}
                         </NativeSelect>} />
                         <Controller name="feature_notes" control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Feature Notes" variant="standard" multiline rows={2} fullWidth />} />
                         <InputLabel id="cosmetic_grade" variant="standard">Cosmetic Grade</InputLabel>
                         <Controller name="cosmetic_grade" control={control} rules={{ required: true }} render={({ field }) => <NativeSelect {...field} error={errors?.cosmetic_grade} labelId="cosmetic_grade" label="Cosmetic Grade" disabled={readOnly} defaultValue={field} variant="standard"  >
                              {cosCat.map((cosmet, i) => {
                                   return(
                                        <option value={cosmet.value}>{cosmet.label}</option>
                                   )
                              })}
                         </NativeSelect>} />
                         <Controller name="cosmetic_notes" control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Cosmetic Notes" variant="standard" multiline rows={2} fullWidth />} />
                    </CardContent>
               </Card>
               </>
               :
               <>
               <Card sx={{my: 2}}>
                    <CardHeader title="Device Information" />
                    <CardContent>
                         <InputLabel id="device_category">Category</InputLabel>
                         <Controller name="device_category" control={control} rules={{ required: true }} render={({ field }) => 
                              <NativeSelect {...field} error={errors?.device_category} labelId="device_category" label="Device Category" disabled={readOnly} onChange={handleCatsChange} defaultValue={field} variant="standard" >
                              <option value="Not Selected">Select Category Type</option>
                              {categories.map((category, i) => {
                                   return(
                                        <option value={category.value}>{category.label}</option>
                                   )
                              })}
                         </NativeSelect>} />
                         <Controller name="device_vendor" control={control} rules={{ required: true }} render={({ field }) => <TextField {...field} error={errors?.device_vendor} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Vendor" variant="standard" />} />
                         <Controller name="device_name" control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Model" variant="standard" />} />
                         <Controller name="device_serial" control={control} rules={{ required: true }} render={({ field }) => <TextField {...field} error={errors?.device_serial} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Device Serial" helperText="Blank entry, Please enter device serial." variant="standard" />} />
                         <Controller name="device_asset_tag" control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Asset Tag" variant="standard" />} />
                         <Controller name="device_weight" control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Weight" variant="standard" />} />
                         <InputLabel id="device_completeness" variant="standard">Device Completeness</InputLabel>
                         <Controller name="device_completeness" control={control} rules={{ required: true }} render={({ field }) => <NativeSelect {...field} error={errors?.device_completeness} labelId="device_completeness" label="Device Completeness" disabled={readOnly} defaultValue={field} variant="standard" >
                              {invRefComplete.map((invcomp, i) => {
                                   return(
                                        <option value={invcomp.value}>{invcomp.label}</option>
                                   )
                              })}
                         </NativeSelect>} /> 
                         <InputLabel id="status" variant="standard">Device Status</InputLabel>
                         <Controller name="status" control={control} rules={{ required: true }} render={({ field }) => <NativeSelect {...field} error={errors?.status} labelId="status" label="Device Status" disabled={readOnly} onChange={handleStatsChange} defaultValue={field} variant="standard" >
                              {invRefStatus.map((stats, i) => {
                                   return(
                                        <option value={stats.value}>{stats.label}</option>
                                   )
                              })}
                         </NativeSelect>} />
                         <InputLabel id="device_disposition" variant="standard">Device Disposition</InputLabel>
                         <Controller name="device_disposition" control={control} rules={{ required: true }} render={({ field }) => <NativeSelect {...field} error={errors?.device_disposition} labelId="device_disposition" label="Device Disposition" disabled={readOnly} onChange={handleDispChange} defaultValue={field} variant="standard" >
                              {invRefDisposition.map((dispo, i) => {
                                   return(
                                        <option value={dispo.value}>{dispo.label}</option>
                                   )
                              })}
                         </NativeSelect>} />
                    </CardContent>
               </Card>
               </>}
               {(type === 'refurbishment') ?
               <>
               <Card sx={{my: 2}}>
                    <CardHeader title="Sale Information" sx={{fontSize: 10}}/>
                    <CardContent>
                         <Controller name={`outlet_id`} control={control} rules={{ required: true }} render={({ field }) => 
                              <NativeSelect {...field} error={errors?.invSalesOut} defaultValue={field} disabled={readOnly} label={`Item listed in which Store Front?`} variant="standard" >  
                              {invSalesOut.map((sellout, i) => {
                                   return(
                                        <option value={sellout.value}>{sellout.label}</option>
                                   )
                              })}
                              </NativeSelect> }/>
                         <Controller name={`listing_id`} control={control} rules={{ required: true }} render={({ field }) => <TextField {...field} error={errors?.listing_id} defaultValue={field} InputProps={{ readOnly: readOnly }} label={`Listing ID / Website`} variant="standard" />} />
                         <Controller name={`listing_date`} control={control} rules={{ required: false }} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label={`Date Item Listed`} variant="standard" />} />
                         <Controller name={`listing_price`} control={control} rules={{ required: false }} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label={`Item Asking Price ($)`} variant="standard" />} />
                         <Controller name={`sold_date`} control={control} rules={{ required: false }} render={({ field }) => <TextField {...field} error={errors?.sold_date} defaultValue={field} InputProps={{ readOnly: readOnly }} label={`Date Item Sold`} variant="standard" />} />
                         <Controller name={`sold_price`} control={control} rules={{ required: false }} render={({ field }) => <TextField {...field} error={errors?.sold_price} defaultValue={field} InputProps={{ readOnly: readOnly }} label={`Item Sold Price ($)`} variant="standard" />} />
                         <Controller name={`sold_notes`} control={control} rules={{ required: false }} render={({ field }) => <TextField {...field} error={errors?.sold_notes} defaultValue={field} InputProps={{ readOnly: readOnly }} label={`Sales Notes`} variant="standard" />} />
                    </CardContent>
               </Card>
               </>
               :
               null}
               {(type === 'refurbishment') ?
               <>
               <Card sx={{my: 2}}>
                    <CardHeader title="Other Attributes" sx={{fontSize: 10}}/>
                    <CardContent>
                         <InputLabel id="operating_system" variant="standard">Operating System</InputLabel>
                         <Controller name="operating_system" control={control} rules={{ required: true }} render={({ field }) => <NativeSelect {...field} error={errors?.operating_system} labelId="operating_system" label="Operating System" disabled={readOnly} defaultValue={field} variant="standard" >
                              {operatingSysCat.map((opcat, i) => {
                                   return(
                                        <option value={opcat.value}>{opcat.label}</option>
                                   )
                              })}
                         </NativeSelect>} />
                         <Controller name="bluetooth.0.description" control={control} render={({ field: { onChange, value } }) => ( <FormControlLabel control={<Switch checked={value} disabled={readOnly} onChange={onChange} />} label="Bluetooth" /> )} />
                         <Controller name="network_interfaces.1.description" control={control} render={({ field: { onChange, value } }) => ( <FormControlLabel control={<Switch checked={value} disabled={readOnly} onChange={onChange} />} label="Wireless" /> )} />
                         <Controller name="network_interfaces.0.description" control={control} render={({ field: { onChange, value } }) => ( <FormControlLabel control={<Switch checked={value} disabled={readOnly} onChange={onChange} />} label="Ethernet" /> )} />
                         <Controller name="dvd.0.description" control={control} render={({ field: { onChange, value } }) => ( <FormControlLabel control={<Switch checked={value} disabled={readOnly} onChange={onChange} />} label="Optical Drive" /> )} />
                    </CardContent>
               </Card>
               </>
               :
               null}
               <Card sx={{my: 2}}>
                    <CardHeader title={`Processor Information`} sx={{fontSize: 10}}/>
                    <CardContent>
                         <Grid container columns={{ xs: 1, sm: 2, md: 6, lg: 9, xl: 12 }}>
                         {cpuFields.map((cpuField, i) => {
                              var diagnostic_color = '';
                              var diagnostic_result = '';
                              invRefForm.diagnostic_results?.forEach(result => {
                                   if(result.name === 'CPU'){
                                        if(result.result === 'PASS'){
                                             diagnostic_color = 'green'
                                             diagnostic_result = result.result
                                        }else if(result.result === 'FAIL'){
                                             diagnostic_color = 'red'
                                             diagnostic_result = result.result
                                        }else{
                                             diagnostic_color = 'orange'
                                             diagnostic_result = 'N/A'
                                        }
                                   }
                              })
                              return (
                                   <Card sx={{mx: 2, my: 2}}>
                                        <CardHeader title={`CPU ${i} Specs`} subheader={`Diagnostic Results: ${diagnostic_result}`} subheaderTypographyProps={{ color: diagnostic_color }} sx={{fontSize: 6}}/>
                                        <CardContent sx={{maxWidth:500}}>
                                             <Controller name={`processors.${i}.vendor`} control={control} rules={{ required: true }} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label={`Processor ${i} Vendor`} variant="standard" /> } />
                                             <Controller name={`processors.${i}.model`} control={control} rules={{ required: true }} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label={`Processor ${i} Model`} variant="standard" />} />
                                             <Controller name={`processors.${i}.cores`} control={control} rules={{ required: false }} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label={`Processor ${i} Cores`} variant="standard" />} />
                                             <Controller name={`processors.${i}.threads`} control={control} rules={{ required: false }} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label={`Processor ${i} Threads`} variant="standard" />} />
                                             <Controller name={`processors.${i}.speed`} control={control} rules={{ required: true }} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label={`Processor ${i} Speed`} variant="standard" />} />
                                        </CardContent>
                                        <CardActions>
                                             {!readOnly && (<button type="button" onClick={() => removeCpuFields(i)}>Remove CPU</button>)}
                                        </CardActions>
                                   </Card>
                              )
                         })}
                         </Grid>
                    </CardContent>
                    <CardActions>
                         {!readOnly && (<button
                              type="button"
                              onClick={() =>
                                   appendCpuFields({
                                        vendor: "",
                                        model: "",
                                        cores: "",
                                        threads: "",
                                        speed: ""
                                   })
                              }
                         >
                              Add CPU
                         </button>)}
                    </CardActions>
               </Card>
               <Card sx={{my: 2}}>
                    <CardHeader title={`Motherboard Information`} sx={{fontSize: 10}}/>
                    <CardContent>
                         <Grid container columns={{ xs: 1, sm: 2, md: 6, lg: 9, xl: 12 }}>
                         {motherboardFields.map((mbField, i) => {
                              var diagnostic_color = '';
                              var diagnostic_result = '';
                              invRefForm.diagnostic_results?.forEach(result => {
                                   if(result.name === 'Motherboard'){
                                        if(result.result === 'PASS'){
                                             diagnostic_color = 'green'
                                             diagnostic_result = result.result
                                        }else if(result.result === 'FAIL'){
                                             diagnostic_color = 'red'
                                             diagnostic_result = result.result
                                        }else{
                                             diagnostic_color = 'orange'
                                             diagnostic_result = 'N/A'
                                        }
                                   }
                              })
                              return (
                                   <Card sx={{mx: 2, my: 2}}>
                                        <CardHeader title={`Motherboard ${i} Specs`} subheader={`Diagnostic Results: ${diagnostic_result}`} subheaderTypographyProps={{ color: diagnostic_color }} sx={{fontSize: 6}}/>
                                        <CardContent sx={{maxWidth:500}}>
                                             <Controller name={`motherboards.${i}.vendor`} control={control} rules={{ required: false }} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label={`Motherboard ${i} Vendor`} variant="standard" /> } />
                                             <Controller name={`motherboards.${i}.product`} control={control} rules={{ required: false }} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label={`Motherboard ${i} Model`} variant="standard" />} />
                                             <Controller name={`motherboards.${i}.serial`} control={control} rules={{ required: false }} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label={`Motherboard ${i} Serial`} variant="standard" />} />
                                             <Controller name={`motherboards.${i}.description`} control={control} rules={{ required: false }} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label={`Motherboard ${i} Description`} variant="standard" />} />
                                        </CardContent>
                                        <CardActions>
                                             {!readOnly && (<button type="button" onClick={() => removeMotherboardFields(i)}>Remove Motherbaord</button>)}
                                        </CardActions>
                                   </Card>
                              )
                         })}
                         </Grid>
                    </CardContent>
                    <CardActions>
                         {!readOnly && (<button
                              type="button"
                              onClick={() =>
                                   appendMotherboardFields({
                                        vendor: "",
                                        product: "",
                                        serial: "",
                                        description: ""
                                   })
                              }
                         >
                              Add Motherboard
                         </button>)}
                    </CardActions>
               </Card>
               <Card sx={{my: 2}}>
                    <CardHeader title="RAM Information" sx={{fontSize: 10}}/>
                    <CardContent>
                         <Grid container columns={{ xs: 1, sm: 2, md: 6, lg: 9, xl: 12 }}>
                         {ramFields.map((ramField, i) => {
                              var diagnostic_color = '';
                              var diagnostic_result = '';
                              invRefForm.diagnostic_results?.forEach(result => {
                                   if(result.name === 'Memory'){
                                        if(result.result === 'PASS'){
                                             diagnostic_color = 'green'
                                             diagnostic_result = result.result
                                        }else if(result.result === 'FAIL'){
                                             diagnostic_color = 'red'
                                             diagnostic_result = result.result
                                        }else{
                                             diagnostic_color = 'orange'
                                             diagnostic_result = 'N/A'
                                        }
                                   }
                              })
                              return (
                                   <Card sx={{mx: 2, my: 2}}>
                                        <CardHeader title={`RAM ${i} Specs`} subheader={`Diagnostic Results: ${diagnostic_result}`} subheaderTypographyProps={{ color: diagnostic_color }} sx={{fontSize: 6}}/>
                                        <CardContent sx={{ maxWidth:800 }}>
                                             <Controller name={`memory.${i}.vendor`} control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label={`RAM ${i} Vendor`} variant="standard" />} />
                                             <Controller name={`memory.${i}.model`} control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label={`RAM ${i} Model`} variant="standard" />} />
                                             <Controller name={`memory.${i}.type`} control={control} rules={{ required: true }} render={({ field }) => 
                                                  <NativeSelect {...field} disabled={readOnly} defaultValue={field} label={`RAM ${i} Type`} variant="standard" >
                                                  {invRefMem.map((invmem, i) => {
                                                       return(
                                                            <option value={invmem.value}>{invmem.label}</option>
                                                       )
                                                  })}
                                             </NativeSelect> }/>
                                             <Controller name={`memory.${i}.slot`} control={control} rules={{ required: false }} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label={`RAM ${i} Slot`} variant="standard" />} />
                                             <Controller name={`memory.${i}.speed`} control={control} rules={{ required: false }} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label={`RAM ${i} Speed`} variant="standard" />} />
                                             <Controller name={`memory.${i}.size`} control={control} rules={{ required: true }} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label={`RAM ${i} size`} variant="standard" />} />
                                             <Controller name={`memory.${i}.serial`} control={control} rules={{ required: false }} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label={`RAM ${i} Serial`} variant="standard" />} />
                                             <Controller name={`memory.${i}.description`} control={control} rules={{ required: false }} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label={`RAM ${i} Description`} variant="standard" />} />
                                             <CardActions>
                                             {!readOnly && (<button type="button" onClick={() => removeRamFields(i)}>Remove RAM</button>)}
                                             </CardActions>
                                        </CardContent>
                                   </Card>
                              )
                    })}</Grid>
                    </CardContent>
                    <CardActions>
                         {!readOnly && (<button
                              type="button"
                              onClick={() =>
                                   appendRamFields({
                                        vendor: "",
                                        model: "",
                                        type: "",
                                        slot: "",
                                        speed: "",
                                        size: "",
                                        serial: "",
                                        description: ""
                                   })
                              }
                         >
                              Add RAM
                         </button>)}
                    </CardActions>
               </Card>
               <Card sx={{my: 2}}>
                    <CardHeader title="Storage Information" sx={{fontSize: 10}}/>
                    <CardContent>
                         <Grid container columns={{ xs: 1, sm: 2, md: 6, lg: 9, xl: 12 }}>
                         {storageFields.map((storageField, i) => {
                              var diagnostic_color = '';
                              var diagnostic_result = '';
                              invRefForm.diagnostic_results?.forEach(result => {
                                   if(result.name === 'Disk'){
                                        if(result.result === 'PASS'){
                                             diagnostic_color = 'green'
                                             diagnostic_result = result.result
                                        }else if(result.result === 'FAIL'){
                                             diagnostic_color = 'red'
                                             diagnostic_result = result.result
                                        }else{
                                             diagnostic_color = 'orange'
                                             diagnostic_result = 'N/A'
                                        }
                                   }
                              })
                              return (
                                   <Card sx={{mx: 2, my: 2}}>
                                        <CardHeader title={`Storage ${i} Specs`} subheader={`Diagnostic Results: ${diagnostic_result}`} subheaderTypographyProps={{ color: diagnostic_color }} sx={{fontSize: 6}}/>
                                        <CardContent sx={{maxWidth: 800}} >
                                             <Controller name={`device_storage.${i}.disk.vendor`} control={control} rules={{ required: false }} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label={`Storage ${i} Make`} variant="standard" /> } />
                                             <Controller name={`device_storage.${i}.disk.model`} control={control} rules={{ required: false }} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label={`Storage ${i} Model`} variant="standard" /> } />
                                             <Controller name={`device_storage.${i}.disk.type`} control={control} render={({ field }) =>  
                                                  <NativeSelect {...field} disabled={readOnly} defaultValue={field} label={`RAM ${i} Type`} variant="standard" >
                                                  {invRefStore.map((refstore, i) => {
                                                       return(
                                                            <option value={refstore.value}>{refstore.label}</option>
                                                       )
                                                  })}
                                             </NativeSelect> }/>
                                             <Controller name={`device_storage.${i}.disk.size`} control={control} rules={{ required: false }} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label={`Storage ${i} Formatted Size`} variant="standard" />} />
                                             <Controller name={`device_storage.${i}.disk.serial`} control={control} rules={{ required: true }} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} onKeyDown={handleSerialTab} label={`Storage ${i} Serial`} variant="standard" />} />
                                             <CardActions>
                                             {!readOnly && (<button type="button" onClick={() => removeStorageFields(i)}>Remove Storage</button>)}
                                             </CardActions>
                                        </CardContent>
                                   </Card>
                              )
                         })}
                         </Grid>
                    </CardContent>
                    <CardActions>
                    {!readOnly && (<button
                         type="button"
                         onClick={() =>
                              appendStorageFields({
                                   disk:{
                                        vendor: "",
                                        model: "",
                                        type: "",
                                        size: "",
                                        serial: ""
                                   }
                              })
                         }
                    >
                         Add Storage
                    </button>)}
                    </CardActions>
               </Card>
               {(invRefForm.device_category === 'Laptop' || invRefForm.device_category === 'Netbook' || invRefForm.device_category === 'Notebook' || invRefForm.device_category === '2-in-1' || invRefForm.device_category === 'All-in-One' || invRefForm.device_category === 'Cell Phone' || invRefForm.device_category === 'SmartPhone' || invRefForm.device_category === 'Tablet' || invRefForm.device_category === 'ChromeBook') ?
               <Card sx={{my: 2}}>
                    <CardHeader title="Display Information" sx={{fontSize: 10}}/>
                    <CardContent>
                         <Controller name="display_size" control={control} rules={{ required: false }} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Display Size" variant="standard" />} />
                         <Controller name="webcam.0.description" control={control} render={({ field: { onChange, value } }) => ( <FormControlLabel control={<Switch checked={value} disabled={readOnly} onChange={onChange} />} label="Webcam" /> )} />
                         <Controller name="touch.0.description" control={control} render={({ field: { onChange, value } }) => ( <FormControlLabel control={<Switch checked={value} disabled={readOnly} onChange={onChange} />} label="Touch" /> )} />
                    </CardContent>
               </Card>
               :
               null}
               <Card sx={{my: 2}}>
                    <CardHeader title="GPU Information" sx={{fontSize: 10}}/>
                    <Grid container columns={{ xs: 1, sm: 2, md: 6, lg: 9, xl: 12 }}>
                         {gpuFields.map((gpuField, i) => {
                              return (
                                   <Card sx={{mx: 2, my: 2}}>
                                        <CardHeader title={`Graphics Card ${i} Specs`} sx={{fontSize: 6}}/>
                                        <CardContent>
                                             <Controller name={`gpu.${i}.vendor`} control={control} rules={{ required: false }} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label={`GPU ${i} Make`} variant="standard" /> } />
                                             <Controller name={`gpu.${i}.product`} control={control} rules={{ required: false }} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label={`GPU ${i} Model`} variant="standard" /> } />
                                             <Controller name={`gpu.${i}.size`} control={control} rules={{ required: false }} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label={`GPU ${i} VRAM Size`} variant="standard" />} />
                                             <Controller name={`gpu.${i}.serial`} control={control} rules={{ required: false }} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label={`GPU ${i} Serial`} variant="standard" />} />
                                             {!readOnly && (<button type="button" onClick={() => removeGpuFields(i)}>Remove GPU</button>)}
                                        </CardContent>
                                   </Card>
                              )
                         })}
                    </Grid>
                    <CardActions>
                         {!readOnly && (<button
                              type="button"
                              onClick={() =>
                                   appendGpuFields({
                                        vendor: "",
                                        model: "",
                                        size: "",
                                        serial: ""
                                   })
                              }
                         >
                              Add Graphics Card
                         </button>)}
                    </CardActions>
               </Card>
               {(invRefForm.device_category === 'Laptop' || invRefForm.device_category === 'Netbook' || invRefForm.device_category === 'Notebook' || invRefForm.device_category === '2-in-1' || invRefForm.device_category === 'All-in-One' || invRefForm.device_category === 'Cell Phone' || invRefForm.device_category === 'SmartPhone' || invRefForm.device_category === 'Tablet' || invRefForm.device_category === 'ChromeBook') ?
               <>
               <Grid item>
                    <Card>
                         <CardHeader title="Battery Information" />
                         <CardContent>
                              <Grid container>
                                   {batteryFields.map((batteryField, i) => {
                                        var diagnostic_color = '';
                                        var diagnostic_result = '';
                                        invRefForm.diagnostic_results?.forEach(result => {
                                             if(result.name === 'Battery'){
                                                  if(result.result === 'PASS'){
                                                       diagnostic_color = 'green'
                                                       diagnostic_result = result.result
                                                  }else if(result.result === 'FAIL'){
                                                       diagnostic_color = 'red'
                                                       diagnostic_result = result.result
                                                  }else{
                                                       diagnostic_color = 'orange'
                                                       diagnostic_result = 'N/A'
                                                  }
                                             }
                                        })
                                        return (
                                             <Card>
                                                  <CardHeader title={`Battery ${i} Specs`} subheader={`Diagnostic Results: ${diagnostic_result}`} subheaderTypographyProps={{ color: diagnostic_color }} />
                                                  <CardContent>
                                                       <Grid item>
                                                            <Controller name={`batteries.${i}.capacity`} control={control} rules={{ required: true }} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label={`Battery ${i} Capacity`} variant="standard" />} />
                                                       </Grid>
                                                       <Grid item>
                                                            <Controller name={`batteries.${i}.cycles`} control={control} rules={{ required: true }} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label={`Battery ${i} Cycles`} variant="standard" />} />
                                                       </Grid>
                                                  </CardContent>
                                                  <CardActions>
                                                       {!readOnly && (<button type="button" onClick={() => removeBatteryFields(i)}>Remove Battery</button>)}
                                                  </CardActions>
                                             </Card>
                                        )
                                   })}
                              </Grid>
                         </CardContent>
                         <CardActions>
                              {!readOnly && (<button
                                   type="button"
                                   onClick={() =>
                                        appendBatteryFields({
                                             cycles: "",
                                             capacity: "",
                                             model: "",
                                             health: "",
                                             serial: "",
                                             description: ""
                                        })
                                   }
                              >
                                   Append Battery
                              </button>)}
                         </CardActions>
                    </Card>
               </Grid>
               </>
               :
               null}
               <Card sx={{my: 2}}>
                    {((istats === 'Rejected' && disp === 'Unusable Resale') || (istats === 'Rejected' &&  disp === 'Recycle')) ?
                    <>
                         <CardHeader title="Rejection Notes" />
                         <CardContent>
                              <Controller name="rejection_notes" control={control} rules={{ required: true }} render={({ field }) => 
                              <TextField {...field} error={errors?.rejection_notes} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Rejection Notes" variant="standard" multiline rows={2} fullWidth />} />
                         </CardContent>
                    </>
                    :
                    <>
                    <CardHeader title="Technician Notes" />
                    <CardContent>
                         <Controller name="technician_notes" control={control} rules={{ required: false }} render={({ field }) => 
                         <TextField {...field} error={errors?.technician_notes} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Technician Notes" variant="standard" multiline rows={2} fullWidth />} />
                    </CardContent>
                    </>}
               </Card>
          </form>
          {auth.user?.type === 'employee' &&(<>
          <Box sx={{ position: 'fixed', bottom: { xs: '3dvh', sm: '3dvh', md: '3dvh' }, right: { xs: '5dvw',sm: '3dvw', md: '3dvw' } }}>
               <Tooltip title="Actions">
                    <IconButton
                    onClick={handleClick}
                    size="small"
                    aria-controls={open ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    >
                    <Avatar sx={{ width: 35, height: 35 }}>A</Avatar>
                    </IconButton>
               </Tooltip>
          </Box>
          <Menu
               anchorEl={anchorEl}
               id="account-menu"
               open={open}
               onClose={handleClose}
               PaperProps={{
                    elevation: 0,
                    sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                    },
                    '&::before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    bottom: 0,
                    right: '50%',
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(50%) rotate(45deg)',
                    zIndex: 0,
                    },
               },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          >
               <MenuItem>
                    {readOnly && (<Button onClick={() => editClicked()}>Edit</Button>)}{!readOnly && (<><Button onClick={() => cancelClicked()}>Cancel</Button><Button type="submit" form='invRefForm'>Save</Button></>)}
               </MenuItem>
               {/* <MenuItem>
                    <PrinterList labelType={type} serial={invRefForm.device_serial} job={invRefForm.job_id} />
               </MenuItem> */}
               {type === 'refurbishment' &&
                    <MenuItem>
                         <PDFDownloadLink document={<MyDocument />} fileName={`${invRefForm.device_serial}-${invRefForm.job_id}.pdf`}>{({ blob, url, loading, error }) => loading ? 'Loading document...' : 'Download now!'}</PDFDownloadLink>
                    </MenuItem>
               }
          </Menu>
          </>)}
     </Box>
    )
}

export default InvRefForm