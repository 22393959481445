import { Avatar, Box, TextField, InputLabel,Menu, MenuItem, IconButton, Tooltip, FormControl, FormControlLabel, Select, Switch, NativeSelect, Button, ButtonGroup, Stack, Paper, Card, CardContent, CardHeader, CardActions, Grid } from '@mui/material';
import { useAuth } from "../../auth/AuthWrapper";
import Item from '@mui/material/Stack';
import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate, Link } from "react-router-dom";
import { useForm, useFormState, Controller, useFieldArray } from "react-hook-form";

const Facility = ({ socket, isConnected, ip}) => {
     const drawerWidth = 200;
     const { register, setValue, getValues, handleSubmit, control, reset, resetField, watch, formState: { errors } } = useForm({});
     const [facility, setFacility] = useState({facility_id:"", facility_name:"", facility_type:"", address:"", address2:"", city:"", state:"", zip:""});
     const [readOnly, setReadOnly] = useState(true);
     const [newForm, setNewForm] = useState(false);
     const auth = useAuth();
     const navigate = useNavigate();
     let { facility_id } = useParams();
     const { dirtyFields } = useFormState({ control });
     const onError = (errors, e) => console.log(errors, e);

     const [ anchorEl, setAnchorEl ] = React.useState(null);
     const open = Boolean(anchorEl);
     const handleClick = (event) => {
          setAnchorEl(event.currentTarget);
     };
     const handleClose = () => {
          setAnchorEl(null);
     };

     useEffect(() => {
          if(isConnected){
               if(facility_id === 'add'){
                    setReadOnly(false);
                    setNewForm(false);
               }else{
                    socket.emit("facilities", {"function": "get_one", "data": {"hostname": auth.domain, "ip": auth.ip, "id": facility_id}}, (response) => {
                         // console.log(response)
                         setNewForm(true);
                         setFacility(response)
                    })
               }
          }
     }, [isConnected]);

      useEffect(() => {
          for (const [key, value] of Object.entries(facility)) {
              setValue(key, value)
            }
      }, [facility])
  
      // For the button actions down below.
      const editClicked = () => {
        setReadOnly(false);
        if(facility_id === 'add'){
             setNewForm(false);
        }
      };
    
      const cancelClicked = () => {
        setReadOnly(true);
        reset(facility);
        if(facility_id === 'add'){
          setNewForm(true);
          navigate('/facilities')
        }
      };
  
      // This is for submitting the form after its been filled out.
     //  const onSubmit = async (data, e) => {
     //      if(isConnected){
     //           socket.emit("facilities", {"function": "save_one", "data": {"hostname": auth.domain, "ip": "0.0.0.0", "id": facility_id, "form_data": data}}, (response) => {
     //                console.log(response)
     //                // setFacility(response)
     //           })
     //      }
          // const requestOptions = {
          //      method: 'POST',
          //      headers: { 'Content-Type': 'application/json', 'authorization': 'bearer ' + token },
          //      body: JSON.stringify(data)
          //  };
          // await fetch('/api/sams/add_facility', requestOptions).then(async response => {
          //      console.log('sending...')
          //      console.log(response)
          //      if(response.ok === false){
          //           const error = response.statusText;
          //           console.log('error')
          //           console.log(error);
          //           throw Error(error);
          //      }
          //      const resp = await response.json();
          //      console.log('test')
          //      console.log(resp)
          //      setFacility(resp)
          // }).catch(error => {
          //      console.log(error)
          // });
     // }

     const onSubmit = async (data, e) => {
          e.preventDefault();
          if(isConnected){
               socket.emit('facilities', {"function": "save", "data": {"hostname": auth.domain, "ip": auth.ip, "id": facility_id, "payload": data, "user": auth.user}}, (response) => {
                    console.log(response)
                    if(response != 'error'){
                         setFacility(response)
                         setReadOnly(true);
                         setNewForm(true);
                         navigate("/facility/" + response.facility_id);
                    }
               })
            }
        }
     watch(errors)
     return (
          <>
          <Box component="main" sx={{
               flexGrow: 1, p: 3, ml: {xl: 25, lg: 25, md: 25, sm: 25, xs: 0}, width: { md: `calc(100% - ${drawerWidth}px)`, sm: `calc(100% - ${drawerWidth}px)`, xs: '100%' 
          }}}>
               <form onSubmit={handleSubmit(onSubmit)} id='facilityForm'>
               <Card sx={{textAlign: 'center'}}>
               <CardHeader title="Facility Info"/>
                    <Card>
                         <CardHeader title="Facility "/>
                         <CardContent>
                              <Controller name="facility_id" control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: newForm }} label="Facility ID" variant="standard" />} />
                              <Controller name="facility_name" control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Name of Facility" variant="standard" />} />
                              <Controller name="facility_type" control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Type of Facility (Partner, Govt, etc.)" variant="standard" />} />
                              <Controller name="address" control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Facility Main Street Address" variant="standard" />} />
                              <Controller name="address2" control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Facility Secondary Address (Unit, Suite, Bldg, etc.)" variant="standard" />} />
                              <Controller name="city" control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="City" variant="standard" />} />
                              <Controller name="state" control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="State" variant="standard" />} />
                              <Controller name="zip" control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Zip Code" variant="standard" />} />
                         </CardContent>
                    </Card>
               </Card>
               </form>
          </Box>
          {auth.user?.type === 'employee' &&(<>
               <Box sx={{ position: 'fixed', bottom: { xs: '3dvh', sm: '3dvh', md: '3dvh' }, right: { xs: '5dvw',sm: '3dvw', md: '3dvw' } }}>
                   <Tooltip title="Actions">
                       <IconButton
                       onClick={handleClick}
                       size="small"
                       aria-controls={open ? 'account-menu' : undefined}
                       aria-haspopup="true"
                       aria-expanded={open ? 'true' : undefined}
                       >
                       <Avatar sx={{ width: 35, height: 35 }}>A</Avatar>
                       </IconButton>
                   </Tooltip>
               </Box>
               <Menu
                   anchorEl={anchorEl}
                   id="account-menu"
                   open={open}
                   onClose={handleClose}
                   PaperProps={{
                       elevation: 0,
                       sx: {
                       overflow: 'visible',
                       filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                       mt: 1.5,
                       '& .MuiAvatar-root': {
                       width: 32,
                       height: 32,
                       ml: -0.5,
                       mr: 1,
                       },
                       '&::before': {
                       content: '""',
                       display: 'block',
                       position: 'absolute',
                       bottom: 0,
                       right: '50%',
                       width: 10,
                       height: 10,
                       bgcolor: 'background.paper',
                       transform: 'translateY(50%) rotate(45deg)',
                       zIndex: 0,
                       },
                   },
               }}
               transformOrigin={{ horizontal: 'right', vertical: 'bottom' }}
               anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
               >
                   <MenuItem>
                       {readOnly && (<Button onClick={() => editClicked()}>Edit</Button>)}{!readOnly && (<><Button onClick={() => cancelClicked()}>Cancel</Button><Button type="submit" form='facilityForm'>Save</Button></>)}
                   </MenuItem>
               </Menu>
          </>)}
     </>
     )
}

export default Facility;