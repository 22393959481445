import { Grid, Box, Paper, Button, ButtonGroup } from '@mui/material';
import Item from '@mui/material/Stack';
import { experimentalStyled as styled } from '@mui/material/styles';
import { useAuth } from "../../auth/AuthWrapper";
import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";

const GridTitleItem = styled(Item)(({ theme }) => ({
     textAlign: 'center',
     color: 'green',
     fontWeight: 'bold',
     fontSize: '18px',
   }));

const GridItem = styled(Paper)(({ theme }) => ({
     backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
     ...theme.typography.body2,
     padding: theme.spacing(2),
     textAlign: 'left',
     color: theme.palette.text.secondary,
   }));

const Facilities = ({ socket, isConnected }) => {
    const drawerWidth = 200;
    const auth = useAuth();
    const [facilities, setFacilities] = useState([]);

     useEffect(() => {
          if(isConnected){
               socket.emit("facilities", {"function":"get_all", "data": {"hostname": auth.domain, "ip": "0.0.0.0"}}, (response) => {
                    setFacilities(response)
               })
          }
     }, [isConnected]);

    return (
     <>
        <Box component="main" sx={{ 
             flexGrow: 1, mt: {xs: 1, sm: 2}, p:{xs: 1, md:2}, ml: {sm: 25, xs: 0}, width: { md: `calc(100% - ${drawerWidth}px)`, sm: `calc(100% - ${drawerWidth}px)`, xs: '100%' 
        }}}>
               <center>
                    <ButtonGroup variant="contained" color="success" size="small" aria-label="outlined primary button group">
                         <Button component={Link} to={'/facility/add'}>Add New Facility</Button>
                    </ButtonGroup>
               </center>
          </Box>
          <Box component="main" sx={{ 
               flexGrow: 1, p:{xs: 1, md: 2}, ml: {sm: 25, xs: 0}, width: { md: `calc(100% - ${drawerWidth}px)`, sm: `calc(100% - ${drawerWidth}px)`, xs: '100%' 
          }}}>
          <Grid
               container
               spacing={{ xs: 1, md: 2 }}
               columns={{ xs: 1, sm: 1, md: 6, lg: 9, xl: 12 }}
          >
        {facilities.map((facility, index) => (
          <Grid item xs={1} sm={1} md={3} key={index}>
            <GridItem sx={{}}>
               <Grid 
                    container
                    spacing={{ xs: 1, md: 2 }}
                    columns={{ xs: 2, sm: 4, md: 6}}
               >
                    <Grid item xs={2} sm={4} md={6} key={index}>
                         <GridTitleItem sx={{}}>
                              {facility.facility_name}
                         </GridTitleItem>
                    </Grid>
                    <Grid item xs={2} sm={4} md={6} key={index}>
                         <Item sx={{}}>
                              <b>Hours, Location & Contact Info</b>
                         </Item>
                    </Grid>
                    <Grid item xs={1} sm={2} md={3} key={index}>
                         <Item sx={{}}>
                              <div>{facility.address}</div>
                              <div>{facility.address2}</div>
                              <div>{facility.city} {facility.state}, {facility.zip}</div>
                         </Item>
                    </Grid>
                    <Grid item xs={1} sm={2} md={3} key={index}>
                         <Item sx={{}}>
                              <div>TZ: {facility.timezone}</div>
                              <div>{facility.open_time} - {facility.close_time}</div>
                              <div>{facility.phone_number}</div>
                              <div>{facility.email}</div>
                         </Item>
                    </Grid>
                    <Grid item xs={2} sm={4} md={6} key={index}>
                         <Item sx={{}}>
                              <b>Certifications</b>
                         </Item>
                    </Grid>
                    <Grid item xs={2} sm={4} md={6} key={index}>
                         <Item sx={{}}>
                              <div></div>
                         </Item>
                    </Grid>
                    <Grid item xs={2} sm={4} md={6} key={index}>
                         <Item sx={{}}>
                              <b>Capabilities</b>
                         </Item>
                    </Grid>
                    <Grid item xs={2} sm={4} md={6} key={index}>
                         <Item sx={{}}>
                              <div>Pickups, Dropoffs, Events, Onsite Data Destruction, Repairs, Consulting</div>
                         </Item>
                    </Grid>
                    <Grid item xs={2} sm={4} md={6} key={index}>
                         <Item sx={{}}>
                              <b>DOES NOT ACCEPT</b>
                         </Item>
                    </Grid>
                    <Grid item xs={2} sm={4} md={6} key={index}>
                         <Item sx={{}}>
                              <div>Batteries, bulbs, refrigerants, liquids, aerosols, paints, oils, etc... </div>
                         </Item>
                    </Grid>
                    <Grid item xs={2} sm={4} md={6} key={index}>
                         <Item sx={{}}>
                              <center>
                                   <ButtonGroup variant="contained" color="success" size="small" aria-label="outlined primary button group">
                                        <Button component={Link} to={'/facility/' + facility.facility_id}>View</Button>
                                   </ButtonGroup>
                              </center>
                         </Item>
                    </Grid>
               </Grid>
            </GridItem>
          </Grid>
        ))}
        </Grid>
         </Box>
     </>
    )
}

export default Facilities;