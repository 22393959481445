// import { PrinterList } from "../PrinterList";
import { Avatar, Box, TextField, InputLabel,Menu, MenuItem, IconButton, Tooltip, FormControl, FormControlLabel, Select, Switch, NativeSelect, Button, ButtonGroup, Stack, Paper, Card, CardContent, CardHeader, CardActions, Grid } from '@mui/material';
import { useAuth } from "../../auth/AuthWrapper";
import Item from '@mui/material/Stack';
import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { useForm, useFormState, Controller, useFieldArray } from "react-hook-form";

const Weight = ({ socket, isConnected }) => {
    const drawerWidth = 200;
    const auth = useAuth();
    const navigate = useNavigate();
    let { weight_id } = useParams();
    let { job_id } = useParams();
    const [job, setJob] = useState('');
    const [readOnly, setReadOnly] = useState(true);
    const [weight, setWeight] = useState([]);
    const [weightId, setWeightId] = useState('');
    const [newTempId, setNewTempId] = useState(Date.now().toString(36))
    const { register, setValue, getValues, handleSubmit, control, reset, watch, errors } = useForm({ defaultValues: weight });

    const [ anchorEl, setAnchorEl ] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
         setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
         setAnchorEl(null);
    };

    const editClicked = () => {
        setReadOnly(false);
    };
    
    const cancelClicked = () => {
        setReadOnly(true);
        reset(weight);
        if(weight_id === 'add'){
            navigate('/job/' + job_id)
        }
    };

    useEffect(() => {
        if(isConnected){
            socket.emit("jobs", {"function": "get_one", "data": {"hostname": auth.domain, "ip": auth.ip, "id": job_id, "user": auth.user}}, (response) => {
                    // console.log(response)
                    setJob(response)
            })
            if(weight_id === 'add'){
                console.log('Add!')
                setWeightId(job_id + newTempId)
                console.log(job_id + '-' + newTempId)
                setValue('weight_id', job_id + newTempId)
                setValue('job_id', job_id)
                setValue('name', '')
                setValue('notes', '')
                setValue('rec', '')
                setValue('status', '')
                setValue('zone', '')
                setValue("weight", {
                    "bulb_count": "0",
                    "crt_weight": "0",
                    "bulb_weight": "0",
                    "qtine_count": "0",
                    "crt_32_count": "0",
                    "crt_33_count": "0",
                    "lcd_tv_count": "0",
                    "metal_weight": "0",
                    "qtine_weight": "0",
                    "trash_weight": "0",
                    "battery_count": "0",
                    "lcd_tv_weight": "0",
                    "printer_count": "0",
                    "battery_weight": "0",
                    "detector_count": "0",
                    "other_tv_count": "0",
                    "printer_weight": "0",
                    "computing_count": "0",
                    "consumer_weight": "0",
                    "detector_weight": "0",
                    "other_tv_weight": "0",
                    "computing_weight": "0",
                    "crt_monitor_count": "0",
                    "lcd_monitor_count": "0",
                    "lcd_monitor_weight": "0"
                })
                setReadOnly(false);
            }else{
                socket.emit("weights", {"function": "get_one", "data": {"hostname": auth.domain, "ip": auth.ip, "id": weight_id, "job": job_id, "user": auth.user}}, (response) => {
                    if(response != 'error'){
                        console.log(response)
                        setWeight(response)
                    }
                })
            }
        }
   }, [isConnected]);

    useEffect(() => {
        for (const [key, value] of Object.entries(weight)) {
            setValue(key, value)
        }
    }, [weight]);
  

    const onSubmit = async (data, e) => {
        e.preventDefault();
        if(isConnected){
            socket.emit('weights', {"function": "save", "data": {"hostname": auth.domain, "ip": auth.ip, "id": weight_id, "job": job_id, "payload": data, "user": auth.user}}, (response) => {
                if(response != 'error'){
                    setWeight(response)
                    setReadOnly(true);
                    navigate("/weight/" + response.job_id + "/" + response.weight_id);
                }
            })
        }
   }
   watch(errors)
    return (
        <>
            <Box component="main" sx={{ flexGrow: 1, p:{xs: 1, md: 2}, ml: {sm: 25, xs: 0}, width: { md: `calc(100% - ${drawerWidth}px)`, sm: `calc(100% - ${drawerWidth}px)`, xs: '100%' }}}>
                <form onSubmit={handleSubmit(onSubmit)} id='weightForm'> 
                    <Card sx={{textAlign: 'center'}}>
                    <CardHeader title={`Weight For Job: ${job_id}`}/>
                        <CardContent>
                            <Controller name="weight_id" control={control} rules={{ required: true }} render={({ field }) => <TextField {...field} error={errors?.weight_id} defaultValue={field} InputProps={{ readOnly: true }} label="Weight ID" variant="standard" />} />
                            <Controller name="job_id" control={control} rules={{ required: true }} render={({ field }) => <TextField {...field} error={errors?.job_id} defaultValue={field} InputProps={{ readOnly: true }} label="Job ID" variant="standard" />} />
                            <Controller name="name" control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Weight Name" variant="standard" />} />
                            <Controller name="weight.metal_weight" control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Metal Weight" variant="standard" />} />
                            <Controller name="weight.trash_weight" control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Trash Weight" variant="standard" />} />
                            <Controller name="weight.consumer_weight" control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Consumer Weight" variant="standard" />} />
                            <Controller name="weight.computing_weight" control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Computing Weight" variant="standard" />} />
                            <Controller name="weight.computing_count" control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Computing Count" variant="standard" />} />
                            <Controller name="weight.printer_weight" control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Loose HDD Weight" variant="standard" />} />
                            <Controller name="weight.printer_count" control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Loose HDD Count" variant="standard" />} />
                            <Controller name="weight.lcd_tv_weight" control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="LCD TV Weight" variant="standard" />} />
                            <Controller name="weight.lcd_tv_count" control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="LCD TV Count" variant="standard" />} />
                            <Controller name="weight.lcd_monitor_weight" control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="LCD Monitor Weight" variant="standard" />} />
                            <Controller name="weight.lcd_monitor_count" control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="LCD Monitor Count" variant="standard" />} />
                            <Controller name="weight.crt_weight" control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="CRT Weight" variant="standard" />} />
                            <Controller name="weight.crt_32_count" control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label='CRT 32" or Under Count' variant="standard" />} />
                            <Controller name="weight.crt_33_count" control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label='CRT 33" or Over Count' variant="standard" />} />
                            <Controller name="weight.crt_monitor_count" control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="CRT Monitor Count" variant="standard" />} />
                            <Controller name="weight.other_tv_weight" control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Other TV Weight" variant="standard" />} />
                            <Controller name="weight.other_tv_count" control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Other TV Count" variant="standard" />} />
                            <Controller name="weight.bulb_weight" control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Bulb Weight" variant="standard" />} />
                            <Controller name="weight.bulb_count" control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Bulb Count" variant="standard" />} />
                            <Controller name="weight.battery_weight" control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Battery Weight" variant="standard" />} />
                            <Controller name="weight.battery_count" control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Battery Count" variant="standard" />} />
                            <Controller name="weight.detector_weight" control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Detector Weight" variant="standard" />} />
                            <Controller name="weight.detector_count" control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Detector Count" variant="standard" />} />
                            <Controller name="weight.qtine_weight" control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Quarantine Weight" variant="standard" />} />
                            <Controller name="weight.qtine_count" control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Quarantine Count" variant="standard" />} />
                            <Controller name="notes" control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Notes" variant="standard" />} />
                            <Controller name="rec" control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="REC" variant="standard" />} />
                            <Controller name="status" control={control} rules={{ required: false }} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Status" variant="standard" />} />
                            <Controller name="zone" control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Zone" variant="standard" />} />
                        </CardContent>
                    </Card>
                </form>
            </Box>
            {auth.user?.type === 'employee' &&(<>
            <Box sx={{ position: 'fixed', bottom: { xs: '3dvh', sm: '3dvh', md: '3dvh' }, right: { xs: '5dvw',sm: '3dvw', md: '3dvw' } }}>
                <Tooltip title="Actions">
                    <IconButton
                    onClick={handleClick}
                    size="small"
                    aria-controls={open ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    >
                    <Avatar sx={{ width: 35, height: 35 }}>A</Avatar>
                    </IconButton>
                </Tooltip>
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                    },
                    '&::before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    bottom: 0,
                    right: '50%',
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(50%) rotate(45deg)',
                    zIndex: 0,
                    },
                },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
            >
                <MenuItem>
                    {readOnly && (<Button onClick={() => editClicked()}>Edit</Button>)}{!readOnly && (<><Button onClick={() => cancelClicked()}>Cancel</Button><Button type="submit" form='weightForm'>Save</Button></>)}
                </MenuItem>
                {/* <MenuItem>
                    <PrinterList labelType={props.type} job={client.job_id} />
                </MenuItem> */}
            </Menu>
            </>)}
        </>
    )
}

export default Weight;