import React from "react";
import { createContext, useContext, useState, useEffect } from "react"
import { useNavigate, useLocation } from "react-router-dom";
import "core-js/stable/atob";
import { jwtDecode } from "jwt-decode";
import { socket } from '../components/socket';


const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const [token, setToken] = useState(localStorage.getItem("SAMS")); // || "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJTdGF0aWNJcCBJVCBEZXYiLCJpYXQiOjE3MTY0OTUzNzgsImV4cCI6MTcxNjQ5NTM3OCwiYXVkIjoic3RhdGljaXAuaXQiLCJzdWIiOiIiLCJpc0F1dGhlbnRpY2F0ZWQiOiJmYWxzZSJ9.rl-0djeShMVPOLWRut3y2fquW_Wn6FfVrQC3opu8-bM"
    const [user, setUser] = useState(jwtDecode(localStorage.getItem("SAMS") || "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJTdGF0aWNJcCBJVCBEZXYiLCJpYXQiOjE3MTY0OTUzNzgsImV4cCI6MTcxNjQ5NTM3OCwiYXVkIjoic3RhdGljaXAuaXQiLCJzdWIiOiIiLCJpc0F1dGhlbnRpY2F0ZWQiOiJmYWxzZSJ9.rl-0djeShMVPOLWRut3y2fquW_Wn6FfVrQC3opu8-bM")); // 
    const [ip, setIp] = useState("0.0.0.0");
    const navigate = useNavigate();
    const [subDomain, setSubDomain] = useState('null');
    const [domain, setDomain] = useState('null');
    const host = window.location.host;
    const loginAction = async (data) => {
       try {
         const response = await fetch("/login", {
           method: "POST",
           headers: {
             "Content-Type": "application/json",
           },
           body: JSON.stringify(data),
         });
         const res = await response.json();
         var decoded = jwtDecode(res);
         if(decoded.exp * 1000 < Date.now()){
             setUser({name: "", type: "", id:"", client_id: "", calendar_key: "", isAuthenticated: false})
             setToken('');
             localStorage.removeItem("SAMS");
             navigate("/login")
        }else{
          if (decoded.isAuthenticated) {
            setUser(decoded);
            setToken(res);
            localStorage.setItem("SAMS", res);
            socket.connect();
            navigate("/dashboard");
            return;
          }
        }
         throw new Error(res.message);
       } catch (err) {
         console.error(err);
       }
     };

    //  useEffect(() => {
    //   console.log(domain)
    //   console.log(subDomain)
    //  }, [domain])

     useEffect(() =>{
      const arr = host.split(".");
        if (arr.length <= 2){
          setDomain(arr[0]);
          setSubDomain(arr[0]);
        }else if(arr.length > 2){
          setDomain(arr[1])
          setSubDomain(arr[0]);
        }
      if(localStorage.getItem("SAMS")){
        var decoded = jwtDecode(localStorage.getItem("SAMS"));
        if(decoded.exp * 1000 < Date.now()){
            setUser({name: "", type: "", id:"", client_id: "", isAuthenticated: false})
            setToken('');
            localStorage.removeItem("SAMS");
            navigate("/login")
       }else{
         if (decoded.isAuthenticated) {
           setUser(decoded);
           setToken(localStorage.getItem("SAMS"));
           socket.connect();
           return;
         }
       }
      }
     },[])
   
     const logOut = () => {
       setUser(null);
       setToken("");
       localStorage.removeItem("SAMS");
       socket.disconnect();
       navigate("/login");
     };


   
     return (
       <AuthContext.Provider value={{ token, user, loginAction, logOut, domain, subDomain, ip }}>
         {children}
       </AuthContext.Provider>
     );
   
   };

   export default AuthProvider;
   
   export const useAuth = () => {
     return useContext(AuthContext);
   };


// const AuthContext = createContext();
// export const AuthData = () => useContext(AuthContext);
// export const AuthWrapper = () => {
//      const [subdomain, setSubDomain] = useState('null');
//      const [ user, setUser ] = useState({name: "", type: "", id:"", client_id: "", isAuthenticated: false});
//      const [ token, setToken ] = useState('');
//      const navigate = useNavigate();
//      var requestedPath = useLocation().pathname;
//      var decoded = {}
// 	useEffect(() => {
// 		const host = window.location.host;
// 		const arr = host
// 			.split(".")
// 			.slice(0, host.includes("localhost") ? -1 : -2);
// 		if (arr.length > 0){
//                setSubDomain(arr[0]);
//           }else{setSubDomain('null')}}, []
//      );
//      useEffect(() => {
//           var cookie = Cookies.get('token');
//           if(!cookie){
//                cookie = {name: "", type: "", id:"", client_id: "", isAuthenticated: false}
//                setUser(cookie);
//                console.log('No token, setting defaults for cookie...')
//                navigate("/login", {state: {requestedPath: requestedPath}})
//           }else{
//                console.log('Token found, setting info for cookie...');
//                decoded = jwtDecode(cookie);
//                if(decoded.exp * 1000 < Date.now()){
//                     setUser({name: "", type: "", id:"", client_id: "", isAuthenticated: false})
//                     setToken('')
//                     Cookies.remove('token')
//                     navigate("/login", {state: {requestedPath: '/dashboard'}})
//                }else{
//                     if(decoded.isAuthenticated){
//                          setUser(decoded);
//                          setToken(cookie);
//                          if(requestedPath === '/login' || requestedPath === '/'){
//                               console.log('sending to /dashboard from /login...')
//                               navigate("/dashboard", {state: {requestedPath: requestedPath}})
//                          }else{
//                               console.log('sending to ' + requestedPath + '...')
//                               navigate(requestedPath, {state: {requestedPath: requestedPath}})
//                          }
//                     }else{
//                          console.log('sending to /login because not authenticated...')
//                          navigate("/login", {state: {requestedPath: requestedPath}})
//                     }
//                }
//           }
//      },[]);

//      async function login(userName, password){
//           const requestOptions = {
//                method: 'POST',
//                headers: { 'Content-Type': 'application/json' },
//                body: JSON.stringify({username: userName, password: password})
//            };
//           await fetch('/login', requestOptions).then(async response => {
//                const data = await response.json();
//                if(response.ok === false){
//                     const error = data.statusText;
//                     console.log(error);
//                     throw Error(error);
//                }
//                Cookies.set('token', data, { expires: 1, secure: true });
//                const decoded = jwtDecode(data);
//                setUser(decoded)
//                setToken(data);
//                return response.json();
//           }).catch(error => {
//                return error;
//           });
//      }

//      const logout = () => {
//           setUser({name: "", type: "", id:"", client_id: "", isAuthenticated: false})
//           setToken('')
//           Cookies.remove('token')
//           socket.disconnect()
//           navigate("/login", {state: {requestedPath: '/dashboard'}})
//      }

//      return (
//           <AuthContext.Provider value={{user, login, logout, subdomain, token}}>
//                <RenderMenu />
//                <RenderRoutes />
//           </AuthContext.Provider>
//      )
// }