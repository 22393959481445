import { Box, Button, ButtonGroup, Paper, TextField, Grid} from '@mui/material';
import { useAuth } from "../../auth/AuthWrapper";
import Item from '@mui/material/Stack';
import { experimentalStyled as styled } from '@mui/material/styles';
import React, { useState, useEffect, useCallback } from 'react';
import { Link } from "react-router-dom";

const BasicPaper = styled(Paper)(({ theme }) => ({
     padding: theme.spacing(2),
     ...theme.typography.body2,
     textAlign: 'center',
     }));

const Inventory = ({ socket, isConnected }) => {
     const drawerWidth = 200;
     const auth = useAuth();
     const [ formValue, setFormValue ] = useState('')
     const [searchValue, setSearchValue] = useState([])
     
     function get_info(serial_id){
          if(isConnected){
                   socket.emit("inventory", {"function": "get_one", "data": {"hostname": auth.domain, "ip": "0.0.0.0", "id": serial_id, "user": auth.user}}, (response) => {
                        console.log(response)
                        setSearchValue(response)
                   })
            }
     }

          return (
               <Box component="main" sx={{
                    flexGrow: 1, p: 3, ml: {xl: 25, lg: 25, md: 25, sm: 25, xs: 0}, width: { md: `calc(100% - ${drawerWidth}px)`, sm: `calc(100% - ${drawerWidth}px)`, xs: '100%' 
               }}}>
                    <BasicPaper> 
                         <h2>Inventory</h2>
                         <TextField id="serial_search" label="Serial Number Search" variant="standard" defaultValue={formValue} onChange={(e) => setFormValue(e.target.value )} />
                              <Button onClick={() => get_info(formValue)}>
                              Enter
                              </Button>
                    </BasicPaper> 

                    {searchValue.device_serial && (
                         <BasicPaper>
                         <Grid container spacing={{ sm: 1, md: 2 }} columns={{ xs: 2, sm: 2, md: 8, lg: 8}}>
                              <Grid item xs={2} sm={2} md={8} key={'result-header-' + 0}>
                                   <h2><b><u>Search Result</u></b></h2>
                              </Grid>
                              <Grid item xs={2} sm={2} md={8} key={'result-' + 0}>
                                   <p>{searchValue.device_vendor} {searchValue.device_name} {searchValue.device_serial} {searchValue.device_class}</p>                                 
                                   <ButtonGroup variant="contained" color="success" size="small" aria-label="outlined primary button group">
                                        <Button component={Link} to={'/refurbishment/item/' + searchValue.device_serial}>View Tech Form</Button>
                                        <Button component={Link} to={'/inventory/item/' + searchValue.device_serial}>View Inventory Form</Button>
                                        <Button>Clear</Button>
                                   </ButtonGroup>
                              </Grid>
                         </Grid>
                    </BasicPaper>)}
               </Box>
       
          )
     
}

export default Inventory;