import Box from '@mui/material/Box';
import { useAuth } from "../../auth/AuthWrapper";
import Typography from '@mui/material/Typography';

import React, { useEffect } from "react";

const Dashboard = () => {
  const auth = useAuth();
  const [drawerWidth, setDrawerWidth] = React.useState('200');

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3, ml: {xl: 25, lg: 25, md: 25, sm: 25, xs: 0}, width: { md: `calc(100% - ${drawerWidth}px)`, sm: `calc(100% - ${drawerWidth}px)`, xs: '100%' }}} >
      <h1>Welcome {auth.user?.name}!</h1>
      <h3>This page will eventually have stats about the services we have performed for you</h3>
      <Typography style={{ fontFamily: "Oxanium-Bold" }} >
          Designed By StaticIp.IT
        </Typography>
    </Box>
  );
};

export default Dashboard;