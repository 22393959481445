// import { PrinterList } from "../PrinterList";
import { Avatar, Box, TextField, InputLabel,Menu, MenuItem, IconButton, Tooltip, FormControl, FormControlLabel, Select, Switch, NativeSelect, Button, ButtonGroup, Stack, Paper, Card, CardContent, CardHeader, CardActions, Grid } from '@mui/material';
import { useAuth } from "../../auth/AuthWrapper";
import Item from '@mui/material/Stack';
import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { useForm, useFormState, Controller, useFieldArray } from "react-hook-form";

const Location = ({ socket, isConnected }) => {
    const drawerWidth = 200;
    const auth = useAuth();
    const navigate = useNavigate();
    let { client_id } = useParams();
    let { location_id } = useParams();
    const [newForm, setNewForm] = useState(false);
    const [client, setClient] = useState({});
    const [clientId, setClientId] = useState('');
    const [readOnly, setReadOnly] = useState(true);
    const [location, setLocation] = useState({location_id: "", location_name: "", location_type: "", address: "", address2: "", city: "", state: "", zip: "", timezone: "", opens_at: "", closes_at: "", location_status: "", account_rep: "", contract_details: {data_handling: "", ewaste_handling: ""}, public_notes: "", primary_poc_name: "", primary_poc_number: "", primary_poc_email: "", primary_poc_type: "", primary_poc_notes: "", secondary_poc_name: "", secondary_poc_number: "", secondary_poc_email: "", secondary_poc_type: "", secondary_poc_notes: "", backup_poc_name: "", backup_poc_number: "", backup_poc_email: "", backup_poc_type: "", backup_poc_notes: ""});
    const [locationId, setLocationId] = useState('');
    const { register, setValue, getValues, handleSubmit, control, reset, watch, errors } = useForm({ defaultValues: location });

    const [ anchorEl, setAnchorEl ] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
         setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
         setAnchorEl(null);
    };

    const editClicked = () => {
        setReadOnly(false);
    };
    
    const saveClicked = () => {
        setReadOnly(true);
    };
    
    const cancelClicked = () => {
        setReadOnly(true);
        reset(location);
        if(location_id === 'add'){
            navigate('/client/' + client_id)
        }
    };

    // useEffect(() =>{
    //     setClientId(client_id)
        // console.log('set client id')
    //     if(location_id === 'add'){
    //         console.log('Add!')
    //         setReadOnly(false);
    //         fetch('/api/sams/get_client/' + client_id, { headers: headers })
    //             .then((response) => response.json())
    //             .then((data) => {setClient(data)});
    //         setReadOnly(false);
    //     }else{
    //         fetch('/api/sams/get_location/' + location_id, { headers: headers })
    //             .then((response) => response.json())
    //             .then((data) => {setLocation(data)});
    //         fetch('/api/sams/get_client/' + client_id, { headers: headers })
    //             .then((response) => response.json())
    //             .then((data) => {setClient(data)});
    //         }
    //     }, [client_id]
    // );

    // useEffect(() =>{
    //     setLocationId(location_id)
    //     // console.log('set location id')
    // }, [location_id]);

    useEffect(() => {
        if(isConnected){
            socket.emit("clients", {"function": "get_one", "data": {"hostname": auth.domain, "ip": auth.ip, "id": client_id}}, (response) => {
                //  console.log(response)
                 setClient(response)
            })
            if(location_id === 'add'){
                // console.log('Add!')
                setReadOnly(false);
                setNewForm(false)
            }else{
                socket.emit("locations", {"function": "get_one", "data": {"hostname": auth.domain, "ip": auth.ip, "id": location_id}}, (response) => {
                    //  console.log(response)
                     setLocation(response)
                })
                setNewForm(true)
            }
        }
   }, [isConnected]);

   useEffect(() => {
    if(isConnected){
        socket.emit("clients", {"function": "get_one", "data": {"hostname": auth.domain, "ip": auth.ip, "id": client_id}}, (response) => {
            //  console.log(response)
             setClient(response)
        })
        // if(location_id === 'add'){
        //     // console.log('Add!')
        //     setReadOnly(false);
        // }else{
        //     socket.emit("locations", {"function": "get_one", "data": {"hostname": auth.domain, "ip": "0.0.0.0", "id": location_id}}, (response) => {
        //         //  console.log(response)
        //          setLocation(response)
        //     })
        // }
    }
}, [location]);

    useEffect(() => {
        for (const [key, value] of Object.entries(location)) {
            setValue(key, value)
          }
          setValue("client_id", client_id)
    }, [location])
  

    const onSubmit = async (data, e) => {
        e.preventDefault();
        if(isConnected){
            socket.emit('locations', {"function": "save", "data": {"hostname": auth.domain, "ip": auth.ip, "id": client_id, "payload": data, "user": auth.user}}, (response) => {
                if(response != 'error'){
                    setClient(response)
                    setReadOnly(true);
                    console.log(response)
                    navigate("/location/" + client_id + "/" + response.location_id);
                }
            })
        }
        // const requestOptions = {
        //      method: 'POST',
        //      headers: { 'Content-Type': 'application/json', 'authorization': 'bearer ' + token },
        //      body: JSON.stringify(data)
        //  };
        // await fetch('/api/sams/add_location', requestOptions).then(async response => {
        //      console.log('sending...')
        //      console.log(response)
        //      if(response.ok === false){
        //           const error = response.statusText;
        //           console.log('error')
        //           console.log(error);
        //           throw Error(error);
        //      }
        //      const resp = await response.json();
        //      console.log('test')
        //      console.log(resp)
        //      setClient(resp)
        // }).catch(error => {
        //      console.log(error)
        // });
   }
   watch(errors)
    return (
        <>
            <Box component="main" sx={{ flexGrow: 1, p:{xs: 1, md: 2}, ml: {sm: 25, xs: 0}, width: { md: `calc(100% - ${drawerWidth}px)`, sm: `calc(100% - ${drawerWidth}px)`, xs: '100%' }}}>
                <form onSubmit={handleSubmit(onSubmit)} id='locationForm'> 
                    <Card sx={{textAlign: 'center'}}>
                    <CardHeader title={`Location Name & Address for Client: ${client.client_name}`}/>
                        <CardContent>
                            <Controller name="client_id" control={control} rules={{ required: true }} render={({ field }) => <TextField {...field} error={errors?.location_id} defaultValue={field} InputProps={{ readOnly: true }} label="Client ID" variant="standard" />} />
                            <Controller name="location_id" control={control} rules={{ required: true }} render={({ field }) => <TextField {...field} error={errors?.location_id} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Location ID" variant="standard" />} />
                            <Controller name="location_name" control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Name of Location" variant="standard" />} />
                            <Controller name="location_type" control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Type of Location" variant="standard" />} />
                            <Controller name="location_status" control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Status of Location" variant="standard" />} />
                            <Controller name="address" control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Main Street Address" variant="standard" />} />
                            <Controller name="address2" control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Secondary Address (Unit, Suite, Bldg, etc.)" variant="standard" />} />
                            <Controller name="city" control={control} rules={{ required: false }} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="City" variant="standard" />} />
                            <Controller name="state" control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="State" variant="standard" />} />
                            <Controller name="zip" control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Zip Code" variant="standard" />} />
                            <Controller name="timezone" control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Timezone" variant="standard" />} />
                            <Controller name="opens_at" control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Opens At" variant="standard" />} />
                            <Controller name="closes_at" control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Closes At" variant="standard" />} />
                            <Controller name="contract_details.data_handling" control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Data Handling" variant="standard" />} />
                            <Controller name="contract_details.ewaste_handling" control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="eWaste Handling" variant="standard" />} />
                            <Controller name="account_rep" control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Account Rep" variant="standard" />} />
                            <Controller name="public_notes" control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Public Notes" variant="standard" />} />
                            <Controller name="primary_poc_name" control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Primary POC Name" variant="standard" />} />
                            <Controller name="primary_poc_number" control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Primary POC Number" variant="standard" />} />
                            <Controller name="primary_poc_email" control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Primary POC Email" variant="standard" />} />
                            <Controller name="primary_poc_type" control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Primary POC Type" variant="standard" />} />
                            <Controller name="primary_poc_notes" control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Primary POC Notes" variant="standard" />} />
                            <Controller name="secondary_poc_name" control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Secondary POC Name" variant="standard" />} />
                            <Controller name="secondary_poc_number" control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Secondary POC Number" variant="standard" />} />
                            <Controller name="secondary_poc_email" control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Secondary POC Email" variant="standard" />} />
                            <Controller name="secondary_poc_type" control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Secondary POC Type" variant="standard" />} />
                            <Controller name="secondary_poc_notes" control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Secondary POC Notes" variant="standard" />} />
                            <Controller name="backup_poc_name" control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Backup POC Name" variant="standard" />} />
                            <Controller name="backup_poc_number" control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Backup POC Number" variant="standard" />} />
                            <Controller name="backup_poc_email" control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Backup POC Email" variant="standard" />} />
                            <Controller name="backup_poc_type" control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Backup POC Type" variant="standard" />} />
                            <Controller name="backup_poc_notes" control={control} render={({ field }) => <TextField {...field} defaultValue={field} InputProps={{ readOnly: readOnly }} label="Backup POC Notes" variant="standard" />} />
                        </CardContent>
                    </Card>
                </form>
            </Box>
            {auth.user?.type === 'employee' &&(<>
            <Box sx={{ position: 'fixed', bottom: { xs: '3dvh', sm: '3dvh', md: '3dvh' }, right: { xs: '5dvw',sm: '3dvw', md: '3dvw' } }}>
                <Tooltip title="Actions">
                    <IconButton
                    onClick={handleClick}
                    size="small"
                    aria-controls={open ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    >
                    <Avatar sx={{ width: 35, height: 35 }}>A</Avatar>
                    </IconButton>
                </Tooltip>
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                    },
                    '&::before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    bottom: 0,
                    right: '50%',
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(50%) rotate(45deg)',
                    zIndex: 0,
                    },
                },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
            >
                <MenuItem>
                    {readOnly && (<Button onClick={() => editClicked()}>Edit</Button>)}{!readOnly && (<><Button onClick={() => cancelClicked()}>Cancel</Button><Button type="submit" form='locationForm'>Save</Button></>)}
                </MenuItem>
                {/* <MenuItem>
                    <PrinterList labelType={props.type} job={client.job_id} />
                </MenuItem> */}
            </Menu>
            </>)}
        </>
    )
}

export default Location;