// import { useReducer, useState, useContext, createContext, useEffect } from "react";
import { Link, Route, Routes, Navigate } from "react-router-dom";
import * as React from 'react';
// import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
// import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
// import Typography from '@mui/material/Typography';
import LogoutIcon from '@mui/icons-material/Logout';
// import LoginIcon from '@mui/icons-material/Login';
import Avatar from '@mui/material/Avatar';
// import Tooltip from '@mui/material/Tooltip';
import Menu from '@mui/material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import Badge from '@mui/material/Badge';
import { useAuth } from "../../auth/AuthWrapper";

import DashboardIcon from '@mui/icons-material/Dashboard';
import StoreIcon from '@mui/icons-material/Store';
import BusinessIcon from '@mui/icons-material/Business';
import WorkIcon from '@mui/icons-material/Work';
import ScaleIcon from '@mui/icons-material/Scale';
import InventoryIcon from '@mui/icons-material/Inventory';
import BuildIcon from '@mui/icons-material/Build';
import GppMaybeIcon from '@mui/icons-material/GppMaybe';
import MapIcon from '@mui/icons-material/Map';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';

export const MainLayout = () => {
    const auth = useAuth();
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const [drawerWidth, setDrawerWidth] = React.useState(200);
    const handleOpenUserMenu = (event) => {
         setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
         setAnchorElUser(null);
    };
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const handleDrawerToggle = () => {
         setMobileOpen(!mobileOpen);
    };
    const Search = styled('div')(({ theme }) => ({
         position: 'relative',
         borderRadius: theme.shape.borderRadius,
         backgroundColor: alpha(theme.palette.common.white, 0.15),
         '&:hover': {
           backgroundColor: alpha(theme.palette.common.white, 0.25),
         },
         marginLeft: 0,
         width: '100%',
         // [theme.breakpoints.up('sm')]: {
         //   marginLeft: theme.spacing(1),
         //   width: 'auto',
         // },
       }));
       
       const SearchIconWrapper = styled('div')(({ theme }) => ({
         padding: theme.spacing(0, 2),
         height: '100%',
         position: 'absolute',
         pointerEvents: 'none',
         display: 'flex',
         alignItems: 'center',
         justifyContent: 'center',
       }));
       
       const StyledInputBase = styled(InputBase)(({ theme }) => ({
         color: 'inherit',
         '& .MuiInputBase-input': {
           padding: theme.spacing(1, 1, 1, 0),
           // vertical padding + font size from searchIcon
           paddingLeft: `calc(1em + ${theme.spacing(4)})`,
           transition: theme.transitions.create('width'),
         //   width: '100%',
         //   [theme.breakpoints.up('sm')]: {
             width: '0ch',
             '&:focus': {
               width: '14ch',
             },
         //   },
         },
       }));

    const drawer = (
         <div>
              <Toolbar />
              {auth.user?.type === 'employee' &&
              <List>
               <ListItem key='nav-1' disablePadding>
                    <ListItemButton component={Link} to='/dashboard'>
                         <ListItemIcon>
                              <DashboardIcon />
                         </ListItemIcon>
                         <ListItemText primary='Dashboard' />
                    </ListItemButton>
               </ListItem>
               <ListItem key='nav-2' disablePadding>
                    <ListItemButton component={Link} to='/facilities'>
                         <ListItemIcon>
                              <StoreIcon />
                         </ListItemIcon>
                         <ListItemText primary='Facilities' />
                    </ListItemButton>
               </ListItem>
               <ListItem key='nav-3' disablePadding>
                    <ListItemButton component={Link} to='/clients'>
                         <ListItemIcon>
                              <BusinessIcon />
                         </ListItemIcon>
                         <ListItemText primary='Clients' />
                    </ListItemButton>
               </ListItem>
               <ListItem key='nav-4' disablePadding>
                    <ListItemButton component={Link} to='/jobs'>
                         <ListItemIcon>
                              <WorkIcon />
                         </ListItemIcon>
                         <ListItemText primary='Jobs' />
                    </ListItemButton>
               </ListItem>
               <ListItem key='nav-events' disablePadding>
                    <ListItemButton component={Link} to='/events'>
                         <ListItemIcon>
                              <ConfirmationNumberIcon />
                         </ListItemIcon>
                         <ListItemText primary='Events' />
                    </ListItemButton>
               </ListItem>
               <ListItem key='nav-5' disablePadding>
                    <ListItemButton component={Link} to='/weights'>
                         <ListItemIcon>
                              <ScaleIcon />
                         </ListItemIcon>
                         <ListItemText primary='Weights' />
                    </ListItemButton>
               </ListItem>
               <ListItem key='nav-6' disablePadding>
                    <ListItemButton component={Link} to='/inventory'>
                         <ListItemIcon>
                              <InventoryIcon />
                         </ListItemIcon>
                         <ListItemText primary='Inventory' />
                    </ListItemButton>
               </ListItem>
               <ListItem key='nav-7' disablePadding>
                    <ListItemButton component={Link} to='/refurbishment'>
                         <ListItemIcon>
                              <BuildIcon />
                         </ListItemIcon>
                         <ListItemText primary='Refurbishment' />
                    </ListItemButton>
               </ListItem>
               <ListItem key='nav-downstream' disablePadding>
                    <ListItemButton component={Link} to='/downstream'>
                         <ListItemIcon>
                              <LocalShippingIcon />
                         </ListItemIcon>
                         <ListItemText primary='Downstream' />
                    </ListItemButton>
               </ListItem>
               <ListItem key='nav-8' disablePadding>
                    <ListItemButton component={Link} to='/quarantine'>
                         <ListItemIcon>
                              <GppMaybeIcon />
                         </ListItemIcon>
                         <ListItemText primary='Quarantine' />
                    </ListItemButton>
               </ListItem>
          </List>
          }
          {auth.user?.type === 'client' &&
          <List>
           <ListItem key='nav-1' disablePadding>
                <ListItemButton component={Link} to='/dashboard'>
                     <ListItemIcon>
                          <DashboardIcon />
                     </ListItemIcon>
                     <ListItemText primary='Dashboard' />
                </ListItemButton>
           </ListItem>
           <ListItem key='nav-2' disablePadding>
                <ListItemButton component={Link} to='/locations'>
                     <ListItemIcon>
                          <MapIcon />
                     </ListItemIcon>
                     <ListItemText primary='Locations' />
                </ListItemButton>
           </ListItem>
           <ListItem key='nav-3' disablePadding>
                <ListItemButton component={Link} to='/jobs'>
                     <ListItemIcon>
                          <WorkIcon />
                     </ListItemIcon>
                     <ListItemText primary='Jobs' />
                </ListItemButton>
           </ListItem>
      </List>
      }
         </div>
         );
         
       return (<>
         <Box sx={{ display: 'flex' }}>
              <CssBaseline />
              <AppBar
              position="fixed"
              sx={{
                   width: { sm: `${drawerWidth}px)` },
                   ml: { sm: `${drawerWidth}px` },
                   zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
              >
              <Toolbar className={'dev_headerBG'}>
                   { auth.user?.isAuthenticated === true ?
                   <IconButton
                   color="inherit"
                   aria-label="open drawer"
                   edge="start"
                   onClick={handleDrawerToggle}
                   sx={{ mr: 2, display: { xl: 'none', lg: 'none', md: 'none', sm: 'none' } }}
                   >
                   <MenuIcon />
                   </IconButton>
                   :
                   <></>
                   }
                   <Box
                        component="img"
                        sx={{
                             content: {
                                  xs: `url(/${auth.subDomain}/img/smallDefaultLogo.png)`, //img src from xs up to md
                                  sm: `url(/${auth.subDomain}/img/bigDefaultLogo.png)`,  //img src from md and up
                             },
                             height: '40px'
                        }}
                        alt="Logo"
                   />
                   <Box sx={{ flexGrow: 1, display: {md: 'flex', sm: 'flex', xs: 'flex' } }}></Box>
                   { auth.user?.isAuthenticated === true ?
                   <>
                        <Box sx={{ flexGrow: 0, pr: 2}}>
                             <Search>
                                  <SearchIconWrapper>
                                       <SearchIcon />
                                  </SearchIconWrapper>
                                  <StyledInputBase
                                       placeholder="Search…"
                                       inputProps={{ 'aria-label': 'search' }}
                                  />
                             </Search>
                        </Box>
                        <Box sx={{ flexGrow: 0 }}>
                        <Badge badgeContent={0} color="success">
                             <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                  <Avatar alt={auth.user.name} src="/static/images/avatar/2.jpg" />
                             </IconButton>
                        </Badge>
                        <Menu
                        sx={{ mt: '45px' }}
                        id="menu-appbar"
                        anchorEl={anchorElUser}
                        anchorOrigin={{
                             vertical: 'top',
                             horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                             vertical: 'top',
                             horizontal: 'right',
                        }}
                        open={Boolean(anchorElUser)}
                        onClick={handleCloseUserMenu}
                        onClose={handleCloseUserMenu}
                        >
                        {/* settingd here */}
                        <List>
                             <ListItem key='Account' disablePadding>
                                       <ListItemButton component={Link} to='/account'>
                                            <ListItemIcon>
                                                <AccountCircleIcon />
                                            </ListItemIcon>
                                       <ListItemText primary='Account' />
                                  </ListItemButton>
                             </ListItem>
                             <ListItem key='Logout' disablePadding>
                                       <ListItemButton onClick={auth.logOut}>
                                            <ListItemIcon>
                                                <LogoutIcon />
                                            </ListItemIcon>
                                       <ListItemText primary='Logout' />
                                  </ListItemButton>
                             </ListItem>
                        </List>
                        </Menu>
                        </Box>
                        </>
                        :
                        <></>
                   }
              </Toolbar>
              </AppBar>
              { auth.user?.isAuthenticated === true ?
                   <Box
                   component="nav"
                   sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 }}}
                   aria-label="mailbox folders"
                   >
                   {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                   <Drawer
                        variant="temporary"
                        open={mobileOpen}
                        onClick={handleDrawerToggle}
                        onClose={handleDrawerToggle}
                        ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                        }}
                        sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                        }}
                   >
                        {drawer}
                   </Drawer>
                   <Drawer
                        variant="permanent"
                        sx={{
                        display: { xs: 'none', sm: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                        }}
                        open
                   >
                        {drawer}
                   </Drawer>
                   </Box>
              :
                   <></>
              }
              <Box
              component="main"
              sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` }
              }}
              >
              </Box>
         </Box>
         </>
       )
  }